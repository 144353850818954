import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaFlag, FaVolumeUp, FaCheck, FaSort, FaFilter, FaSearch, FaTimes } from 'react-icons/fa';
import { Data } from '../data';
import '../styles/FlaggedWordsPage.css';

const FlaggedWordsPage = ({ userStats, setUserStats }) => {
  const navigate = useNavigate();
  const [flaggedWords, setFlaggedWords] = useState([]);
  const [playingAudio, setPlayingAudio] = useState(null);
  const [expandedCards, setExpandedCards] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('recent'); // recent, alphabetical, flagCount
  const [filterOption, setFilterOption] = useState('all'); // all, dueToday, dueThisWeek

  useEffect(() => {
    let words = Data.filter(word => 
      userStats.flaggedSynonyms?.[word.word]?.length > 0
    );

    // Apply search filter
    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      words = words.filter(word => 
        word.word.toLowerCase().includes(searchLower) ||
        userStats.flaggedSynonyms[word.word].some(syn => 
          syn.toLowerCase().includes(searchLower)
        )
      );
    }

    // Apply time filter
    if (filterOption !== 'all') {
      const now = new Date();
      words = words.filter(word => {
        const reviewDates = userStats.reviewDates?.[word.word] || {};
        const nextReviews = Object.values(reviewDates);
        
        if (filterOption === 'dueToday') {
          return nextReviews.some(date => new Date(date) <= now);
        } else if (filterOption === 'dueThisWeek') {
          const weekFromNow = new Date(now);
          weekFromNow.setDate(weekFromNow.getDate() + 7);
          return nextReviews.some(date => new Date(date) <= weekFromNow);
        }
        return true;
      });
    }

    // Apply sorting
    words.sort((a, b) => {
      switch (sortOption) {
        case 'alphabetical':
          return a.word.localeCompare(b.word);
        case 'flagCount':
          return (userStats.flaggedSynonyms[b.word]?.length || 0) - 
                 (userStats.flaggedSynonyms[a.word]?.length || 0);
        case 'recent':
        default:
          const aDate = Math.min(...Object.values(userStats.reviewDates?.[a.word] || {}));
          const bDate = Math.min(...Object.values(userStats.reviewDates?.[b.word] || {}));
          return aDate - bDate;
      }
    });

    setFlaggedWords(words);
  }, [userStats, searchTerm, sortOption, filterOption]);

  const handlePronunciation = async (word, barElement) => {
    try {
      if (playingAudio === word) {
        window.speechSynthesis.cancel();
        setPlayingAudio(null);
        barElement.classList.remove('playing');
        return;
      }

      window.speechSynthesis.cancel();
      barElement.classList.add('playing');
      setPlayingAudio(word);

      if ('speechSynthesis' in window) {
        const utterance = new SpeechSynthesisUtterance(word);
        utterance.lang = 'en-US';
        utterance.rate = 0.9;

        utterance.onend = () => {
          setPlayingAudio(null);
          barElement.classList.remove('playing');
        };

        utterance.onerror = () => {
          console.error('Error playing pronunciation for:', word);
          setPlayingAudio(null);
          barElement.classList.remove('playing');
        };

        window.speechSynthesis.speak(utterance);
      }
    } catch (error) {
      console.error('Error with pronunciation:', error);
      setPlayingAudio(null);
      barElement.classList.remove('playing');
    }
  };

  const handleUnflagSynonym = (word, synonym) => {
    setUserStats(prev => {
      const currentFlagged = prev.flaggedSynonyms[word] || [];
      const newFlagged = currentFlagged.filter(s => s !== synonym);

      const newFlaggedSynonyms = {
        ...prev.flaggedSynonyms,
        [word]: newFlagged
      };

      // Remove the word entry if no more flagged synonyms
      if (newFlagged.length === 0) {
        delete newFlaggedSynonyms[word];
      }

      return {
        ...prev,
        flaggedSynonyms: newFlaggedSynonyms
      };
    });
  };

  const startPractice = () => {
    // Navigate directly to flagged quiz instead of word-knowledge
    navigate('/flagged-quiz');
  };

  return (
    <div className="flagged-words-page">
      <div className="page-header">
        <button className="back-button" onClick={() => navigate('/')}>
          <FaArrowLeft /> Back to Home
        </button>
        <h1>Flagged Words Review</h1>
      </div>

      {flaggedWords.length > 0 ? (
        <>
          <div className="controls-section">
            <div className="search-bar">
              <FaSearch className="search-icon" />
              <input
                type="text"
                placeholder="Search flagged words..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              {searchTerm && (
                <button 
                  className="clear-search"
                  onClick={() => setSearchTerm('')}
                >
                  <FaTimes />
                </button>
              )}
            </div>

            <div className="filter-controls">
              <div className="filter-group">
                <label><FaSort /> Sort by:</label>
                <select 
                  value={sortOption}
                  onChange={(e) => setSortOption(e.target.value)}
                >
                  <option value="recent">Due Date</option>
                  <option value="alphabetical">Alphabetical</option>
                  <option value="flagCount">Flag Count</option>
                </select>
              </div>

              <div className="filter-group">
                <label><FaFilter /> Filter:</label>
                <select 
                  value={filterOption}
                  onChange={(e) => setFilterOption(e.target.value)}
                >
                  <option value="all">All Words</option>
                  <option value="dueToday">Due Today</option>
                  <option value="dueThisWeek">Due This Week</option>
                </select>
              </div>
            </div>
          </div>

          <div className="practice-section">
            <div className="practice-info">
              <button 
                className="practice-button" 
                onClick={startPractice}
              >
                Start Quiz ({flaggedWords.length} {flaggedWords.length === 1 ? 'word' : 'words'})
              </button>
              <div className="practice-stats">
                <p className="flagged-count">
                  {flaggedWords.length} {flaggedWords.length === 1 ? 'word' : 'words'} flagged
                </p>
                <p className="synonym-count">
                  {flaggedWords.reduce((total, word) => 
                    total + (userStats.flaggedSynonyms[word.word]?.length || 0), 0
                  )} total flagged synonyms
                </p>
              </div>
            </div>
          </div>

          <div className="flagged-words-list">
            {flaggedWords.map(wordData => {
              return (
                <div key={wordData.word} className="flagged-word-card">
                  <div className="word-header" onClick={() => {
                    setExpandedCards(prev => ({
                      ...prev,
                      [wordData.word]: !prev[wordData.word]
                    }));
                  }}>
                    <div className="word-info">
                      <h2>{wordData.word}</h2>
                      {wordData['part-of-speech'] && (
                        <span className="part-of-speech">
                          {wordData['part-of-speech'].join(', ')}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="definition">
                    {Array.isArray(wordData.definition) 
                      ? wordData.definition[0]
                      : wordData.definition}
                  </div>

                  <div className="synonyms-section">
                    <h3>Flagged Synonyms:</h3>
                    <div className="synonyms-grid">
                      {wordData.synonyms
                        .filter(synonym => userStats.flaggedSynonyms[wordData.word]?.includes(synonym))
                        .map(synonym => (
                          <div key={synonym} className="synonym-container">
                            <div className="synonym-tag flagged">
                              {synonym}
                              <button
                                className="unflag-button"
                                onClick={() => handleUnflagSynonym(wordData.word, synonym)}
                                title="Remove flag"
                              >
                                <FaCheck />
                              </button>
                            </div>
                            <button
                              className={`pronunciation-bar ${playingAudio === synonym ? 'playing' : ''}`}
                              onClick={(e) => handlePronunciation(synonym, e.target)}
                              title={`Pronounce ${synonym}`}
                            />
                          </div>
                        ))}
                    </div>
                  </div>

                  {expandedCards[wordData.word] && (
                    <div className="expanded-content">
                      {wordData.sentences && wordData.sentences[0] && (
                        <div className="example-section">
                          <h3>Example Usage:</h3>
                          <p className="example-sentence">{wordData.sentences[0]}</p>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <div className="no-flagged-words">
          <FaFlag className="empty-icon" />
          <h2>No Flagged Words</h2>
          <p>Words you flag during quizzes or vocabulary review will appear here.</p>
          <Link to="/word-knowledge" className="start-quiz-button">
            Start a Quiz
          </Link>
        </div>
      )}
    </div>
  );
};

export default FlaggedWordsPage; 