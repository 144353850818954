import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaPlay, FaRandom } from 'react-icons/fa';
import { Data } from '../data';
import '../styles/WordKnowledge.css';

const WordKnowledgePage = ({ 
  quizSetup, 
  setQuizSetup, 
  setQuizResults, 
  setUserStats,
  userStats,
  customWords,
  excludedWords 
}) => {
  const navigate = useNavigate();
  
  const getFilteredWords = () => {
    let filteredWords = [...Data, ...customWords];
    
    if (!selectedAlphabets?.includes('all')) {
      filteredWords = filteredWords.filter(word => 
        selectedAlphabets?.some(letter => 
          word.word.toLowerCase().startsWith(letter.toLowerCase())
        )
      );
    }

    filteredWords = filteredWords.filter(word => {
      const masteredWordSynonyms = new Set([
        ...(userStats.masteredSynonyms?.[word.word] || []),
        ...(userStats.quizHistory?.flatMap(quiz => 
          quiz.wordResults?.[word.word]?.correct || []
        ) || [])
      ]);

      return masteredWordSynonyms.size < word.synonyms.length;
    });

    return filteredWords;
  };

  const [selectedAlphabets, setSelectedAlphabets] = useState(quizSetup.selectedAlphabets);
  const [selectedWordCount, setSelectedWordCount] = useState(
    Math.min(quizSetup.selectedWordCount, getFilteredWords().length)
  );

  const handleRandomSelection = () => {
    const alphabets = 'abcdefghijklmnopqrstuvwxyz'.split('');
    const randomLetters = [];
    const numLetters = Math.floor(Math.random() * 3) + 1; // Select 1-3 random letters
    
    for (let i = 0; i < numLetters; i++) {
      const randomIndex = Math.floor(Math.random() * alphabets.length);
      randomLetters.push(alphabets[randomIndex]);
      alphabets.splice(randomIndex, 1);
    }
    
    setSelectedAlphabets(randomLetters);
  };

  const handleStartQuiz = () => {
    const filteredWords = getFilteredWords();
    if (filteredWords.length === 0) {
      alert('No words available for quiz. All words have been mastered!');
      return;
    }

    const selectedWords = [...filteredWords]
      .sort(() => 0.5 - Math.random())
      .slice(0, Math.min(selectedWordCount, filteredWords.length));

    setQuizSetup({
      selectedAlphabets,
      selectedWordCount,
      filteredWords: selectedWords
    });

    navigate('/quiz');
  };

  const availableWords = getFilteredWords().length;

  const getWordStats = () => {
    const allWords = [...Data, ...customWords];
    const totalWords = allWords.length;
    const totalSynonyms = allWords.reduce((sum, word) => sum + word.synonyms.length, 0);
    
    let masteredWords = 0;
    let masteredSynonyms = 0;

    allWords.forEach(word => {
      const wordSynonyms = word.synonyms;
      const masteredWordSynonyms = new Set([
        ...(userStats.masteredSynonyms?.[word.word] || []),
        ...(userStats.quizHistory?.flatMap(quiz => 
          quiz.wordResults?.[word.word]?.correct || []
        ) || [])
      ]);

      if (masteredWordSynonyms.size === wordSynonyms.length) {
        masteredWords++;
      }
      masteredSynonyms += masteredWordSynonyms.size;
    });

    return {
      totalWords,
      totalSynonyms,
      masteredWords,
      masteredSynonyms,
      availableWords: totalWords - masteredWords,
      availableSynonyms: totalSynonyms - masteredSynonyms
    };
  };

  const stats = getWordStats();

  return (
    <div className="word-knowledge-page">
      <div className="page-header">
        <button 
          className="back-button"
          onClick={() => navigate('/')}
        >
          <FaArrowLeft /> Back to Home
        </button>
        <h1>Word Knowledge Quiz</h1>
      </div>

      <div className="quiz-setup-container">
        <div className="setup-section word-count-section">
          <h2>Quiz Size</h2>
          <div className="word-count-slider">
            <label>Number of Words: <span className="word-count">{selectedWordCount}</span></label>
            <input
              type="range"
              min="1"
              max={Math.min(20, availableWords)}
              value={Math.min(selectedWordCount, availableWords)}
              onChange={(e) => setSelectedWordCount(Number(e.target.value))}
              className="custom-slider"
            />
            <div className="slider-labels">
              <span>1</span>
              <span>{Math.min(20, availableWords)}</span>
            </div>
          </div>
          <p className="available-words">
            {availableWords} {availableWords === 1 ? 'word' : 'words'} available for quiz
          </p>
        </div>

        <div className="setup-section alphabet-section">
          <div className="section-header">
            <h2>Starting Letters</h2>
            <button 
              className="random-button"
              onClick={handleRandomSelection}
              title="Random Selection"
            >
              <FaRandom />
            </button>
          </div>
          <div className="alphabet-buttons">
            <button
              key="all"
              className={`alphabet-button ${selectedAlphabets.includes('all') ? 'selected' : ''}`}
              onClick={() => setSelectedAlphabets(['all'])}
            >
              ALL
            </button>
            {[...'abcdefghijklmnopqrstuvwxyz'].map(letter => (
              <button
                key={letter}
                className={`alphabet-button ${selectedAlphabets.includes(letter) ? 'selected' : ''}`}
                onClick={() => {
                  if (selectedAlphabets.includes('all')) {
                    setSelectedAlphabets([letter]);
                  } else {
                    const newSelection = selectedAlphabets.includes(letter)
                      ? selectedAlphabets.filter(l => l !== letter)
                      : [...selectedAlphabets, letter];
                    setSelectedAlphabets(newSelection.length ? newSelection : ['all']);
                  }
                }}
              >
                {letter.toUpperCase()}
              </button>
            ))}
          </div>
          <p className="selected-letters">
            Selected: {selectedAlphabets.includes('all') 
              ? 'All Letters' 
              : selectedAlphabets.map(l => l.toUpperCase()).join(', ')}
          </p>
        </div>

        <button 
          className="start-quiz-button"
          onClick={handleStartQuiz}
          disabled={availableWords === 0}
        >
          <FaPlay /> Start Quiz
        </button>

        <div className="setup-section stats-section">
          <h2>Word Pool Status</h2>
          <div className="stats-grid">
            <div className="stat-item">
              <div className="stat-content">
                <div className="stat-main">
                  <div className="stat-value">{stats.availableWords}</div>
                  <div className="stat-label">Available Words</div>
                </div>
                <div className="stat-progress">
                  <div className="progress-bar">
                    <div 
                      className="progress-fill"
                      style={{ width: `${((stats.totalWords - stats.availableWords)/stats.totalWords) * 100}%` }}
                    />
                  </div>
                  <div className="progress-text">
                    {stats.masteredWords} of {stats.totalWords} mastered
                  </div>
                </div>
              </div>
            </div>
            <div className="stat-item">
              <div className="stat-content">
                <div className="stat-main">
                  <div className="stat-value">{stats.availableSynonyms}</div>
                  <div className="stat-label">Available Synonyms</div>
                </div>
                <div className="stat-progress">
                  <div className="progress-bar">
                    <div 
                      className="progress-fill"
                      style={{ width: `${((stats.totalSynonyms - stats.availableSynonyms)/stats.totalSynonyms) * 100}%` }}
                    />
                  </div>
                  <div className="progress-text">
                    {stats.masteredSynonyms} of {stats.totalSynonyms} mastered
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WordKnowledgePage; 