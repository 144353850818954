import React from 'react';
import { Link } from 'react-router-dom';
import { FaBook, FaCalculator, FaChartLine, FaQuestionCircle, FaBrain, FaCog, FaFlag, FaLayerGroup } from 'react-icons/fa';
import '../styles/MainPage.css';

const MainPage = () => {
  return (
    <div className="main-page">
      <header className="main-header">
        <h1>BSEP Learning Hub</h1>
        <p>Your comprehensive learning platform for vocabulary and mathematics</p>
      </header>

      <div className="features-grid">
        <Link to="/word-knowledge" className="feature-card">
          <div className="feature-icon">
            <FaBrain />
          </div>
          <div className="feature-content">
            <h2>Word Knowledge</h2>
            <p>Test your vocabulary with synonym quizzes</p>
          </div>
        </Link>

        <Link to="/flashcards" className="feature-card">
          <div className="feature-icon">
            <FaLayerGroup />
          </div>
          <div className="feature-content">
            <h2>Flashcards</h2>
            <p>Study words and synonyms with interactive flashcards</p>
          </div>
        </Link>

        <Link to="/flagged-words" className="feature-card">
          <div className="feature-icon">
            <FaFlag />
          </div>
          <div className="feature-content">
            <h2>Flagged Words</h2>
            <p>Review words you've marked for extra practice</p>
          </div>
        </Link>

        <Link to="/vocabulary" className="feature-card">
          <div className="feature-icon">
            <FaBook />
          </div>
          <div className="feature-content">
            <h2>Vocabulary</h2>
            <p>Learn and practice new words with interactive exercises</p>
          </div>
        </Link>

        <Link to="/practice" className="feature-card">
          <div className="feature-icon">
            <FaQuestionCircle />
          </div>
          <div className="feature-content">
            <h2>Practice</h2>
            <p>Focus on areas that need improvement</p>
          </div>
        </Link>

        <Link to="/math" className="feature-card">
          <div className="feature-icon">
            <FaCalculator />
          </div>
          <div className="feature-content">
            <h2>Mathematics</h2>
            <p>Practice conversions and mathematical concepts</p>
          </div>
        </Link>

        <Link to="/progress" className="feature-card">
          <div className="feature-icon">
            <FaChartLine />
          </div>
          <div className="feature-content">
            <h2>Progress</h2>
            <p>Track your learning journey and achievements</p>
          </div>
        </Link>
      </div>

      <div className="settings-link">
        <Link to="/settings" className="settings-button">
          <FaCog /> Settings
        </Link>
      </div>

      <footer className="main-footer">
        <p>© 2024 BSEP Learning Hub. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default MainPage; 