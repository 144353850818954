export const Data =  [
    {
      "word": "abhor",
      "definition": [
        "to regard with extreme repugnance or aversion; detest utterly; loathe; abominate.",
        "to regard with extreme repugnance",
        "To regard with disgust or hatred."
      ],
      "synonyms": [
        "detest",
        "hate",
        "loathe",
        "despise",
        "abominate",
        "regard with disgust"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "I abhor dishonesty; trust is the foundation of any strong relationship.",
        "Soldiers are trained to abhor actions that could put their teammates at risk.",
        "He abhors the taste of bitter vegetables and avoids them at all costs.",
        "I abhor violence in any form."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "abrupt",
      "definition": [
        "sudden and unexpected; or brief to the point of rudeness"
      ],
      "synonyms": [
        "immediate",
        "hurried",
        "hasty",
        "unanticipated",
        "terse",
        "sudden",
        "speedy",
        "unexpected",
        "startling",
        "without warning"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "Her abrupt departure left everyone confused and curious.",
        "An abrupt change in the mission plan left the troops scrambling for gear.",
        "The meeting came to an abrupt end when the fire alarm went off.",
        "The recession brought an abrupt halt to this happiness."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "abstemious",
      "definition": [
        "sparing or moderate in eating or drinking or other activities"
      ],
      "synonyms": [
        "moderate",
        "restrained",
        "temperate",
        "sparing",
        "frugal",
        "ascetic",
        "self-disciplined",
        "abstinent"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "Her abstemious habits contributed to her excellent health and longevity.",
        "An abstemious diet helped her stay fit and focused.",
        "Abstemious habits are essential for soldiers to maintain peak physical fitness.",
        "He lived an abstemious life, avoiding any excess in food or drink.",
        "Naomi Campbell’s career as a model demanded an abstemious diet.",
        "He was known for his abstemious lifestyle, rarely indulging in rich foods or alcoholic beverages."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "ABS of STEM (As in 'abs' are built by moderate or controlled habits.)"
      ]
    },
    {
      "word": "abundant",
      "definition": [
        "existing in great amounts; marked by plenty"
      ],
      "synonyms": [
        "plentiful",
        "ample",
        "copious",
        "bountiful",
        "profuse",
        "overflowing",
        "lavish",
        "generous",
        "rich"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "Thanks to the abundant rainfall this year, the harvest is expected to be excellent.",
        "Nature provides an abundant variety of resources to sustain life.",
        "The base had an abundant supply of rations to sustain the troops.",
        "The garden was abundant with colorful flowers and fruits."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "A BUN Dance (Imagine a dance floor filled with 'a bun dance' or lots of buns to signify abundance.)"
      ]
    },
    {
      "word": "acquiescence",
      "definition": [
        "The reluctant acceptance of something without protest.",
        "readiness or willingness to yield to the wishes of others"
      ],
      "synonyms": [
        "obedience",
        "assent",
        "submissiveness",
        "docility",
        "compliance",
        "consent",
        "acceptance",
        "agreement",
        "concurrence",
        "submission",
        "resignation",
        "conformity",
        "yielding"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "Despite his initial objections, he eventually offered his acquiescence to the group's decision.",
        "Her quiet acquiescence to the changes in company policy surprised her colleagues.",
        "His silent acquiescence suggested he was not happy with the decision.",
        "Soldiers showed acquiescence to the orders despite their reservations.",
        "There was a sense of quiet acquiescence in the group, as everyone agreed."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "acquisition",
      "definition": [
        "the act of acquiring or gaining possession; something gained or added."
      ],
      "synonyms": [
        "purchase",
        "asset",
        "property",
        "investment",
        "possession",
        "attainment"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The students acquisition of BSEP English in such a short time amazed all of their instructors.",
        "The acquisition of the new software improved the team’s productivity.",
        "The military’s acquisition of advanced drones enhanced surveillance capabilities.",
        "Her recent acquisition of rare books made her library complete."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "adage",
      "definition": [
        "a traditional saying"
      ],
      "synonyms": [
        "maxim",
        "proverb",
        "truism",
        "cliche",
        "motto",
        "byword"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "An old adage states, 'Actions speak louder than words.'",
        "The sergeant reminded the recruits of the adage, 'Stay low and move fast.'",
        "Her grandmother often shared adages that were wise yet simple."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "adjacent",
      "definition": [
        "lying near, close, or contiguous"
      ],
      "synonyms": [
        "adjoining",
        "neighboring",
        "next door to",
        "abutting",
        "contiguous"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The schools were adjacent but there were separate doors. He sat in an adjacent room and waited",
        "Her room was adjacent to mine, so I could hear her music.",
        "In battle formation, staying adjacent to your unit is crucial for defense.",
        "The park is adjacent to the school, making it easy for students to visit."
      ],
      "part-of-speech": [
        ""
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "admonish",
      "definition": [
        "warn or reprimand someone; to warn or caution; to reprove or scold"
      ],
      "synonyms": [
        "reprimand",
        "rebuke",
        "lambaste",
        "chide",
        "berate",
        "scold",
        "reprove",
        "criticize"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The teacher had to admonish the students for not doing their homework.",
        "The lieutenant would often admonish recruits to be punctual and alert.",
        "Her friend admonished her about the dangers of reckless driving."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "adobe",
      "definition": [
        "kind of clay in the form of sun-dried bricks used for building"
      ],
      "synonyms": [
        "brick",
        "clay",
        "terra cotta",
        "earth brick",
        "mudbrick"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "Traditional adobe houses keep cool even in the hottest weather.",
        "During field training, soldiers were shown how to construct shelters using adobe.",
        "The desert village was filled with adobe structures that blended into the landscape."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "adore",
      "definition": [
        "to love and respect someone deeply; worship or regard with deep affection"
      ],
      "synonyms": [
        "love",
        "cherish",
        "idealize",
        "revere",
        "admire",
        "esteem",
        "worship",
        "treasure"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "She adored her grandmother, who always made her feel special.",
        "The soldier adored the camaraderie among his team, as it kept him motivated through tough missions.",
        "He couldn’t help but adore the puppy’s playful nature."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "adversary",
      "definition": [
        "one that is hostile toward another"
      ],
      "synonyms": [
        "enemy",
        "opponent",
        "foe",
        "rival",
        "antagonist",
        "nemesis",
        "challenger",
        "contender",
        "opposer",
        "combatant"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The two countries were adversaries and fought against each other during the war.",
        "In chess, a good player always respects their adversary.",
        "The enemy forces became a relentless adversary, challenging the platoon’s every move.",
        "She faced her academic adversary in the final debate with confidence."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "advocacy",
      "definition": [
        "support for or recommendation of a particular cause"
      ],
      "synonyms": [
        "support",
        "promotion",
        "endorsement",
        "championing",
        "backing",
        "sponsorship",
        "defense",
        "argument for",
        "approval"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "Public advocacy by numerous civil rights leaders led to the passing of new anti-discrimination laws.",
        "She has dedicated her career to the advocacy of women's rights and gender equality.",
        "Her advocacy for animal rights inspired many people to volunteer.",
        "The officer’s advocacy for better training equipment led to noticeable improvements in the troops' performance.",
        "He joined an environmental group to support their advocacy for clean air policies."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "agile",
      "definition": [
        "Able to move quickly and easily; flexible and adaptable"
      ],
      "synonyms": [
        "nimble",
        "lithe",
        "spry",
        "supple",
        "limber",
        "graceful",
        "sprightly"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The gymnast’s agile movements captivated the audience.",
        "Soldiers must be agile to adapt quickly in unpredictable combat situations.",
        "The software team adopted an agile approach, allowing for rapid changes in project development."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "allude",
      "definition": [
        "to suggest or call attention to indirectly"
      ],
      "synonyms": [
        "hint at",
        "suggest",
        "imply",
        "refer to",
        "touch on"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "In her speech, she alluded to her mentor’s influence without mentioning him directly.",
        "The commander’s brief alluded to a potential mission change, keeping the soldiers on high alert.",
        "He often alluded to his past successes, though he rarely shared details."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "amalgamation",
      "definition": [
        "the action, process, or result of combining or uniting"
      ],
      "synonyms": [
        "fusion",
        "combination",
        "integration",
        "mixture",
        "blend",
        "union",
        "mingling",
        "consolidation",
        "synthesis",
        "convergence",
        "incorporation"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The new smoothie was an amalgamation of tropical fruits, creating a unique flavor.",
        "The military strategy was an amalgamation of multiple tactics from different branches.",
        "The city’s culture is an amalgamation of traditions from many immigrant communities."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "amused",
      "definition": [
        "finding something funny or entertaining"
      ],
      "synonyms": [
        "entertained",
        "delighted",
        "diverted",
        "gladdened",
        "tickled",
        "pleased",
        "captivated"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "She was amused by the child’s endless questions about the world.",
        "The soldiers were amused by the captain’s stories, which helped lighten the mood before deployment.",
        "His friends were amused by his attempts to dance at the party."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "argument",
      "definition": [
        "usually heated exchange of opposite views"
      ],
      "synonyms": [
        "quarrel",
        "disagreement",
        "clash",
        "fight"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "They had a heated argument over who should do the dishes.",
        "The officer presented a strong argument for the need for additional supplies to complete the mission.",
        "Their argument about vacation plans ended in laughter as they realized they wanted the same destination."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "assail",
      "definition": [
        "to attack vigorously or violently"
      ],
      "synonyms": [
        "assault",
        "set upon",
        "lash out at",
        "tear into"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "Critics assailed the film for its lack of originality.",
        "The unit was trained to assail enemy defenses with speed and precision.",
        "He felt overwhelmed as doubts began to assail him before his big presentation."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "attrition",
      "definition": [
        "a reduction or decrease in number, size, or strength"
      ],
      "synonyms": [
        "wearing down",
        "weakening",
        "sapping",
        "erosion",
        "thinning out",
        "diintegration"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The company faced a steady rate of attrition as employees left for better opportunities.",
        "The platoon was prepared for a long mission, knowing that attrition could wear down even the strongest soldiers.",
        "The constant arguments caused an attrition in their friendship over time."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "banish",
      "definition": [
        "to send someone away, or get rid of"
      ],
      "synonyms": [
        "exile",
        "cast out",
        "expel",
        "oust",
        "deport",
        "eject",
        "extradite",
        "drive away",
        "evict",
        "exclude",
        "ban",
        "forbid",
        "abolish",
        "dispel"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183aea8079eb4cd4a59fdd/627962677e478616fae897ed.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183bb18079eb4cd4a5b740/628478e5c3731da7ee320ff2.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The king decided to banish the traitor from the kingdom forever.",
        "Any soldier caught disobeying a direct order could be banished from the unit.",
        "He decided to banish all negative thoughts and focus on his goals."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        "Ban this - Banning someone from a place"
      ]
    },
    {
      "word": "berate",
      "definition": [
        "to scold or criticize angrily"
      ],
      "synonyms": [
        "rebuke",
        "reprimand",
        "chastise",
        "lambaste"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183bd78079eb4cd4a5bbba/6286476fc3731da7ee3b80f5.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65fb517bd58ee572fc8297e2/665bbb32e7d77a68b54514af.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65fb50ebd58ee572fc8297c2/665b91dde7d77a68b54440e0.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65fb50ebd58ee572fc8297c2/665b91dde7d77a68b54440e0.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183cb18079eb4cd4a5d533/629d317e14bb6d88ae1aa85d.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183a018079eb4cd4a583cb/62a7dabdd12c875e2fc5cadc.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65f8a7c3b267755af6cf7314/665a651fe7d77a68b53d6f17.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The coach would often berate players who weren’t giving their best effort.",
        "The sergeant had to berate a soldier who was neglecting his duties during training.",
        "Her parents would berate her for staying up too late on school nights."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        "Be rate - Rating someone's behavior negatively"
      ]
    },
    {
      "word": "bigotry",
      "definition": [
        "Bigotry means having a strong dislike or intolerance for people with different opinions, beliefs, or backgrounds, often leading to unfair treatment."
      ],
      "synonyms": [
        "prejudice",
        "bias",
        "discrimination",
        "unfairness"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183e3c8079eb4cd4a60438/62a83cf2d12c875e2fc714bc.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183b818079eb4cd4a5b1d8/628de970b071e78202f72344.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183ed58079eb4cd4a61699/628387eec3731da7ee2d1784.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "Bigotry has no place in a society that values equality.",
        "The military actively works to root out bigotry to foster a unified and inclusive environment.",
        "She was disappointed to see bigotry still alive in her community."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "Big ought try - Someone too big to try understanding others"
      ]
    },
    {
      "word": "bivouac",
      "definition": [
        "a temporary camp without cover"
      ],
      "synonyms": [
        "camp",
        "cantonment",
        "encampment"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183d688079eb4cd4a5eaa2/62a4f16160e405035534d11f.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The hikers set up a bivouac under the stars to rest for the night.",
        "The unit set up a bivouac in the field, ready for an early morning mission.",
        "They made a quick bivouac in the woods as a shelter from the unexpected storm."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "boast",
      "definition": [
        "(verb) exaggerated or objectionable speech; or to speak with exaggeration and excessive pride",
        "(noun) an act of boasting."
      ],
      "synonyms": [
        "brag",
        "self-praise",
        "overstatement",
        "crow",
        "swagger",
        "gloat",
        "show off",
        "exaggeration"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183e358079eb4cd4a60373/6273f14c49bbae143d799549.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183e188079eb4cd4a5ffed/627358af49bbae46093cdd51.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "He boasted of his family's wealth. The town boasts a new school. (verb)",
        "Talent is his boast. It is her boast that she has never betrayed a friend. (noun)",
        "He would often boast about his accomplishments to anyone who would listen.",
        "The decorated officer rarely felt the need to boast, letting his actions speak instead.",
        "She couldn’t help but boast a little after winning the competition."
      ],
      "part-of-speech": [
        "noun",
        "verb"
      ],
      "mnemonics": [
        "Bo-ast - A bow being used to shoot high into the sky"
      ]
    },
    {
      "word": "bodacious",
      "definition": [
        "Bodacious means remarkable, impressive, or bold. It describes something that stands out for being exciting or daring.",
        "likely to attract attention, or without exception or restriction"
      ],
      "synonyms": [
        "prominent",
        "remarkable",
        "noticeable",
        "dramatic",
        "commanding",
        "striking",
        "conspicuous",
        "impressive",
        "brilliant",
        "desireable",
        "sexy",
        "complete",
        "utter"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65f89ee9b267755af6cf6fe7/665af287e7d77a68b540920c.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The surfer performed a bodacious trick that wowed the crowd.",
        "The soldier's bodacious bravery during the rescue mission earned him high praise.",
        "Her bodacious sense of style always made her stand out in a crowd."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "Body-cious - A body so amazing it's outrageous",
        "Think 'BOLD + AUDACIOUS' = BODACIOUS!Imagine someone who is confidently bold and makes an unforgettable impression—like a 'bodacious' friend who dares to do amazing things!"
      ]
    },
    {
      "word": "bombastic",
      "definition": [
        "meaningless words and phrases, meant to impress",
        "marked by the use of impressivesounding but mostly meaningless words and phrases, meant to impress"
      ],
      "synonyms": [
        "rhetoric",
        "rhetorical",
        "ostentatious",
        "grandiose",
        "inflated",
        "pretentious",
        "pompous",
        "oratorical"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b1839d88079eb4cd4a57ed9/62b0f8b7fe38d5182598bbf2.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/62a3c9541ee3a75421b40fca/62a9ec147a297243a85f5913.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The politician’s bombastic speech was full of grand promises.",
        "The drill sergeant’s bombastic tone motivated the recruits to give their best.",
        "Her bombastic personality could be overwhelming, but she was undeniably entertaining."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "Bomb-plastic - Speech that's as fake as plastic and explosive"
      ]
    },
    {
      "word": "boring",
      "definition": [
        "not interesting, tedious"
      ],
      "synonyms": [
        "dull",
        "monotonous",
        "repetitious",
        "bland"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The lecture was so boring that several students struggled to stay awake.",
        "Long hours of guard duty can be boring, but focus is essential to stay alert.",
        "He found the novel boring, despite its critical acclaim."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "brawn",
      "definition": [
        "muscular strength"
      ],
      "synonyms": [
        "physical strength",
        "muscle",
        "toughness",
        "burliness",
        "muscular strength",
        "might",
        "powerfulness"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183ac78079eb4cd4a59bc4/629ce95a14bb6d88ae194b91.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The soldiers wore sleeveless shirts and took pleasure in displaying their brawn.",
        "The team relied on his brawn to move the heavy equipment.",
        "In combat, soldiers need both brawn and brains to succeed.",
        "His brawn and determination made him a formidable competitor in wrestling."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "B-raw-n - Be raw and natural strength"
      ]
    },
    {
      "word": "broach",
      "definition": [
        "to suggest or mention for the first time; bring up."
      ],
      "synonyms": [
        "bring up",
        "introduce",
        "propose",
        "suggest",
        "mention"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5e4a8ed1e6a94833685987ff/6291909db071e7e06461cf42.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "She was nervous to broach the topic of a raise with her boss.",
        "The lieutenant decided to broach the subject of additional training with his commanding officer.",
        "He finally broached the idea of a family vacation after years of saving up."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        "Bro-ach - A brother carefully bringing up an achy subject"
      ]
    },
    {
      "word": "canny",
      "definition": [
        "difficult to fool or take advantage of; shrewd; wary; clever; careful, cautious, prudent."
      ],
      "synonyms": [
        "shrewd",
        "sharp",
        "perspicacious",
        "discriminating",
        "perceptive",
        "discerning",
        "penetrating",
        "acute",
        "clever",
        "wise",
        "intelligent"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "Her canny business sense helped her company succeed.",
        "The canny soldier quickly found a solution to the unexpected challenge in the field.",
        "He was known for being canny, always spotting opportunities others missed."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "cede",
      "definition": [
        "to yield or formally surrender"
      ],
      "synonyms": [
        "surrender",
        "concede",
        "hand over",
        "transfer",
        "grant",
        "abandon",
        "relinquish"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The king decided to cede control of the land to his neighboring ally.",
        "In a surprising move, the general chose to cede ground to lure the enemy into a trap.",
        "She had to cede her position as team captain after her injury."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "chronicle",
      "definition": [
        "(noun)  a chronological record of events; a history.",
        "(verb) to record or narrate in the form of a chronicle."
      ],
      "synonyms": [
        "written account",
        "history",
        "annals",
        "archive(s)",
        "log",
        "diary",
        "journal",
        "calendar",
        "chronology",
        "narrative",
        "description",
        "story",
        "record",
        "put on record",
        "write down",
        "set down",
        "document",
        "register",
        "report",
        "enter",
        "narrate",
        "relate",
        "recount",
        "describe",
        "tell about",
        "retail"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The tabloids chronicled the events leading up to the couple's divorce. (verb)",
        "We can read about these battles in the chronicles that were written during those times. (noun)",
        "The historian set out to chronicle the town’s rich history.",
        "The war correspondent aimed to chronicle the experiences of soldiers on the front lines.",
        "His diary was a chronicle of his travels across the globe."
      ],
      "part-of-speech": [
        "noun",
        "verb"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "circumvent",
      "definition": [
        "to find a way around or overcome an obstacle"
      ],
      "synonyms": [
        "avoid",
        "evade",
        "bypass"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "He tried to circumvent the traffic by taking a back road.",
        "The soldiers had to circumvent enemy defenses to reach their objective undetected.",
        "She found a way to circumvent the rules without breaking them outright."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "clemency",
      "definition": [
        "mercy, lenience"
      ],
      "synonyms": [
        "mildness",
        "forbearance",
        "compassion",
        "sympathy"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The judge showed clemency, reducing the sentence due to good behavior.",
        "The general granted clemency to the captured soldiers, showing mercy in a tough situation.",
        "She pleaded for clemency, hoping for understanding despite her mistakes."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "clutter",
      "definition": [
        "(verb): to fill or litter with things in a disorderly manner; to fill or litter with a messy collection of things.",
        "(noun): a disorderly heap or assemblage; litter; a state or condition of confusion."
      ],
      "synonyms": [
        "litter",
        "make untidy",
        "make a mess of",
        "mess up",
        "throw",
        "into disorder",
        "jumble",
        "muddle",
        "disarrange",
        "disarray"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "Don't clutter your mind with useless facts. (verb)",
        "Sentence 2: I picked up the clutter in my room. (noun)",
        "Her desk was covered in clutter, making it hard to find anything.",
        "The command center had to be free of clutter to ensure efficient communication during operations.",
        "He decided to clear the clutter in his room to create a more organized space."
      ],
      "part-of-speech": [
        "noun",
        "verb"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "commiserate",
      "definition": [
        "to feel or express sympathy or sorrow"
      ],
      "synonyms": [
        "sympathize",
        "empathize",
        "reassure",
        "comfort",
        "console",
        "pity",
        "comfort"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b1834c88079eb4cd4a4e318/627969a37e478616fae8abd6.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b96abb0cc77853d885612a2/627915e3b45eb616341af506.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "After the loss, her friends gathered to commiserate with her, offering support and comfort.",
        "She commiserated with her friend over the lost opportunity",
        "The soldiers took a moment to commiserate with the injured after the long battle",
        "We commiserated with the team who missed out on the promotion."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        "'Come and Misery Rate' – Imagine you 'come' to rate someone's misery, as a way to sympathize."
      ]
    },
    {
      "word": "communique",
      "definition": [
        "an official bulletin or communication, usually to the press or public."
      ],
      "synonyms": [
        "official communication",
        "press release",
        "bulletin",
        "message",
        "dispatch",
        "announcement",
        "statement",
        "report",
        "notification"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183ad18079eb4cd4a59ce9/6274115c49bbae143d7a4575.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183ad18079eb4cd4a59ce9/6274115c49bbae143d7a456f.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65fb5224d58ee572fc829824/665c008be7d77a68b546d54c.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The government issued a communique about the new policy",
        "The military received an urgent communique regarding troop movements",
        "An official communique was released following the meeting."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "'Communicate in brief' – Think of a formal, brief message communicated to many."
      ]
    },
    {
      "word": "commute",
      "definition": [
        "(noun): regular travel to or from work or school; a trip made to and from work; or travel regularly over some distance, or to change a prison sentence to a less severe one.",
        "(verb): to ride or drive a long distance to and from work or school."
      ],
      "synonyms": [
        "travel to and from work",
        "reduce",
        "alleviate",
        "limit",
        "mitigate",
        "moderate",
        "travel",
        "shuttle",
        "lessen",
        "shorten",
        "modify",
        "journey",
        "traverse",
        "transit",
        "make the trip"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183a418079eb4cd4a58b85/6273699d49bbae46093d3443.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b96ad8fcc77853d885616ad/6276d6db2827a11674bea22b.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "Her daily commute to work takes about an hour",
        "Many service members commute to the base from nearby towns",
        "He's willing to commute a long distance for his dream job."
      ],
      "part-of-speech": [
        "verb",
        "noun"
      ],
      "mnemonics": [
        "'Commute like a Comet' – Think of traveling frequently, like a comet moving between orbits."
      ]
    },
    {
      "word": "concoct",
      "definition": [
        "to create or think of cleverly"
      ],
      "synonyms": [
        "design",
        "invent",
        "devise",
        "contrive",
        "invent",
        "create",
        "formulate",
        "fabricate"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b18385e8079eb4cd4a55149/6274ddb42827a11674b458e5.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b1837558079eb4cd4a531a0/62a226df60e405740cf52da0.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b1839da8079eb4cd4a57f20/6275cd412827a11674b93528.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "She concocted a story to cover her mistake",
        "The soldier concocted a plan to navigate the tough terrain",
        "He loves to concoct interesting recipes in the kitchen."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        "'Cooking up a concoction' – Imagine someone mixing up ingredients or creating something new."
      ]
    },
    {
      "word": "concur",
      "definition": [
        "to agree, to cooperate; to share the same opinion; agree."
      ],
      "synonyms": [
        "agree",
        "be in harmony",
        "coincide",
        "be in agreement",
        "be of the same opinion",
        "be simultaneous",
        "be concurrent",
        "cooperate",
        "approve",
        "consent",
        "be in accord"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b1839df8079eb4cd4a57fbc/62a15195fdbae9fe9167c2ba.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b1839df8079eb4cd4a57fbc/62a15195fdbae9fe9167c2bb.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "Experts concur that this is the best approach",
        "High-ranking officers concurred on the decision to deploy reinforcements",
        "Most of the team members concurred with the proposed changes."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        "Con and Cur are in agreement' – Think of two people, 'Con' and 'Cur,' both agreeing on something."
      ]
    },
    {
      "word": "confidante",
      "definition": [
        "a person who has a strong liking for and trust in another"
      ],
      "synonyms": [
        "friend",
        "buddy",
        "pal",
        "supporter",
        "ally",
        "advisor",
        "counselor"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65fb5110d58ee572fc8297d1/665b8ea4e7d77a68b5442da9.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b96afc5cc77853d88561a3e/6289575cb071e717a658cf37.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "She considers her sister her closest confidante",
        "Each squad leader acted as a confidante to their team",
        "He told his confidante about his career aspirations."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "'Confide in a confidante' – Someone trustworthy enough to confide in."
      ]
    },
    {
      "word": "conspiracy",
      "definition": [
        "a secret agreement for a dishonest purpose"
      ],
      "synonyms": [
        "plot",
        "scheme",
        "cabal",
        "deception",
        "ploy",
        "intrigue",
        "dodge",
        "trick",
        "machination",
        "plan",
        "collusion"
      ],
      "media": [
        ""
      ],
      "image": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b18353c8079eb4cd4a4f123/627524d62827a11674b5beeb.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b96accecc77853d88561528/6277de6eb05db515a22c2a81.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183c108079eb4cd4a5c22b/627830a3b05db515a22dee47.mp4"
      ],
      "sentences": [
        "There was a conspiracy to overthrow the leader",
        "Military intelligence uncovered a conspiracy targeting key bases",
        "The friends joked about a conspiracy against their favorite team."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "'Con + Spirit' – A group of people 'conned' or scheming with a 'spirit' of secrecy."
      ]
    },
    {
      "word": "construe",
      "definition": [
        "to make plain or understandable; to deduce by inference"
      ],
      "synonyms": [
        "interpret",
        "understand",
        "explain",
        "translate",
        "render",
        "clarify",
        "spell out",
        "infer",
        "decipher",
        "analyze",
        "explain"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65fb50e3d58ee572fc8297be/665b8fd0e7d77a68b544389d.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "Her words could be construed as an insult",
        "The commander construed the order as an urgent directive",
        "The lawyer tried to construe the contract terms in his client’s favor."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        "'Construct the True meaning' – To figure out or interpret the true meaning of something."
      ]
    },
    {
      "word": "contemplate",
      "definition": [
        "to give serious and careful thought to"
      ],
      "synonyms": [
        "ponder",
        "consider",
        "study",
        "review",
        "reflect",
        "think about",
        "meditate"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b1839968079eb4cd4a576e1/628700a2c3731da7ee3ec22f.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b1838e88079eb4cd4a56215/6278242db05db515a22d943f.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183ec68079eb4cd4a614c1/628206af04f5597672d0e993.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "He spent hours contemplating his future",
        "The officer contemplated the mission objectives before briefing his unit",
        "She sat by the lake to contemplate her next move."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        "'Temple in mind' – Imagine someone sitting peacefully like in a temple, deeply thinking."
      ]
    },
    {
      "word": "contentious",
      "definition": [
        "inclined to argue; quarrelsome; belligerent; characterized by argument or controversy",
        "marked by or involving contention."
      ],
      "synonyms": [
        "controversial",
        "disputable",
        "debatable",
        "contended",
        "moot",
        "vexed",
        "argumentative",
        "bickering",
        "quarrelsome",
        "combative",
        "aggressive",
        "confrontational",
        "belligerent"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65fb4f3dd58ee572fc829767/666ebb3dab721a61ab9f5024.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "She had never been one to argue with her mother, but she was becoming more contentious now that she was in high school.",
        "There is now a contentious bill before the legislature.",
        "The debate turned contentious over differing opinions",
        "Rules regarding military discipline can sometimes be contentious",
        "The contentious issue divided the entire community."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "'Contentious Content' – Think of content or topics that cause people to argue or dispute."
      ]
    },
    {
      "word": "conversant",
      "definition": [
        "having information as the result of study or experience"
      ],
      "synonyms": [
        "acquainted",
        "informed",
        "familiar",
        "aware",
        "knowledgeable",
        "well-versed"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b18354a8079eb4cd4a4f2db/62afb5427a2972424fad45df.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65fb53ead58ee572fc8298b1/665bad3ae7d77a68b544ccba.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        " Being conversant in multiple languages gave her a significant advantage in her career.",
        "She is conversant in several foreign languages",
        "The officer was highly conversant with military protocols",
        "He’s conversant in the field of computer science."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "'Versed in Conversation' – If you're conversant, you're knowledgeable enough to keep up in a conversation."
      ]
    },
    {
      "word": "coroner",
      "definition": [
        "a public offical who investigates any death not due to natural causes"
      ],
      "synonyms": [
        "medical examiner",
        "investigator",
        "pathologist",
        "doctor",
        "physician",
        "forensic specialist",
        "autopsy specialist"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183e8a8079eb4cd4a60d92/627565682827a11674b7332e.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The coroner’s report confirmed the cause of death",
        "A military coroner was called to examine the casualties",
        "The coroner arrived to assess the accident scene."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "'C-O-R is for Corpses' – Think of the coroner, the person who examines corpses to find the cause of death."
      ]
    },
    {
      "word": "crevasse",
      "definition": [
        "a deep, narrow opening or crack in thick ice or rock"
      ],
      "synonyms": [
        "chasm",
        "fissure",
        "crevice",
        "cleft",
        "crack",
        "gorge",
        "rift"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5e4a93a6e6a9483368598a18/62a9d7ae7a297243a85bfe84.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b18386c8079eb4cd4a552fa/628e45c5b071e78202f8f4e7.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65fb5639d58ee572fc82996d/66731128ab721a61aba23eb4.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "Mountaineers must be careful not to fall into a crevasse",
        "The soldiers carefully navigated the ice crevasse during their expedition",
        "She peered down the deep crevasse in awe."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "'Crave a Safe Route' – When faced with a crevasse, you crave finding a way around it."
      ]
    },
    {
      "word": "cynical",
      "definition": [
        "having deep distrust of human beings and their motives"
      ],
      "synonyms": [
        "ironic",
        "pessimistic",
        "skeptical",
        "sardonic",
        "ironic",
        "sarcastic",
        "negative",
        "doubtful",
        "mistrustful",
        "jaded"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b1837128079eb4cd4a52964/62760f402827a11674ba7cc5.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183e5f8079eb4cd4a6086f/62a46a8560e4050355324efc.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "His cynical outlook made it hard for him to trust others",
        "Soldiers can sometimes become cynical after long deployments",
        "She was cynical about the politician’s promises."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "Sin of Skepticism' – Imagine a person skeptical of others’ motives, almost sinful in their doubt."
      ]
    },
    {
      "word": "decipher",
      "definition": [
        "to change from a code into ordinary language; to make sense of"
      ],
      "synonyms": [
        "decode",
        "decrypt",
        "work out",
        "solve",
        "interpret",
        "translate",
        "explain",
        "make sense of",
        "solve",
        "unravel"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183ed18079eb4cd4a6161d/6284eecbc3731da7ee348c75.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b96ae3ccc77853d8856182b/6285a1c9c3731da7ee382cbd.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183e338079eb4cd4a60327/6272479849bbaed92564b400.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "They worked together to decipher the ancient text",
        "Intelligence officers were assigned to decipher enemy transmissions",
        "It took her hours to decipher the handwriting on the note."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        "Decode the Cipher' – Think of breaking a code or solving a puzzle."
      ]
    },
    {
      "word": "decorum",
      "definition": [
        "properness of behavior, manner, appearance, or the like; dignity; propriety; dignified manner of behavior, speech, dress, etc."
      ],
      "synonyms": [
        "good taste",
        "politeness",
        "courtesy",
        "decency",
        "properness",
        "propriety",
        "refinement",
        "dignity",
        "etiquette",
        "manners",
        "protocol"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "His decorum at the formal dinner impressed everyone",
        "Military personnel are often expected to maintain strict decorum",
        "She admired the decorum of the guests at the gala."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "decry",
      "definition": [
        "to express strong disapproval of; condemn; to express scornfully one’s low opinion of something"
      ],
      "synonyms": [
        "dismiss",
        "minimize",
        "diminish",
        "criticize",
        "lambaste",
        "disapprove of",
        "disparage",
        "denounce",
        "belittle",
        "attack",
        "condemn",
        "vilify"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "Activists decry the pollution caused by factories",
        "Leaders were quick to decry the enemy’s tactics",
        "She was not afraid to decry injustices in her community."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "defer",
      "definition": [
        "to postpone or to yield to another's wishes"
      ],
      "synonyms": [
        "delay",
        "wait",
        "suspend",
        "acquiesce",
        "concede",
        "give in",
        "postpone",
        "yield",
        "submit",
        "put off"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "He decided to defer his studies for a year",
        "Junior officers often defer to senior leaders for guidance",
        "She had to defer her travel plans due to work obligations."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "demoralize",
      "definition": [
        "to deprive of courage, spirit, or discipline; to cause someone to lose confidence or hope, or to undermine their morale."
      ],
      "synonyms": [
        "dishearten",
        "dismay",
        "discourage",
        "cast down",
        "depress",
        "unsettle",
        "undermine"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The long delays began to demoralize the team",
        "Constant attacks aimed to demoralize the soldiers",
        "The loss of the project funding could demoralize employees."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "demurral",
      "definition": [
        "a feeling or declaration of disapproval or dissent; the act of raising an objection or expressing hesitation or doubt about something"
      ],
      "synonyms": [
        "objection",
        "compliant",
        "protest",
        "challenge",
        "reservation",
        "hesitation",
        "dissent"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "Despite her demurral, she agreed to help with the task",
        "His demurral showed his reluctance to support the mission",
        "The team accepted the plan without much demurral."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "deplete",
      "definition": [
        "to decrease or use up the supply of"
      ],
      "synonyms": [
        "exhaust",
        "consume",
        "drain",
        "diminish",
        "use up",
        "reduce"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "Overuse of resources can deplete the environment",
        "Extended deployments can quickly deplete supplies",
        "His energy began to deplete as the day went on."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "depression",
      "definition": [
        "a state of low spirits"
      ],
      "synonyms": [
        "melancholy",
        "misery",
        "gloominess",
        "sadness",
        "grief",
        "sorrow",
        "dejection",
        "downheartedness",
        "despondency",
        "dispiritedness",
        "paralysis"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "Many people sought help for depression during the pandemic",
        "The prolonged conflict led to increased rates of depression among troops",
        "She managed to overcome her depression with support."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "destitution",
      "definition": [
        "utter poverty, or the extreme want of the necessities of life; lack of means of subsistence"
      ],
      "synonyms": [
        "dire poverty",
        "extreme poverty",
        "impoverishment",
        "indigence",
        "hardship",
        "neediness",
        "want",
        "deprivation",
        "hardship",
        "privation",
        "indigence"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The economic crisis left many families in destitution",
        "Some veterans face destitution without proper support",
        "Programs were established to alleviate destitution in the city."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "diminish",
      "definition": [
        "to make smaller or cause to appear smaller in size or importance; to make or to seem smaller or less important."
      ],
      "synonyms": [
        "decrease",
        "reduce",
        "lessen",
        "shrink",
        "plummet",
        "plunge"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The noise began to diminish as the crowd left",
        "Frequent failures could diminish troop morale",
        "His influence started to diminish over time."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "disclose",
      "definition": [
        "to make something known publicly, or to show something that was hidden; to make known, as information previously kept secret"
      ],
      "synonyms": [
        "reveal",
        "uncover",
        "discover",
        "tell",
        "divulge",
        "reveal",
        "unveil",
        "expose"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The company refused to disclose financial details",
        "The officer decided to disclose key information to the team",
        "She disclosed her plans to only a few close friends."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "disparity",
      "definition": [
        "the condition or an instance of being unlike, unequal, or of different kinds; difference; lack of equality"
      ],
      "synonyms": [
        "discrepancy",
        "inconsistency",
        "imbalance",
        "unevenness",
        "disproportion",
        "variance",
        "difference",
        "inequality",
        "gap",
        "divergence"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "There’s a large disparity in access to healthcare",
        "Disparity in ranks can impact collaboration in the military",
        "The disparity between their skills became apparent."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "dossier",
      "definition": [
        "a file containing detailed records on a particular person or subject"
      ],
      "synonyms": [
        "report",
        "documentation",
        "data",
        "information",
        "file",
        "record",
        "document",
        "portfolio"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The police reviewed the suspect’s dossier before questioning",
        "The military compiled a detailed dossier on the target",
        "Her dossier included references from all previous employers."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "dreadful",
      "definition": [
        "causing fear, shock, or suffering; causing intense disgust, displeasure, or resentment"
      ],
      "synonyms": [
        "awful",
        "horrible",
        "offensive",
        "horrendous",
        "terrible",
        "terrifying",
        "frightful",
        "horrid",
        "atrocious"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The weather was dreadful all weekend",
        "The conditions at the battle site were dreadful",
        "She remembered it as a dreadful experience."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "eclectic",
      "definition": [
        "drawing on or influenced by various styles, disciplines, systems or the like; composed or created by selecting from various styles, disciplines, systems, or the like; consisting of many things of different sorts."
      ],
      "synonyms": [
        "varied",
        "assorted",
        "diverse",
        "chaotic",
        "mixed",
        "diverse",
        "wide-ranging"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "Her taste in music is quite eclectic",
        "The general had an eclectic range of tactics for various situations",
        "He decorated his home with an eclectic mix of styles."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "effulgence",
      "definition": [
        "the quality or state of having or giving off light"
      ],
      "synonyms": [
        "brightness",
        "brilliance",
        "illumination",
        "glow",
        "radiance",
        "shine",
        "luminosity",
        "splendor"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The sun’s effulgence made it hard to see",
        "The explosion’s effulgence illuminated the entire base",
        "She admired the effulgence of the chandelier."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "effusive",
      "definition": [
        " expressing emotions at undue length or intensity; flowing out or over; expressing welcome, approval, or pleasure in a way that shows very strong feeling; showing feelings freely"
      ],
      "synonyms": [
        "emotional",
        "demonstrative",
        "affectionate",
        "uninhibited",
        "outgoing",
        "loving",
        "gushing",
        "overflowing",
        "expressive",
        "enthusiastic",
        "verbose",
        "gushy",
        "exuberant"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "She was effusive in her praise for the team",
        "The officer’s effusive gratitude surprised the soldiers",
        "His effusive welcome made everyone feel at home."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "elicit",
      "definition": [
        "to draw or bring out; to call forth; evoke; to draw out something hidden"
      ],
      "synonyms": [
        "obtain",
        "induce",
        "bring out",
        "generate",
        "excite",
        "draw out",
        "give rise to",
        "extract",
        "call forth",
        "evoke",
        "inspire",
        "engender",
        "prompt",
        "stimulate",
        "provoke"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The teacher’s question was meant to elicit discussion",
        "The drill sergeant tried to elicit a stronger response from the recruits",
        "Her story managed to elicit sympathy from the audience."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "elusive",
      "definition": [
        "hard to find, capture, or isolate"
      ],
      "synonyms": [
        "slippery",
        "transient",
        "fleeting",
        "temporary",
        "evasive",
        "tricky",
        "hard to grasp",
        "ambiguous"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The solution to the problem proved elusive",
        "The elusive target made tracking difficult for the reconnaissance team",
        "He found happiness to be an elusive goal."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "'Lose Track' – Imagine something that keeps slipping away and is hard to catch."
      ]
    },
    {
      "word": "embargo",
      "definition": [
        "a government order that restricts trade or the shipment of goods; a government order that forbids ships to move in or out of its ports; an order that something not be done or used; to prohibit"
      ],
      "synonyms": [
        "prohibition",
        "ban",
        "exclude",
        "prevent",
        "halt",
        "limitation",
        "veto",
        "injunction",
        "restriction",
        "interdiction",
        "warning",
        "blockade"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The solution to the problem proved elusive",
        "The elusive target made tracking difficult for the reconnaissance team",
        "He found happiness to be an elusive goal."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "'Bar Goods' – Imagine a bar or restriction on goods or trade."
      ]
    },
    {
      "word": "eminent",
      "definition": [
        "standing above others in rank, importance, or achievement"
      ],
      "synonyms": [
        "distinguished",
        "famous",
        "notable",
        "honored",
        "prominent",
        "renowned",
        "prestigious"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The professor is an eminent scholar in his field",
        "Several eminent leaders attended the military conference",
        "She met an eminent scientist at the event."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "'Eminent = Prominent' – Think of someone well-known and respected in their field."
      ]
    },
    {
      "word": "emphasize",
      "definition": [
        "to lay stress upon, to give emphasis to"
      ],
      "synonyms": [
        "bring attention to",
        "draw attention to",
        "call attention to",
        "focus attention on",
        "highlight",
        "stress",
        "spotlight"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "He emphasized the importance of teamwork",
        "The instructor emphasized safety during weapons training",
        "She wanted to emphasize her main point."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        "'Make Important' – Think of highlighting or stressing something."
      ]
    },
    {
      "word": "encampment",
      "definition": [
        "a place where people live in tents, usually for a short time"
      ],
      "synonyms": [
        "campsite",
        "bivouac",
        "settlement"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The refugees formed an encampment near the river",
        "The soldiers secured their encampment for the night",
        "The archaeological site revealed signs of an ancient encampment."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "Camp Setup' – A temporary setup where troops or travelers stay."
      ]
    },
    {
      "word": "engulf",
      "definition": [
        " completely surround, cover, or swallow up something, often in a powerful or overwhelming way."
      ],
      "synonyms": [
        "swallow",
        "immerse",
        "envelop",
        "submerge",
        "consume"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The military operation was swift, designed to engulf the enemy forces completely.",
        "The floodwaters began to engulf the city, covering entire neighborhoods.",
        "Sadness can sometimes engulf us unexpectedly, taking over our thoughts."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        "'En' + 'gulf'—think of something entering a 'gulf' or hollow and completely filling it.",
        "'Engulf' sounds like 'in gulf,' picturing something being taken in or surrounded by a large gulf or wave."
      ]
    },
    {
      "word": "enclosure",
      "definition": [
        "inserted, as inside an envelope"
      ],
      "synonyms": [
        "court",
        "paddock",
        "yard",
        "compound",
        "pen",
        "cage",
        "corral",
        "inclusion",
        "addition"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The military established a secure enclosure around the base.",
        "The farmer built an enclosure to keep the animals safe.",
        "We entered a large enclosure filled with exotic birds at the zoo."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "'En' + 'closure'—a closed area, like a 'closure' that keeps things inside.",
        "Picture an 'enclosed' space, like a fenced-off area, to remember it’s a contained area."
      ]
    },
    {
      "word": "ensconce",
      "definition": [
        "to place snugly or put into a hiding place"
      ],
      "synonyms": [
        "lodge",
        "settle",
        "position",
        "conceal",
        "hide",
        "stash"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The general decided to ensconce himself in a bunker to oversee the operations safely.",
        "After a long day, she would ensconce herself in a cozy chair with a good book.",
        "He managed to ensconce himself in his office, shutting out all distractions."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        "'En' + 'sconce'—imagine settling into a 'sconce' (a small nook) to feel safe.",
        "To 'ensconce' is like 'en-safety,' placing yourself securely in a safe spot."
      ]
    },
    {
      "word": "erratic",
      "definition": [
        "deviating from the usual or proper course"
      ],
      "synonyms": [
        "unpredictable",
        "inconsistent",
        "changeable",
        "variable",
        "irregular",
        "inconstant",
        "uncertain",
        "unstable",
        "turbulent"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The soldier's erratic behavior raised concern among his superiors.",
        "Her breathing became erratic as panic set in.",
        "The car's erratic movements made it difficult to follow on the road."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "'Erratic' sounds like 'error'—imagine an error causing unpredictable or inconsistent results.",
        "Erratic sounds like 'err'—think of something going wrong or off-course repeatedly."
      ]
    },
    {
      "word": "erudite",
      "definition": [
        "having advanced knowledge or education"
      ],
      "synonyms": [
        "scholarly",
        "knowledgeable",
        "academic",
        "informed"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The military historian was known for his erudite understanding of war tactics.",
        "She impressed everyone with her erudite insights into ancient philosophy.",
        "An erudite person often enjoys discussing topics deeply and thoughtfully."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "'Eru' + 'dite'—picture someone with a 'bright light' of knowledge.",
        "Erudite sounds like 'educated'—easy to remember that it means scholarly or well-read."
      ]
    },
    {
      "word": "ethics",
      "definition": [
        "code of good conduct"
      ],
      "synonyms": [
        "fairness",
        "integrity",
        "morality",
        "principles",
        "morals",
        "standards",
        "virtue"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The soldier faced an ethical dilemma during the mission.",
        "Medical professionals are guided by strict ethics in their work.",
        "Ethics dictate how we should treat each other fairly and justly."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "Ethics sounds like 'ethos' (values), making it easier to remember it means moral principles.",
        "Picture 'ethics' as 'rules' or principles that 'stick' to one's actions and choices."
      ]
    },
    {
      "word": "excel",
      "definition": [
        "to be greater, better, or stronger than"
      ],
      "synonyms": [
        "shine",
        "be very good",
        "be excellent",
        "be",
        "brilliant",
        "be outstanding",
        "be skillful",
        "be talented",
        "be proficient",
        "be expert",
        "be preeminent",
        "reign supreme",
        "exceed",
        "surpass",
        "outdo",
        "transcend"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The captain encouraged his soldiers to excel in every mission.",
        "She worked hard to excel in her studies.",
        "The athlete trained rigorously to excel in his performance."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        "Excel sounds like 'exceed'—to go beyond the standard or expectation.",
        "'Ex' + 'cell'—to rise above your own 'cell' or limitations and stand out."
      ]
    },
    {
      "word": "exploit",
      "definition": [
        "an act of notable skill, strength, or cleverness; or to take unfair advantage of"
      ],
      "synonyms": [
        "feat",
        "deed",
        "achievement",
        "abuse",
        "manipulate",
        "mistreat"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The commander decided to exploit a weakness in the enemy's defense.",
        "Companies often exploit loopholes in tax laws.",
        "He aimed to exploit his skills to achieve success."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        "'Exploit' sounds like 'explore,' with a sense of taking advantage of something.",
        "Think of 'ex' (outside) + 'plot'—going beyond the usual plot or expectation to make use of opportunities."
      ]
    },
    {
      "word": "faction",
      "definition": [
        "a group of people acting together within a larger group"
      ],
      "synonyms": [
        "coalition",
        "team",
        "movement",
        "schism"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183e658079eb4cd4a60914/627e3a8f8d33c5b0c1608d2c.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b96b03dcc77853d88561af8/6287149cc3731da7ee3f16cf.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183c238079eb4cd4a5c468/6288598dc3731da7ee43ec4d.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183e718079eb4cd4a60a90/62884e33c3731da7ee43c0d9.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "A faction within the military advocated for a different strategy.",
        "Political factions within the government often have differing views.",
        "The team split into factions due to differing goals."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "Faction sounds like 'fraction'—a small group separated from a larger whole.",
        "Picture factions as 'fractures' or divisions within a group."
      ]
    },
    {
      "word": "fame",
      "definition": [
        "popular acclaim"
      ],
      "synonyms": [
        "renown",
        "celebrity",
        "prominence",
        "stardom",
        "popularity",
        "notability",
        "distinction"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The war hero earned fame for his bravery on the battlefield.",
        "The singer's fame grew after the release of her latest album.",
        "He pursued fame as a way to prove his talent to the world."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "Fame sounds like 'name'—a recognized name becomes famous.",
        "Think of 'fame' as an abbreviation for 'familiar name,' someone everyone knows."
      ]
    },
    {
      "word": "fate",
      "definition": [
        "the determining cause by which things happen, or the final outcome"
      ],
      "synonyms": [
        "destiny",
        "providence",
        "chance",
        "luck",
        "God's will",
        "nemesis",
        "kismet",
        "predestination"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The soldier trusted fate to guide his actions on the battlefield.",
        "She believed fate had brought them together.",
        "Sometimes, fate seems to have its own plans for us."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "Fate sounds like 'date'—as if life has a specific date or destiny set for us.",
        "Think of fate as the 'fate line' on one's palm, symbolizing destiny."
      ]
    },
    {
      "word": "favor",
      "definition": [
        "an act of kind assistance; or to do a service for or to prefer"
      ],
      "synonyms": [
        "service",
        "grace",
        "courtesy",
        "help",
        "assist",
        "please",
        "like",
        "endorse"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The general did him a favor by approving his leave request.",
        "She asked for a favor from her friend in a time of need.",
        "Politicians often seek public favor through their actions."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "'Favor' sounds like 'favoring' someone or something—easy to remember as support or help.",
        "Favor rhymes with 'savior'—something or someone helpful."
      ]
    },
    {
      "word": "fecund",
      "definition": [
        "producing abundantly"
      ],
      "synonyms": [
        "fertile",
        "abundant",
        "plentiful",
        "inventive"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183ec18079eb4cd4a61430/627e86dd8d33c5b0c16231ae.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65f8a030b267755af6cf7043/6671e1c5ab721a61aba175e1.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183d718079eb4cd4a5ebc4/62a5924b60e40503553791f7.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The land was so fecund that crops grew abundantly with little effort.",
        "His mind was fecund with creative ideas.",
        "The fecund valley was a strategic point for military supplies."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "Fecund sounds like 'feast'—think of abundant harvests or fertile land.",
        "Picture 'fecund' as 'fecal' + 'underground,' symbolizing soil rich in nutrients for growth."
      ]
    },
    {
      "word": "feign",
      "definition": [
        "to give a false appearance of"
      ],
      "synonyms": [
        "simulate",
        "fake",
        "sham",
        "give the",
        "appearance of",
        "pretend",
        "masquerade"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183c4e8079eb4cd4a5c99a/62863630c3731da7ee3b2f90.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/660b232ded50ea5a54311328/665aa50fe7d77a68b53ed0bf.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65f8a7adb267755af6cf7309/665a6122e7d77a68b53d59df.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65f89d53b267755af6cf6f41/666befcdab721a61ab9ad665.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The spy had to feign innocence to avoid suspicion.",
        "She would feign surprise at every gift, even if she knew what it was.",
        "The animal feigned death to escape the predator."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        "Feign sounds like 'fake'—to pretend or put on an appearance.",
        "'Feign' rhymes with 'gain'—think of pretending something to gain an advantage."
      ]
    },
    {
      "word": "fervor",
      "definition": [
        "intensity of feeling or expression"
      ],
      "synonyms": [
        "ardor",
        "zeal",
        "enthusiasm",
        "fervency",
        "intensity",
        "passion",
        "emotion"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183cb88079eb4cd4a5d608/62a71af8d12c875e2fc30ed6.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b1837238079eb4cd4a52b80/62a82f9cd12c875e2fc6eaf3.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/6616b65a2395f6443818ba73/66738b43ab721a61aba29de7.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The soldiers marched with fervor, fully committed to their mission.",
        "She spoke about her beliefs with such fervor that everyone listened intently.",
        "His fervor for helping others drove him to volunteer every weekend."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "'Fervor' sounds like 'fever'—think of a passionate intensity, like a fever burning within.",
        "Fervor starts with 'fer,' similar to 'fire,' representing intense enthusiasm or passion."
      ]
    },
    {
      "word": "fiction",
      "definition": [
        "something that is the product of the imagination"
      ],
      "synonyms": [
        "fantasy",
        "tale",
        "story",
        "novel",
        "fabrication",
        "fable",
        "invention"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The general's story of the victory was more fiction than fact.",
        "She enjoys reading fiction to escape into different worlds.",
        "Sometimes, fiction can reveal truths that reality hides."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "'Fiction' sounds like 'fake'—think of made-up stories.",
        "'Fict' resembles 'fact' but with a twist, symbolizing the imagined or unreal."
      ]
    },
    {
      "word": "flagrant",
      "definition": [
        "obviously inconsistent with what is right or proper"
      ],
      "synonyms": [
        "blatant",
        "conspicuous",
        "egregious",
        "brazen",
        "glaring",
        "gross",
        "obvious",
        "overt"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The soldier's flagrant disregard for orders led to disciplinary action.",
        "Her flagrant violation of the rules could not go unnoticed.",
        "The flagrant error in the report was obvious to everyone."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "Flagrant sounds like 'flame'—an obvious mistake that stands out like a flame.",
        "Flagrant sounds like 'flag'—imagine a big, bright flag waving to draw attention to a glaring mistake."
      ]
    },
    {
      "word": "fortuitous",
      "definition": [
        "happening by good luck, especially unexpectedly"
      ],
      "synonyms": [
        "fortunate",
        "coincidental",
        "lucky",
        "happy"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The fortuitous arrival of reinforcements turned the tide in the battle.",
        "Meeting her childhood friend at the airport was a fortuitous event.",
        "A fortuitous discovery led to the breakthrough in the research."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "Fortuitous sounds like 'fortune,' suggesting a lucky or chance occurrence.",
        "Think of 'for-tune,' as if fortune happened for a good outcome by chance."
      ]
    },
    {
      "word": "foster",
      "definition": [
        "to help the growth or development of"
      ],
      "synonyms": [
        "nurture",
        "encourage",
        "raise",
        "promote"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The sergeant aimed to foster camaraderie among his troops.",
        "Parents foster a sense of responsibility in their children by giving them tasks.",
        "The school seeks to foster creativity in its students."
      ],
      "part-of-speech": [
        "verb",
        "adjective"
      ],
      "mnemonics": [
        "'Foster' sounds like 'foster care'—to support, encourage, or nurture.",
        "'Foster' rhymes with 'faster'—encouraging someone to grow or improve quickly."
      ]
    },
    {
      "word": "fraction",
      "definition": [
        "irregular part of something that remains incomplete"
      ],
      "synonyms": [
        "fragment",
        "piece",
        "portion"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "A small faction of the troops split into different fractions during the debate.",
        "He only ate a fraction of his meal before leaving.",
        "The project required dividing the funds into fractions for each team."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "'Fraction' sounds like 'fracture'—a part or piece of a whole.",
        "Think of 'fraction' as 'fractional' or partial, representing a small part of something."
      ]
    },
    {
      "word": "fragility",
      "definition": [
        "the quality of having a delicate structure"
      ],
      "synonyms": [
        "exquisiteness",
        "brittleness",
        "insubstantiality"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b18387a8079eb4cd4a554b5/6274fd312827a11674b4f4bc.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183dd88079eb4cd4a5f84e/6286234ac3731da7ee3acfe6.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b1834678079eb4cd4a4d778/628e1c0db071e78202f8274c.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/660c2354011b7046ee3fc57d/66608d8e877ad250fbbd246e.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The fragility of the truce meant any small incident could spark conflict.",
        "The fragility of the vase made it necessary to handle it with care.",
        "Emotional fragility can make stressful situations even harder to manage."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "Fragility sounds like 'fragile'—easily broken or delicate.",
        "Think of 'frag' as a piece breaking off, like something easily shattered."
      ]
    },
    {
      "word": "frolic",
      "definition": [
        "a time of carefree fun; or to play and run about happily"
      ],
      "synonyms": [
        "spree",
        "festivity",
        "fling",
        "cavort",
        "dance",
        "skip",
        "prance"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "After the mission, the soldiers enjoyed a rare moment to frolic in the fields.",
        "The puppies frolic in the park, chasing each other with boundless energy.",
        "Children frolic on the playground, laughing and running around."
      ],
      "part-of-speech": [
        "verb",
        "noun"
      ],
      "mnemonics": [
        "Frolic sounds like 'roll'—imagine rolling around playfully.",
        "Picture 'frolic' as 'free' and 'play,' representing joyful, carefree movement."
      ]
    },
    {
      "word": "gaudy",
      "definition": [
        "excessively showy"
      ],
      "synonyms": [
        "loud",
        "flashy",
        "garish",
        "tawdry"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The officer's uniform was stylish without being gaudy.",
        "The decorations were so gaudy that they overwhelmed the small room.",
        "Her gaudy jewelry caught everyone’s attention, but not in a good way."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "'Gaudy' sounds like 'gawdy' or 'gawdy'—too bright or showy.",
        "Think of 'gaudy' as 'garish'—overly flashy and lacking taste."
      ]
    },
    {
      "word": "gawk",
      "definition": [
        "to gape or stare stupidly"
      ],
      "synonyms": [
        "stare",
        "rubberneck",
        "peer",
        "ogle"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The recruits couldn’t help but gawk at the impressive tanks rolling in.",
        "People gawked at the street performers as they passed by.",
        "He tried not to gawk, but the skyscraper was just so tall."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        "'Gawk' sounds like 'gaze awkwardly,' suggesting a stare that’s unintentional or clumsy.",
        "Think of 'gawk' as a mix of 'gaze' and 'awkward,' for a fixed, surprised stare."
      ]
    },
    {
      "word": "gaze",
      "definition": [
        "to look long and hard in wonder or surprise"
      ],
      "synonyms": [
        "gape",
        "gawk",
        "stare",
        "watch"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The soldier gazed into the distance, lost in thought.",
        "She gazed at the stars, mesmerized by their beauty.",
        "He held her gaze, silently communicating his feelings."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        "Gaze sounds like 'glaze,' as in a soft, dreamy look.",
        "Think of 'gaze' as 'a gaze' or steady look, suggesting focus and attention."
      ]
    },
    {
      "word": "glean",
      "definition": [
        "to collect gradually and bit by bit"
      ],
      "synonyms": [
        "gather",
        "harvest",
        "obtain"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The intelligence team worked to glean crucial information from enemy communications.",
        "From their conversation, she managed to glean some insights into his personality.",
        "Farmers glean leftover crops from the fields after the main harvest."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        "Glean sounds like 'clean,' as in carefully picking or gathering small details.",
        "Think of 'glean' as 'glee' from gathering information bit by bit."
      ]
    },
    {
      "word": "green",
      "definition": [
        "lacking in adult experience or maturity"
      ],
      "synonyms": [
        "inexperienced",
        "immature",
        "young",
        "naive"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The soldiers were mostly green recruits with little experience.",
        "Green fields stretched as far as the eye could see.",
        "He was still green at his job, needing guidance from his seniors."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "'Green' is like 'new,' representing freshness and inexperience.",
        "Green, as in 'growing,' represents something that’s still developing."
      ]
    },
    {
      "word": "halt",
      "definition": [
        "to bring to a standstill"
      ],
      "synonyms": [
        "stop",
        "stall",
        "catch",
        "arrest",
        "block",
        "impede",
        "detain",
        "hold up",
        "break",
        "pause",
        "come to a standstill"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The commander ordered his troops to halt immediately.",
        "She had to halt her car as a deer crossed the road.",
        "The project came to a sudden halt due to budget issues."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        "'Halt' sounds like 'halt!'—a command to stop.",
        "Think of 'halt' as 'halted' in place, signaling a complete stop."
      ]
    },
    {
      "word": "hankering",
      "definition": [
        "a strong wish for something"
      ],
      "synonyms": [
        "craving",
        "longing",
        "desire",
        "yearning"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "After months in the field, the soldier had a hankering for home-cooked meals.",
        "She felt a sudden hankering for chocolate.",
        "He had a hankering to visit the beach and relax."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "'Hankering' sounds like 'hunger,' representing a strong desire.",
        "Think of 'hankering' as 'hankering for,' a craving or yearning."
      ]
    },
    {
      "word": "hesitant",
      "definition": [
        "slow to proceed because of doubt"
      ],
      "synonyms": [
        "reluctant",
        "skeptical",
        "unsure",
        "uncertain"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The rookie soldier was hesitant to follow the risky order.",
        "She felt hesitant about making such a big commitment.",
        "He was hesitant to speak up, fearing he might be wrong."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "'Hesitant' sounds like 'hesitate,' meaning unsure or unwilling.",
        "Hesitant has 'hesi,' like 'hesitate,' symbolizing doubt."
      ]
    },
    {
      "word": "hiatus",
      "definition": [
        "a break in continuity"
      ],
      "synonyms": [
        "interruption",
        "pause",
        "interval",
        "interlude"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "After the war, the general took a hiatus from military duties.",
        "The band went on a hiatus to focus on solo projects.",
        "The show went on a brief hiatus between seasons."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "'Hiatus' sounds like 'pause,' representing a break.",
        "Hiatus has 'hi' in it, like taking a break to say 'hi' later."
      ]
    },
    {
      "word": "hindrance",
      "definition": [
        "something that makes progress difficult"
      ],
      "synonyms": [
        "obstacle",
        "hurdle",
        "obstruction",
        "limitation",
        "barrier",
        "impediment",
        "brake",
        "restraint",
        "handicap"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "Heavy rain was a hindrance to the troops' progress.",
        "Lack of funds proved to be a hindrance to the project.",
        "Her nervousness was a hindrance to her public speaking."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "'Hindrance' sounds like 'hind,' like being held back or delayed.",
        "Think of 'hindrance' as 'hind' + 'dance,' blocking forward movement."
      ]
    },
    {
      "word": "horrendous",
      "definition": [
        "extremely disturbing or repellant"
      ],
      "synonyms": [
        "gruesome",
        "shocking",
        "terrible",
        "horrific"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The battlefield conditions were horrendous during the storm.",
        "The horror movie had horrendous scenes that shocked viewers.",
        "The horrendous traffic made her late to the meeting."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "'Horrendous' sounds like 'horror'—something dreadful or shocking.",
        "Think of 'horrendous' as an extreme version of 'horrible.'"
      ]
    },
    {
      "word": "hypocrisy",
      "definition": [
        "the practice of claiming to have moral standards to which one's own behavior does not conform"
      ],
      "synonyms": [
        "insincerity",
        "deception",
        "pretense",
        "dishonesty",
        "cant",
        "sanctimony",
        "posturing",
        "false virtue",
        "humbug",
        "deceit"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The leader’s hypocrisy became evident when he ignored his own orders.",
        "She was called out for hypocrisy when she didn't follow her own advice.",
        "Hypocrisy in politics often frustrates the public."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "'Hypocrisy' has 'hypo,' like 'hypo'critical or fake.",
        "Hypocrisy sounds like 'hypo' and 'crazy,' a 'crazy' double standard."
      ]
    },
    {
      "word": "illusion",
      "definition": [
        "a false idea or conception"
      ],
      "synonyms": [
        "delusion",
        "fallacy",
        "dream",
        "fantasy",
        "vision",
        "mirage",
        "hallucination",
        "specter",
        "phantom",
        "misconception"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The enemy's numbers were an illusion; they were fewer than they appeared.",
        "The magician created an illusion that left everyone stunned.",
        "Success can sometimes be an illusion, hiding deeper issues."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "'Illusion' sounds like 'illusionist,' a trick or misleading appearance.",
        "Think of 'illusion' as 'ill' + 'vision,' something deceptive to the eyes."
      ]
    },
    {
      "word": "impediment",
      "definition": [
        "something that makes movement difficult"
      ],
      "synonyms": [
        "deterrent",
        "obstacle",
        "hindrance",
        "interference"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The muddy terrain was an impediment to the advancing troops.",
        "His speech impediment made public speaking difficult.",
        "Lack of resources was a major impediment to the project."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "'Impediment' sounds like 'impede,' meaning to block or hinder.",
        "Think of 'impediment' as a 'mental' block, something standing in the way."
      ]
    },
    {
      "word": "impetuous",
      "definition": [
        "acting quickly and without thought, controlled by emotion"
      ],
      "synonyms": [
        "impulsive",
        "adventurous",
        "reckless",
        "thoughtless"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183e598079eb4cd4a607bd/628d2001b071e7adb822fe5d.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/62a0f1210502e54f654c4d83/62a4116560e4056ac7efeb02.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183cc18079eb4cd4a5d70e/62a80139d12c875e2fc655c2.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "His impetuous decision to attack without orders caused trouble.",
        "She made an impetuous choice to travel without planning.",
        "He often acted in an impetuous way, without considering the consequences."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "'Impetuous' sounds like 'impulse,' representing acting on a whim.",
        "Think of 'im-pet' as a pet's quick, unpredictable movement."
      ]
    },
    {
      "word": "impromptu",
      "definition": [
        "made or done without previous thought or preparation"
      ],
      "synonyms": [
        "spontaneous",
        "spur-of-the-moment",
        "unplanned"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65f8a58cb267755af6cf725b/665b3e18e7d77a68b542586f.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183e468079eb4cd4a60567/627839a4b05db515a22e0dcf.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183acd8079eb4cd4a59c62/62876e72c3731da7ee4065a9.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65f89d55b267755af6cf6f42/666bf5b7ab721a61ab9ad9a1.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The commander gave an impromptu speech to encourage his soldiers.",
        "They had an impromptu meeting in the hallway to discuss the changes.",
        "Her impromptu decision to join the party surprised everyone."
      ],
      "part-of-speech": [
        "adjective",
        "verb",
        "noun"
      ],
      "mnemonics": [
        "'Impromptu' sounds like 'prompt,' suggesting something done on the spot.",
        "Think of 'impromptu' as 'im-prompt,' quick and unplanned."
      ]
    },
    {
      "word": "incisive",
      "definition": [
        "very clear and direct"
      ],
      "synonyms": [
        "concise",
        "keen",
        "blunt"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "Her incisive questions cut through the confusion and got to the core of the issue.",
        "The commander’s incisive decisions in battle led to a strategic advantage.",
        "His incisive wit made him popular among his peers but often left others speechless."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "Incisive people make decisions that cut through the noise.",
        "Think of incisors; they’re sharp and get to the point."
      ]
    },
    {
      "word": "inept",
      "definition": [
        "lacking qualities required to do a job"
      ],
      "synonyms": [
        "incompetent",
        "incapable",
        "unskilled",
        "inefficient",
        "unprofessional",
        "clumsy",
        "ineffective",
        "crude",
        "amateurish"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65f8a19eb267755af6cf70e6/665ac727e7d77a68b53fa429.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183d9a8079eb4cd4a5f0c0/6273a4b749bbae46093e7758.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183eb28079eb4cd4a61257/6285ba23c3731da7ee38a33b.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b18394c8079eb4cd4a56e10/629099e0b071e76046cbd68d.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183d188079eb4cd4a5e12e/627f15ce8d33c5b0c1653955.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183dd98079eb4cd4a5f87e/62a56dc060e4050355371e8f.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b18394c8079eb4cd4a56e10/629099e0b071e76046cbd8c7.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "He felt inept at handling the complex tasks given to him.",
        "The soldier’s inept handling of the equipment led to a temporary setback.",
        "She was worried she would appear inept in her new job, but she quickly adapted."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "An inept person is ‘in’ the ‘depth’ of mistakes.",
        "Think of ‘in’ + ‘apt,’ meaning lacking the skill or aptitude."
      ]
    },
    {
      "word": "infer",
      "definition": [
        "to derive by reasoning, to draw a conclusion"
      ],
      "synonyms": [
        "deduce",
        "presume",
        "surmise",
        "understand"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "From the tone of his voice, she could infer that he was upset.",
        "The intelligence officer was trained to infer potential threats from limited information.",
        "Reading between the lines, she could infer his true intentions."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        "Infer sounds like ‘information’—drawing meaning from information.",
        "If you ‘refer’ to clues, you can ‘infer’ the answer."
      ]
    },
    {
      "word": "infiltrate",
      "definition": [
        "to pass through small openings"
      ],
      "synonyms": [
        "penetrate",
        "pervade",
        "permeate",
        "filter through",
        "insinuate oneself",
        "intrude on",
        "invade",
        "trespass on",
        "access"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65f8835cb267755af6cf6b77/665a3c01e7d77a68b53c76d4.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183a498079eb4cd4a58c75/62bc35e58c808c49caa99074.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The spy was able to infiltrate the enemy organization.",
        "Special forces successfully infiltrate hostile territory to gather intelligence.",
        "The group tried to infiltrate the company to gather trade secrets."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        "Think of ‘in’ + ‘filter’—going in without being noticed.",
        "Infiltrate: ‘in’ + ‘filtrate’—like filtering into something secretly."
      ]
    },
    {
      "word": "insignia",
      "definition": [
        "a sign that indicates membership in a particular group or the attainment of a particular rank"
      ],
      "synonyms": [
        "ribbon",
        "badge",
        "medal",
        "decoration"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The soldiers wore the insignia of their respective units.",
        "In the military, the insignia symbolizes rank and role.",
        "He wore the insignia of his office proudly."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "Insignia: think of ‘in-sign,’ a sign that represents status.",
        "‘In sign’—a sign of authority or group membership."
      ]
    },
    {
      "word": "intervene",
      "definition": [
        "come between so as to prevent or alter a result or course of events."
      ],
      "synonyms": [
        "intercede",
        "negotiate",
        "mediate",
        "meddle"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b1837bf8079eb4cd4a53e5b/62a65bb4d12c8718fb1e1073.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65fb5290d58ee572fc829851/665c0bdee7d77a68b5472782.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The teacher had to intervene to prevent a fight between students.",
        "The peacekeeping forces were sent to intervene in the conflict.",
        "He felt the need to intervene in his friend’s argument to help resolve it."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        "Intervene sounds like ‘enter’—to step in between.",
        "To ‘interfere’ to ‘prevent’ is to intervene."
      ]
    },
    {
      "word": "intrinsic",
      "definition": [
        "being a part of the innermost nature of a person or thing"
      ],
      "synonyms": [
        "inherent",
        "innate",
        "ingrained"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The value of the diamond was intrinsic, beyond its appearance.",
        "The soldier’s loyalty was intrinsic, stemming from a deep sense of duty.",
        "Her love for music was intrinsic, not based on external factors."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "Intrinsic is like ‘in-train’—it’s within or natural.",
        "Think of intrinsic as ‘in-trust’—deeply embedded or essential."
      ]
    },
    {
      "word": "invariably",
      "definition": [
        "on every relevant occasion"
      ],
      "synonyms": [
        "always",
        "constantly",
        "consistently"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "He invariably wakes up at 6 a.m., no matter the day.",
        "The commander’s instructions invariably prioritize troop safety.",
        "Her kind nature invariably shines through, even in tough times."
      ],
      "part-of-speech": [
        "adverb"
      ],
      "mnemonics": [
        "Invariably means ‘in-variable’—it doesn’t change.",
        "Invariably is ‘in’ + ‘vary’—does not vary or change."
      ]
    },
    {
      "word": "invigorate",
      "definition": [
        "to give life, vigor, or spirit to"
      ],
      "synonyms": [
        "strengthen",
        "vitalize",
        "refresh",
        "stimulate",
        "brace",
        "energize",
        "rejuvenate"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "A brisk walk in the morning helps invigorate the mind and body.",
        "The pep talk invigorated the troops before the operation.",
        "The new project invigorated her passion for her work."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        "Invigorate sounds like ‘in-vigor’—to put vigor or energy in.",
        "Think of vigor—adding life or energy."
      ]
    },
    {
      "word": "jest",
      "definition": [
        "something said or done to cause laughter; or to make jokes"
      ],
      "synonyms": [
        "witticism",
        "gag",
        "fun",
        "quip",
        "banter",
        "wisecrack"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183ab98079eb4cd4a599f3/627810a7b05db515a22d2247.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b96ad78cc77853d88561676/628a17c5b071e717a65b48d0.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/660b2765ed50ea5a54311486/665edadd877ad250fbbbb9c5.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65f8a7f4b267755af6cf732b/665a71b3e7d77a68b53db09d.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "His playful jest lightened the mood in the room.",
        "The soldiers exchanged jests to keep morale high during their mission.",
        "She made a jest about the weather, making everyone laugh."
      ],
      "part-of-speech": [
        "noun",
        "verb"
      ],
      "mnemonics": [
        "Jest sounds like ‘jest-er’—a joke or funny remark.",
        "Think of ‘jest’ as ‘joke’—both light-hearted."
      ]
    },
    {
      "word": "kink",
      "definition": [
        "a twist or bend in something"
      ],
      "synonyms": [
        "twist",
        "curl",
        "crimp",
        "wrinkle",
        "knot",
        "frizz",
        "coil",
        "tangle",
        "entanglement"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The hose had a kink in it, blocking the flow of water.",
        "The team found a kink in the plan that needed adjustment before the operation.",
        "She noticed a kink in the design and worked to resolve it."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "Kink sounds like ‘link’—a kink in the link or line creates a twist.",
        "Imagine ‘kinks’ in a wire, causing it to stop flowing straight."
      ]
    },
    {
      "word": "laden",
      "definition": [
        "having a weight or burden on"
      ],
      "synonyms": [
        "loaded",
        "burdened",
        "filled",
        "encumbered"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The ship was laden with goods from overseas.",
        "The soldiers’ packs were laden with supplies for the mission.",
        "Her arms were laden with gifts as she walked in."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "Think of ‘lay down’—laden with weight.",
        "Laden sounds like ‘loaded’—carrying a heavy load."
      ]
    },
    {
      "word": "lambaste",
      "definition": [
        "to criticize severely or angrily"
      ],
      "synonyms": [
        "berate",
        "criticize",
        "chastise",
        "castigate",
        "attack",
        "trounce",
        "censure",
        "chew out",
        "denounce"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183cb18079eb4cd4a5d533/629d317e14bb6d88ae1aa85d.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The coach lambasted the team for their lack of effort during practice.",
        "The sergeant lambasted the recruits for not following protocol.",
        "She was lambasted by critics for her latest performance."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        "Lambaste: think of ‘lamb’ being ‘basted’ harshly with words.",
        "Imagine a lamb getting ‘basted’—criticized or scolded intensely."
      ]
    },
    {
      "word": "latent",
      "definition": [
        "present but invisible or inactive"
      ],
      "synonyms": [
        "hidden",
        "secret",
        "concealed",
        "invisible",
        "suppressed",
        "underlying",
        "veiled",
        "dormant",
        "inactive",
        "unrevealed"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/628cdacee1c3e7c82c20a9fc/62b11bddfe38d51825997332.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b1836f28079eb4cd4a52580/62af8d7a7a2972424fac869b.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "Her latent talent for painting surfaced later in life.",
        "The soldiers were trained to identify latent threats that might emerge unexpectedly.",
        "The disease remained latent, showing no symptoms initially."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "Latent sounds like ‘late’—something present but appearing late.",
        "Think of ‘laying dormant’—latent is hidden but present."
      ]
    },
    {
      "word": "lewd",
      "definition": [
        "referring to sexual matters in an unacceptable way"
      ],
      "synonyms": [
        "vulgar",
        "crude",
        "smutty",
        "dirty",
        "filthy",
        "obscene",
        "tasteless",
        "lascivious",
        "indecent",
        "lustful",
        "salacious",
        "bawdy",
        "offensive",
        "pornographic"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183a858079eb4cd4a59385/627e01108d33c5b0c15f5853.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/660b224ded50ea5a543112e5/665e641a877ad250fbbb5033.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/62a0ed33e96566ec73c18166/62a53f7b60e4050355365075.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183aea8079eb4cd4a59fdd/627962677e478616fae897c5.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65fb5182d58ee572fc8297e5/665bbb76e7d77a68b54515eb.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183d718079eb4cd4a5ebc4/62a5924b60e4050355379371.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/628d2408e1c3e7477147cee8/62a4ee8c60e405035534a56f.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b1837068079eb4cd4a527eb/6274033c49bbae143d79f34e.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b18343a8079eb4cd4a4d1ef/6282d8f2c3731d0a8ff3a793.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183b828079eb4cd4a5b1f0/62a226e660e405740cf62724.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183b828079eb4cd4a5b1f0/62a226e660e405740cf62727.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "He was reprimanded for making lewd comments in the workplace.",
        "The officer warned against lewd behavior among the soldiers during their downtime.",
        "The comedian’s lewd jokes offended some members of the audience."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "Lewd sounds like ‘lewd-y’—a rude or inappropriate display.",
        "Think of ‘ludicrous’ behavior—lewd is similarly inappropriate."
      ]
    },
    {
      "word": "loathe",
      "definition": [
        "to dislike strongly"
      ],
      "synonyms": [
        "despise",
        "abhor",
        "hate",
        "detest"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "She came to loathe the long commute to work.",
        "He loathed the idea of retreating during the military exercise.",
        "They loathe dishonesty and avoid those who are untruthful."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        "Loathe sounds like ‘loathe-some’—something detestable.",
        "Imagine loathe as ‘loathe-to-love’—meaning you hate it."
      ]
    },
    {
      "word": "luster",
      "definition": [
        "brightness created by reflected light"
      ],
      "synonyms": [
        "sheen",
        "gloss",
        "shine",
        "brilliance"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b1838038079eb4cd4a54666/62899701b071e717a659c40a.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/66754d57171fe56cd43996bd/6679b3576926467e70e5b14d.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The silverware had lost its luster over the years.",
        "The officer’s medals shone with a bright luster during the ceremony.",
        "Her enthusiasm added luster to the project and motivated others."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "Luster is like ‘lustrous’—a shiny or glowing appearance.",
        "Think of ‘luxury’—items with luster are shiny and beautiful."
      ]
    },
    {
      "word": "makeshift",
      "definition": [
        "doing for a while as a substitute"
      ],
      "synonyms": [
        "temporary",
        "provisional",
        "make-do",
        "substitute",
        "standby",
        "impromptu"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "They used a makeshift shelter made from tree branches during the storm.",
        "The soldiers quickly assembled a makeshift barricade for protection.",
        "She created a makeshift table out of boxes to hold the supplies."
      ],
      "part-of-speech": [
        "adjective",
        "noun"
      ],
      "mnemonics": [
        "Makeshift: think of ‘make-shift’ as something made quickly to ‘shift’ to.",
        "Imagine ‘making’ something ‘swiftly’ to use temporarily."
      ]
    },
    {
      "word": "mammoth",
      "definition": [
        "unusually large"
      ],
      "synonyms": [
        "colossal",
        "huge",
        "giant",
        "massive",
        "gigantic",
        "enormous"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The construction project was a mammoth undertaking.",
        "The army faced a mammoth challenge in navigating the rugged terrain.",
        "She tackled the mammoth pile of paperwork with determination."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "Mammoth sounds like ‘massive’—something very large.",
        "Think of a woolly mammoth—huge and intimidating in size."
      ]
    },
    {
      "word": "maroon",
      "definition": [
        "a dark red-purple color; or to leave behind, to isolate"
      ],
      "synonyms": [
        "crimson",
        "burgundy",
        "abandon",
        "desert",
        "strand"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "They were marooned on a deserted island after their boat drifted away.",
        "The soldiers were accidentally marooned in hostile territory after the mission.",
        "Due to the storm, they felt marooned at the airport with no way out."
      ],
      "part-of-speech": [
        "verb",
        "noun",
        "adjective"
      ],
      "mnemonics": [
        "Marooned sounds like ‘marooned-lone’—stranded or left alone.",
        "Think of maroon as ‘left to moor’ in a faraway place."
      ]
    },
    {
      "word": "materialize",
      "definition": [
        "to come into existence or to appear"
      ],
      "synonyms": [
        "arrive",
        "start",
        "actualize",
        "show"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "His plans for a new business started to materialize after months of hard work.",
        "The threat began to materialize as enemy forces moved closer.",
        "She hoped her dreams would materialize into reality."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        "Materialize: think of ‘material’—to make something real.",
        "‘Make material’—turn ideas into a tangible form."
      ]
    },
    {
      "word": "meticulous",
      "definition": [
        "taking great care and effort"
      ],
      "synonyms": [
        "careful",
        "diligent",
        "thorough",
        "particular"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "He was meticulous in his research, leaving no detail overlooked.",
        "The officer’s meticulous planning ensured a successful mission.",
        "She was meticulous about organizing her workspace."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "Meticulous: ‘meet’ every tiny detail with care.",
        "Think of ‘meticulous’ as ‘mini-details’—focusing on the small parts."
      ]
    },
    {
      "word": "modicum",
      "definition": [
        "the smallest amount or part imaginable"
      ],
      "synonyms": [
        "iota",
        "whit",
        "shred"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "He showed a modicum of patience despite the chaos around him.",
        "The officer’s actions displayed a modicum of restraint in a high-pressure situation.",
        "A modicum of respect can go a long way in resolving conflicts."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "Modicum sounds like ‘moderate amount’—a small measure.",
        "Think of ‘mini-come’—a little bit coming."
      ]
    },
    {
      "word": "mope",
      "definition": [
        "to go about silently in a bad mood"
      ],
      "synonyms": [
        "pout",
        "sulk",
        "brood"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "He began to mope around the house after hearing the disappointing news.",
        "The soldiers were reminded not to mope and to maintain high spirits.",
        "She refused to mope after the setback and kept pushing forward."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        "Mope sounds like ‘moan’—feeling down or low.",
        "Imagine ‘mope’ as ‘move slowly’ in sadness."
      ]
    },
    {
      "word": "moratorium",
      "definition": [
        "a temporary stopping of an activity"
      ],
      "synonyms": [
        "postponement",
        "stay",
        "freeze",
        "halt",
        "delay",
        "pause",
        "embargo",
        "deferment",
        "suspension",
        "hiatus"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The company placed a moratorium on new hires to control costs.",
        "The military imposed a moratorium on training exercises due to weather conditions.",
        "They called for a moratorium on the project until further notice."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "Moratorium: ‘more wait’ on a decision or action.",
        "Think of ‘more at rest’—a temporary pause."
      ]
    },
    {
      "word": "nebulous",
      "definition": [
        "not seen or understood clearly"
      ],
      "synonyms": [
        "ambiguous",
        "mysterious",
        "obscure",
        "vague",
        "dark",
        "cryptic",
        "undefined",
        "unclear"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "His plans for the future remained nebulous and undefined.",
        "The soldiers received nebulous instructions that left them uncertain.",
        "The politician’s answer was nebulous, lacking clarity."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "Nebulous sounds like ‘nebulizer’—a misty or unclear form.",
        "Think of ‘nebula’—cloudy or vague."
      ]
    },
    {
      "word": "negligent",
      "definition": [
        "failing to give enough care or attention"
      ],
      "synonyms": [
        "careless",
        "regardless",
        "slack",
        "thoughtless",
        "inattentive",
        "neglectful",
        "irresponsible",
        "forgetful",
        "sloppy"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The company was found negligent in handling safety protocols.",
        "The officer faced consequences for being negligent in his duties.",
        "He was negligent about following up on important tasks."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "Negligent: ‘neglect’ a responsibility or duty.",
        "Think of ‘negative agent’—someone who neglects or fails."
      ]
    },
    {
      "word": "neophyte",
      "definition": [
        "a person just starting out in a field of activity"
      ],
      "synonyms": [
        "novice",
        "beginner",
        "rookie",
        "tyro"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "He was a neophyte in the industry, eager to learn but lacking experience.",
        "The new recruit, a neophyte, needed guidance from seasoned soldiers.",
        "She’s a neophyte in cooking but learns quickly."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "Neophyte sounds like ‘new fight’—a beginner entering a field.",
        "Neo means new—neophyte is a newcomer."
      ]
    },
    {
      "word": "nepotism",
      "definition": [
        "unfair use of power to get jobs or benefits for family or friends"
      ],
      "synonyms": [
        "favoritism",
        "bias",
        "partiality"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The company faced accusations of nepotism in its hiring practices.",
        "The military discouraged nepotism to ensure fairness in promotions.",
        "Her family’s influence led to claims of nepotism in her career."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "Nepotism: ‘nepot’ (like nephew) showing favor to family.",
        "Think of ‘nepotist’—someone giving preference to family."
      ]
    },
    {
      "word": "nullify",
      "definition": [
        "to put an end to by formal action"
      ],
      "synonyms": [
        "repeal",
        "cancel",
        "invalidate"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The court decided to nullify the contract due to fraud.",
        "The commander ordered actions that would nullify the enemy’s advantage.",
        "Her apology did not nullify the impact of her earlier statements."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        "Nullify: ‘null’ means zero—nullify makes it as if it never happened.",
        "Think of ‘null’ in math—nullify makes something ineffective."
      ]
    },
    {
      "word": "obstinate",
      "definition": [
        "sticking to an opinion in spite of reason or persuasion"
      ],
      "synonyms": [
        "stubborn",
        "intransigent",
        "adamant",
        "uncompromising"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "He remained obstinate in his opinions, refusing to listen to advice.",
        "The obstinate terrain made it difficult for the troops to advance.",
        "She was obstinate about sticking to her original plan despite setbacks."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "Obstinate: ‘obstacle’ in changing one's mind.",
        "Think of ‘stubborn stone’—obstinate as unmovable."
      ]
    },
    {
      "word": "obtrude",
      "definition": [
        "to interest oneself in what is not one's concern"
      ],
      "synonyms": [
        "interfere",
        "snoop",
        "meddle"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "He did not want to obtrude on their private conversation.",
        "The soldier refrained from obtruding his personal views during the briefing.",
        "She didn’t want her opinions to obtrude on the peaceful discussion."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        "Obtrude: think of ‘intrude’—to push oneself where not wanted.",
        "Obtrude as ‘obstacle’—an unwelcome appearance."
      ]
    },
    {
      "word": "obvious",
      "definition": [
        "not open to misinterpretation"
      ],
      "synonyms": [
        "clear",
        "manifest",
        "plain",
        "apparent",
        "evident",
        "patent",
        "conspicuous",
        "transparent",
        "unmistakable"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The solution was so obvious that everyone wondered why it was overlooked.",
        "It became obvious to the team that a change in strategy was necessary.",
        "The officer’s displeasure was obvious to the recruits."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "Obvious: ‘ob-vi’ (like ‘view’)—clearly seen or evident.",
        "Think of ‘ob-vision’—clear to the eye."
      ]
    },
    {
      "word": "omen",
      "definition": [
        "a thing believed to foretell a future event"
      ],
      "synonyms": [
        "prophecy",
        "sign",
        "forewarning",
        "harbinger",
        "prediction",
        "hint",
        "precursor"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183d4b8079eb4cd4a5e75c/62a9cc427a297243a85938cf.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b96b00dcc77853d88561ab1/627585832827a11674b7c913.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The dark clouds were seen as an omen of the coming storm.",
        "The soldiers regarded the sudden silence as an omen on the battlefield.",
        "She hoped the rainbow was a good omen for her journey."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "Omen sounds like ‘omen-telling’—a sign of what is to come.",
        "Think of ‘o’ as a symbol—omen symbolizes a future event."
      ]
    },
    {
      "word": "omission",
      "definition": [
        "something left out"
      ],
      "synonyms": [
        "deletion",
        "blank",
        "oversight"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/62a0f1180502e54f654c4d78/62a6341fd12c8718fb1d8df2.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/62a3c96b1ee3a75421b40ffe/62b523e253ebb5953ea6cb81.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The omission of key information caused confusion during the meeting.",
        "The report’s omission of the enemy's strength left the unit unprepared.",
        "Her omission of certain facts led to misunderstandings."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "Omission: think of ‘omit’—leaving something out.",
        "‘Oh, missed it’—omission is when something’s left out."
      ]
    },
    {
      "word": "ouster",
      "definition": [
        "the act of removing someone from an important position or job"
      ],
      "synonyms": [
        "ejection",
        "banishment",
        "dismiss",
        "chase",
        "expulsion",
        "departure",
        "withdrawal",
        "cast out",
        "deportation"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The CEO faced an ouster due to declining company profits.",
        "The ouster of the corrupt official was welcomed by the soldiers.",
        "The team celebrated the ouster of ineffective management."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "Ouster: ‘out’ of power or position.",
        "Think of ‘oust’—ousting someone from a role."
      ]
    },
    {
      "word": "overture",
      "definition": [
        "a performance or event that precedes the main event"
      ],
      "synonyms": [
        "prelude",
        "preamble",
        "start"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The new ambassador made an overture to establish friendly relations.",
        "The general's overture for peace was met with skepticism by the enemy.",
        "The company’s overture to partner was a strategic move."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "Overture: like ‘overture’ in music—an introduction or opening.",
        "Think of ‘over’ (beginning) and ‘sure’ (move)—first steps."
      ]
    },
    {
      "word": "perceptive",
      "definition": [
        "able to notice and understand things that many do not notice"
      ],
      "synonyms": [
        "observant",
        "insightful",
        "knowledgeable",
        "precise",
        "intelligent",
        "discerning",
        "penetrating",
        "clever",
        "percipient"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65fb557bd58ee572fc82991e/666e4bf7ab721a61ab9e9599.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183c258079eb4cd4a5c4ae/62903620b071e7b444aa66d2.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "She was perceptive and noticed details others missed.",
        "The officer’s perceptive eye caught signs of enemy movement.",
        "His perceptive nature allowed him to understand others’ feelings."
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "Perceptive: ‘perceive’—quick to understand or notice.",
        "Think of ‘perfect vision’—able to see clearly."
      ]
    },
    {
      "word": "perforate",
      "definition": [
        "to make a hole or series of holes in"
      ],
      "synonyms": [
        "pierce",
        "drill",
        "puncture"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183c478079eb4cd4a5c8be/62779c61b05db515a22ab21e.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/62a3c96a1ee3a75421b40ffa/62af150f7a2972424faa491d.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The tool was designed to perforate paper quickly and cleanly.",
        "The armor was strong enough to resist being perforated by enemy fire.",
        "She used a pin to perforate the lid for better ventilation."
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        "Perforate: ‘per’ (through) + ‘forate’ (pierce)—pierce through.",
        "Think of ‘per-for-a-hole’—making small holes through something."
      ]
    },
    {
      "word": "perilous",
      "definition": [
        "full of danger; dangerous; fraught with danger; causing or involving great danger; risky; hazardous."
      ],
      "synonyms": [
        "dangerous",
        "unsafe",
        "hazardous",
        "risky",
        "serious",
        "threatening",
        "treacherous",
        "precarious",
        "vulnerable",
        "uncertain",
        "insecure",
        "critical",
        "desperate"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b1838758079eb4cd4a55413/628408f9c3731da7ee2fd633.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183bb18079eb4cd4a5b740/628478e5c3731da7ee3212eb.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "We live in perilous times. For three days, the ship tossed on perilous seas.",
        "",
        ""
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "Pair-ill-us - A pair of illnesses threatening us"
      ]
    },
    {
      "word": "perjure",
      "definition": [
        "to tell a lie in a court of law"
      ],
      "synonyms": [
        "misrepresent",
        "deceive",
        "lie"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b96ad78cc77853d88561676/628a17c5b071e717a65b48e3.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65f89a3bb267755af6cf6e38/665bc137e7d77a68b5453c1d.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "He was found guilty of perjury after lying under oath during the trial.",
        "",
        ""
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        "Per-jury - A person fooling the jury"
      ]
    },
    {
      "word": "petrify",
      "definition": [
        "to make unable to act or move from terror; to turn into stone."
      ],
      "synonyms": [
        "terrified",
        "terror-stricken",
        "horrified",
        "horror-struck",
        "paralyze"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b18399f8079eb4cd4a5780a/6282c5d1c3731db15b8e1ded.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b96add7cc77853d88561751/627b8af6bedfb51639864180.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "I was petrified with fear. Minerals left behind by water petrify wood by replacing woods cells when they die.",
        "",
        ""
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        "Pet-rify - Your pet becoming rigid with fear"
      ]
    },
    {
      "word": "phenomenon",
      "definition": [
        "a remarkable person, thing, or event"
      ],
      "synonyms": [
        "miracle",
        "wonder",
        "marvel",
        "sensation",
        "anomaly"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183ebb8079eb4cd4a61372/6274ace42827a11674b3541d.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The band was a pop phenomenon just for their sales figures alone.",
        "A hurricane is an example of a weather phenomenon. ",
        "Michael Jackson was a singing phenomenon when he was only five-years-old."
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "PHENomenon = 'A PHENomenal event to Note': Imagine a remarkable event that you definitely want to take note of."
      ]
    },
    {
      "word": "pliable",
      "definition": [
        "able to bend easily without breaking"
      ],
      "synonyms": [
        "flexible",
        "supple",
        "limber"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183ca68079eb4cd4a5d3cc/6273873f49bbae46093dd48c.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5e4aa76ce6a94839d1f5b9f9/629ae5b2303d6c554363b0f0.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183e998079eb4cd4a60f4c/6278305fb05db515a22dd6ca.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The dough was pliable and easy to shape into various forms.",
        "",
        ""
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "SUPple = 'A SUPer flexible body': Think of a gymnast or dancer who is very flexible and can move easily.",
        "Ply-able - Able to be plied like wood /Picture a rubber band stretching and bending"
      ]
    },
    {
      "word": "plummet",
      "definition": [
        "an abrupt drop; or to fall abruptly"
      ],
      "synonyms": [
        "fall",
        "plunge",
        "nosedive",
        "descend",
        "rapidly",
        "diminish",
        "crash",
        "fall headlong"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183b428079eb4cd4a5aa4c/627709982827a11674bfa3cd.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "Stock prices plummeted after the news of the oil spill. (verb) ",
        "Stock prices plummeted after the news of the oil spill. (verb)",
        "A small weight on a line used for sounding is called a plummet. (noun)"
      ],
      "part-of-speech": [
        "verb",
        "noun"
      ],
      "mnemonics": [
        "Plum-met - A plum meeting the ground rapidly"
      ]
    },
    {
      "word": "polarize",
      "definition": [
        "divide into two completely opposing groups",
        "to induce polarity in; cause to concentrate around opposite points",
        "to cause similar vibrations of (light waves or the like) to move in one plane or direction."
      ],
      "synonyms": [
        "split",
        "partition",
        "sever",
        "consolidate",
        "unite",
        "concentrate",
        "integrate",
        "merge"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "An unpopular war can polarize a nation's people.",
        "",
        ""
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        "Polar-eyes - Eyes looking at opposite poles"
      ]
    },
    {
      "word": "ponderous",
      "definition": [
        "causing restlessness or lack of interest; unwieldy or clumsy because of weight or size"
      ],
      "synonyms": [
        "boring",
        "tiring",
        "dull",
        "monotonous",
        "heavy",
        "weighty",
        "massive"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b1839238079eb4cd4a56912/62a226e260e405740cf58b4b.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The professor's lectures were often ponderous, filled with dense academic theories.",
        "",
        ""
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "PONderous = 'Like a heavy PONcho': Picture a thick, heavy poncho making it hard to move quickly."
      ]
    },
    {
      "word": "predisposed",
      "definition": [
        "to be more likely to behave in a particular way"
      ],
      "synonyms": [
        "prone",
        "inclined",
        "willing"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/628d23ece1c3e7477147cc9b/62a8d3c9d12c875e2fc7a0de.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183dce8079eb4cd4a5f713/62847effc3731da7ee323f8e.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "Due to his upbringing, he was predisposed to trust authority figures without question.",
        "",
        ""
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "Pre-dis-posed - Previously disposed to act"
      ]
    },
    {
      "word": "prestige",
      "definition": [
        "the power to impress because of success, wealth, etc."
      ],
      "synonyms": [
        "status",
        "celebrity",
        "notoriety",
        "position",
        "stature",
        "significance",
        "standing",
        "stature",
        "prestigiousness",
        "renown",
        "fame"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b96b035cc77853d88561aed/628d7507b071e78202f4cebf.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b96ab04cc77853d88561150/62761b832827a11674bac2cb.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "Sometimes people use the term 'status' to mean high prestige.",
        "Albert Einstein's work brought him fame and prestige.",
        ""
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        "Press-tige - Press coverage of a tiger's superiority"
      ]
    },
    {
      "word": "prioritize",
      "definition": [
        "to put in order based on importance"
      ],
      "synonyms": [
        "categorize",
        "organize",
        "classify"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "It's important to prioritize your tasks to ensure you focus on the most important ones first.",
        "",
        ""
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "prosaic",
      "definition": [
        "encountered in the normal course of events"
      ],
      "synonyms": [
        "oridinary",
        "normal",
        "typical",
        "commonplace"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183d088079eb4cd4a5df57/629b4526303d6cfbdc2e84a4.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/62a0f1250502e54f654c4d87/62d2fc0e9bd932079aa881f4.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183afa8079eb4cd4a5a1c7/629164f3b071e7e0646110ff.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "His writing style was criticized for being too prosaic, lacking creativity and originality. ",
        "",
        ""
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "Professional at being basic"
      ]
    },
    {
      "word": "proverbial",
      "definition": [
        "commonly known, expressing an acknowledged truth",
        "widely known and often referred to, as if in a common proverb"
      ],
      "synonyms": [
        "conventional",
        "notorious",
        "archetypal",
        "accepted",
        "traditional",
        "famous",
        "acknowledged"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b1837068079eb4cd4a527eb/6274033c49bbae143d79f5ab.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "He was the proverbial pillar of strength.",
        "",
        ""
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "PROs VERBally IAL ways: PRO: well-known or famous (like a professional) VERB: reminds you of verbal, suggesting something spoken or common in speech (like proverbs)"
      ]
    },
    {
      "word": "purblind",
      "definition": [
        "lacking in vision, insight, or understanding"
      ],
      "synonyms": [
        "obtuse",
        "blind",
        "visually impaired"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/6616b65f2395f6443818ba75/66738c4fab721a61aba29ff8.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "His purblind loyalty to the company prevented him from seeing its flaws.",
        "",
        ""
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "Pure-blind - Purely but not completely blind",
        "OBtuse = 'Someone with an OBlivious mind': Imagine someone so oblivious or dull-witted that they miss obvious things."
      ]
    },
    {
      "word": "quarry",
      "definition": [
        "something that is hunted or killed"
      ],
      "synonyms": [
        "prey",
        "game",
        "target",
        "mine",
        "pit",
        "excavation",
        "digging site",
        "extractive",
        "industry"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "a large open hole or pit dug for mining stone, marble, gravel, or the like.",
        "",
        ""
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "quiescence",
      "definition": [
        "a state of temporary inactivity"
      ],
      "synonyms": [
        "suspension",
        "dormancy",
        "inertia"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The quiescence of the village was interrupted only by the occasional chirping of birds.",
        "",
        ""
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "rambunctious",
      "definition": [
        "rough or noisy in a high-spirited way"
      ],
      "synonyms": [
        "boisterous",
        "rowdy",
        "raucous",
        "noisy"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The children were particularly rambunctious after eating too much candy at the party.",
        "",
        ""
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "rampart",
      "definition": [
        "a fortification built around a city or castle for protection"
      ],
      "synonyms": [
        "wall",
        "barricade",
        "buffer"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The castle was surrounded by a tall rampart, offering protection from invaders.",
        "",
        ""
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "rebuke",
      "definition": [
        "a formal expression of disapproval; or to criticize to correct a fault"
      ],
      "synonyms": [
        "reprimand",
        "reproach",
        "lecture",
        "admonish",
        "scold",
        "blame"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "She received a stern rebuke from her boss for repeatedly missing deadlines.",
        "",
        ""
      ],
      "part-of-speech": [
        "noun",
        "verb"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "reprimand",
      "definition": [
        "a public expression of disapproval; or to criticize severely",
        "a strong, severe or usually formal statement of disapproval; often public or official rebuke."
      ],
      "synonyms": [
        "admonition",
        "censure",
        "rebuke",
        "admonishment",
        "blame",
        "chiding",
        "scolding",
        "chastise",
        "admonish",
        "lambaste",
        "reprove",
        "castigate",
        "berate"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "Teachers are advised to reprimand a child who misbehaves. (verb)",
        "The police officer received a severe reprimand from his superior, but he wasn't suspended. (noun) ",
        ""
      ],
      "part-of-speech": [
        "noun",
        "verb"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "restitution",
      "definition": [
        "payment for loss or injury"
      ],
      "synonyms": [
        "reparation",
        "compensation",
        "damages"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The court ordered the thief to pay full restitution to the victims of his crime.",
        "",
        ""
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "retract",
      "definition": [
        "to formally reject or go back on"
      ],
      "synonyms": [
        "withdraw",
        "denounce",
        "deny"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "Upon realizing his error, the politician quickly moved to retract his controversial statement.",
        "",
        ""
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "sanction",
      "definition": [
        "approval by an authority for doing something; or to give official permission"
      ],
      "synonyms": [
        "permission",
        "authorization",
        "consent",
        "approve",
        "endorse",
        "ratify"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b96aeb7cc77853d885618e3/627e0cee8d33c5b0c15f97ec.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b96ad81cc77853d8856168e/62841f43c3731da7ee304245.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183ebe8079eb4cd4a613cb/6288ca5ec3731da7ee459889.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5e4a8ed9e6a948336859880c/629bfa7a14bb6d3b4fc21af9.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183cf58079eb4cd4a5dd30/62a5b92260e4057cb85a9338.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The international community imposed economic sanctions on the oppressive regime.",
        "",
        ""
      ],
      "part-of-speech": [
        "noun",
        "verb"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "savor",
      "definition": [
        "(noun): to enjoy the taste or smell of (something) for as long as possible; a good taste or smell; a distinctive or singular quality; characteristic identified by taste; or to take pleasure in",
        "(verb): to have a particular taste or smell; have savor (usually followed by 'of'); to give an impression; hint (usually followed by 'of'). "
      ],
      "synonyms": [
        "taste",
        "flavor",
        "palatability",
        "relish",
        "delight in",
        "appreciate",
        "suggest",
        "smack of",
        "seem like",
        "give the impression of",
        "have the hallmarks of"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183b878079eb4cd4a5b28d/6287ad0ac3731da7ee415e71.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183aea8079eb4cd4a59fdd/627962677e478616fae897a1.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b1838218079eb4cd4a549f4/62a226e060e405740cf55a50.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183b168079eb4cd4a5a512/628d424bb071e7adb823c8ca.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b96adefcc77853d88561785/6271ad50851d787699aa54f2.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "",
        "",
        ""
      ],
      "part-of-speech": [
        "noun",
        "verb"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "schism",
      "definition": [
        "a division between opposing sections caused by differences in opinion"
      ],
      "synonyms": [
        "discord",
        "strife",
        "friction",
        "conflict",
        "estrangement",
        "spilt",
        "detachment",
        "rift",
        "breach",
        "division",
        "alienation"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183e3c8079eb4cd4a60438/62a83cf3d12c875e2fc715fd.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/628e9742b071e79dd9eabff2/62ad4d207a2972af4d5e5b47.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65fb50cbd58ee572fc8297b5/665b8ad6e7d77a68b5441a5c.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The eleventh century schism divided the Christian Church into what became the Eastern Orthodox Church and the Roman Catholic Church.",
        "",
        ""
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "scornful",
      "definition": [
        "filled with contempt"
      ],
      "synonyms": [
        "insulting",
        "mocking",
        "rude",
        "defiant",
        "disdainful",
        "contemptuous"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/62a0ed24e96566ec73c18158/62b1edaafe38d53b52415d49.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65f899beb267755af6cf6dfb/665be82ee7d77a68b546384a.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183dd08079eb4cd4a5f75d/62a688d3d12c8718fb1e9acc.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65f8a0bfb267755af6cf7085/6672413eab721a61aba19b50.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183cc48079eb4cd4a5d779/62a9c6a27a297243a85824df.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "He is deeply scornful of soldiers.",
        "",
        ""
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "screech",
      "definition": [
        "a loud, harsh, piercing cry; or to cry out loudly and emotionally"
      ],
      "synonyms": [
        "shriek",
        "yell",
        "howl",
        "scream",
        "yelp",
        "squawk"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/62a0ed48e96566ec73c18199/62b19493fe38d518259bce13.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183b4e8079eb4cd4a5abb1/62838c01c3731da7ee2d266d.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b18386b8079eb4cd4a552f4/628957f7b071e717a658dcb2.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/669d02fda5288936b30aaba7/669d17fbc3b09f59ba6c029d.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The soldier’s car tires made a loud screeching noise when he mashed the brakes abruptly. (verb) ",
        "We heard screeches coming from the monkey house at the zoo. (noun)",
        ""
      ],
      "part-of-speech": [
        "verb",
        "noun"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "sequester",
      "definition": [
        "to keep apart from others or to confiscate"
      ],
      "synonyms": [
        "isolate",
        "separate",
        "segregate",
        "seize",
        "take over"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "To preserve evidence, the investigators decided to sequester the area until further notice.",
        "",
        ""
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "sever",
      "definition": [
        "to break or separate, especially by cutting; to end a connection with someone or something."
      ],
      "synonyms": [
        "detach",
        "disconnect",
        "separate",
        "split",
        "cut",
        "disjoin",
        "dissect",
        "dissever",
        "dissociate",
        "disunite",
        "divide",
        "cleave",
        "bisect"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183dcc8079eb4cd4a5f6e8/6276bba82827a11674be17b4.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183c4c8079eb4cd4a5c959/628473f1c3731da7ee320345.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183e798079eb4cd4a60b8b/6284a08ec3731da7ee32eaa1.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b96afc5cc77853d88561a3e/6289575cb071e717a658cf10.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b96ab36cc77853d885611b5/628b7139b071e717a65f5a55.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The soldier managed to cut into the hand from the accident, but not far enough to sever the limb. The U.S. severed diplomatic relations with Cuba.",
        "",
        ""
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "sham",
      "definition": [
        "(noun): an imitation that is passed as genuine; counterfeit.",
        "(verb): to create the false appearance of; fake.",
        "(adjective): false, not genuine or simulated."
      ],
      "synonyms": [
        "bogus",
        "fraudulent",
        "phony",
        "counterfeit",
        "fake",
        "feigned",
        "make believe",
        "not genuine",
        "fraud",
        "false",
        "fictitious",
        "dissemble",
        "feigned",
        "pretend",
        "simulate",
        "act",
        "misleading",
        "forgery",
        "hoax",
        "phony",
        "impostor",
        "pretender",
        "masquerade"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "",
        "",
        ""
      ],
      "part-of-speech": [
        "verb",
        "noun",
        "adjective"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "shun",
      "definition": [
        "persistently avoid, ignore, or reject (someone or something) through antipathy or caution; to keep away from or avoid on purpose."
      ],
      "synonyms": [
        "avoid",
        "evade",
        "eschew",
        "steer clear of",
        "shy away from",
        "fight shy of",
        "ostracize"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "After his divorce he found himself being shunned by many of his former friends. She shuns foods that are not healthy.",
        "",
        ""
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "skepticism",
      "definition": [
        "doubt towards a subject or event; distrust or disbelief, or a general tendency to doubt and question."
      ],
      "synonyms": [
        "apprehension",
        "disbelief",
        "distrust",
        "hesitation",
        "reluctance",
        "suspicion",
        "uncertainty",
        "doubtfulness",
        "lack of conviction"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "After years of failing many classes in high school, there was a lot of skepticism that Joseph could graduate from high school. ",
        " The nutritionist's claim of finding a cure for the disease was met with intense skepticism in the medical community.",
        ""
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "snarl",
      "definition": [
        " (verb): (of an animal such as a dog) make an aggressive growl with bared teeth in a threatening an vicious way; to speak sharply with anger or menace; a confused or tangled situation. ",
        "(noun): an angry or vicious growl.",
        "(general): a crowded or tangled mass; or to speak sharply or irritably "
      ],
      "synonyms": [
        "disarray",
        "disorder",
        "knot",
        "growl",
        "bark",
        "snap",
        "shout"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/62a0f12d0502e54f654c4d90/62b74a1953ebb58ca7d3d886.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/62a3c9741ee3a75421b4100d/62ba81388c808c18d1aef414.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/62a0f1230502e54f654c4d85/62aff4947a2972424fae7c4a.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65f8a405b267755af6cf71c2/666dbd94ab721a61ab9d53bd.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183d968079eb4cd4a5f03b/62a142cdfdbae9fe91679823.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The dog greeted me with a snarl. (verb) ",
        "She snarled at me when I asked the same question twice. (verb)",
        "The traffic was in a snarl because of road work. (noun)",
        "It will take an hour to remove the snarl in the fishing line. (noun)"
      ],
      "part-of-speech": [
        "verb",
        "noun"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "soluble",
      "definition": [
        "capable of being dissolved in a liquid; or capable of being solved"
      ],
      "synonyms": [
        "resolvable",
        "answerable",
        "feasible",
        "solvable",
        "dissolved",
        "dispersible",
        "solvent",
        "emulsifiable"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65f8a37bb267755af6cf719e/665a7b4fe7d77a68b53de894.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b96acd2cc77853d88561531/627683e02827a11674bcd7cb.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "Sugar is more quickly soluble in warm water than in cold.",
        "",
        ""
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "Soluble can be considered a synonym of 'answerable' because both words essentially mean 'capable of being solved' or 'capable of finding a solution"
      ]
    },
    {
      "word": "spawn",
      "definition": [
        "(Verb): to produce a large number of eggs.",
        "(noun): the large number of eggs produced by fish, frogs, and other water animals."
      ],
      "synonyms": [
        "offspring",
        "fruit",
        "progeny",
        "create",
        "cause",
        "generate",
        "breed"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b96accccc77853d88561524/6286870ac3731da7ee3cc9ff.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b96accccc77853d88561524/6286870ac3731da7ee3ccb3d.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65f89e81b267755af6cf6fb5/665b0f3be7d77a68b5413790.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/660b1f58ed50ea5a543111d6/666a149cab721a61ab9941bc.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "",
        "",
        ""
      ],
      "part-of-speech": [
        "verb",
        "noun"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "stalwart",
      "definition": [
        "Loyal, reliable, and hardworking; marked by firmness and determination, often with physical or mental strength. ",
        "Marked by outstanding strength and vigor of body, mind, or spirit."
      ],
      "synonyms": [
        "steadfast",
        "loyal",
        "resolute",
        "dependable",
        "strong",
        "unyielding",
        "hardy",
        "determined",
        "tenacious",
        "tough",
        "brave",
        "bold",
        "dauntless"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183de98079eb4cd4a5fa5f/62a226eb60e405740cf6e3e1.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65fb50e9d58ee572fc8297c1/665b92a7e7d77a68b5444474.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "The stalwart firefighter bravely entered the burning building to rescue the trapped family. ",
        "He remained a stalwart supporter of the king, even in difficult times.",
        ""
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "staunch",
      "definition": [
        "firm in one's allegiance"
      ],
      "synonyms": [
        "loyal",
        "steadfast",
        "true"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b18343f8079eb4cd4a4d2c0/62a06425e1c27e1918ead337.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/628e9731b071e79dd9eabfa6/629fd4c614bb6d739ebf91d5.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183d878079eb4cd4a5ee79/627663c42827a11674bc248b.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "As a staunch supporter of environmental conservation, she dedicated her life to protecting endangered species.",
        "",
        ""
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "suffice",
      "definition": [
        "to be enough, to satisfy a need"
      ],
      "synonyms": [
        "get by",
        "satisfy",
        "be enough",
        "be sufficient",
        "be adequate",
        "serve",
        "suit",
        "assuage",
        "fulfill"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183eb08079eb4cd4a6120c/6290324cb071e7b444aa4cdd.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/660b1f87ed50ea5a543111ec/666a4206ab721a61ab996c62.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65f8a6c1b267755af6cf72b8/665ae1d2e7d77a68b5403634.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65f8a398b267755af6cf71ac/665a8c7fe7d77a68b53e435c.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "",
        "",
        ""
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "suppress",
      "definition": [
        "to stop the activities or progress of, especially by force",
        "to keep back, as an emotion or urge; to keep from being published or disclosed",
        "to put down by authority or force"
      ],
      "synonyms": [
        "abolish",
        "conceal",
        "muzzle",
        "quench",
        "quash",
        "repress",
        "silence",
        "stifle",
        "cover up",
        "subdue",
        "vanquish",
        "triumph",
        "over power",
        "extinguish",
        "defeat"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183cc98079eb4cd4a5d7e6/628da036b071e78202f59e88.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b1837eb8079eb4cd4a54392/6275210c2827a11674b5ab29.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183b7e8079eb4cd4a5b169/62790badb45eb616341ab25b.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b1838758079eb4cd4a55413/628408f9c3731da7ee2fd721.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183e868079eb4cd4a60d3f/62a226ed60e405740cf72bd4.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "",
        "",
        ""
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "synonymous",
      "definition": [
        "resembling another in meaning"
      ],
      "synonyms": [
        "comparable",
        "similar",
        "identical"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "",
        "",
        ""
      ],
      "part-of-speech": [
        "noun"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "synthesize",
      "definition": [
        "to make by combining different things"
      ],
      "synonyms": [
        "integrate",
        "mix",
        "fuse"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "",
        "",
        ""
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "tactical",
      "definition": [
        "relating to or constituting actions carefully planned to gain a specific military end",
        "suitable for bringing about a desired result under the circumstances"
      ],
      "synonyms": [
        "diplomatic",
        "cunning",
        "prudent",
        "désirable",
        "wise",
        "possible",
        "politic",
        "practical",
        "judicious",
        "expédient",
        "strategic",
        "calculated",
        "planned",
        "deliberate",
        "methodical",
        "shrewd"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183b878079eb4cd4a5b28d/6287ad0ac3731da7ee415ecf.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b1839df8079eb4cd4a57fbc/62a15195fdbae9fe9167c4c8.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b96af88cc77853d885619e8/62845500c3731da7ee31593d.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "",
        "",
        ""
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "tantalizing",
      "definition": [
        "having a strong power to hold interest",
        "arousing desire or interest, but usually unavailable or beyond reach."
      ],
      "synonyms": [
        "tempting",
        "enticing",
        "appealing",
        "interesting",
        "intriguing",
        "charming",
        "attractive",
        "exciting"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183dd98079eb4cd4a5f868/6274750c2827a11674b1fbb0.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b96ab9dcc77853d8856127b/6274017649bbae143d79e937.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "",
        "",
        ""
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "tawdry",
      "definition": [
        "marked by low quality and unbecoming appearance"
      ],
      "synonyms": [
        "gaudy",
        "flashy",
        "trashy",
        "junky",
        "showy",
        "cheap",
        "vulgar",
        "cheapjack",
        "tinsel"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183dc78079eb4cd4a5f64c/62b5abe053ebb5c6e3aaf1f4.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183dc78079eb4cd4a5f64c/62b5abe053ebb5c6e3aaf1f4.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183d278079eb4cd4a5e2f7/62ad65ab7a2972af4d5edbfb.mp4",
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "",
        "",
        ""
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "tawny",
      "definition": [
        "of a yellowish-brown color"
      ],
      "synonyms": [
        "blond",
        "fair",
        "flaxen",
        "golden",
        "sandy",
        "straw",
        "tan"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183be48079eb4cd4a5bd1e/6281484304f5597672ccf789.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b18398a8079eb4cd4a5757c/62b1b683fe38d518259c74af.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65fb4fc9d58ee572fc829783/66731cc2ab721a61aba24420.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "",
        "",
        ""
      ],
      "part-of-speech": [
        "adjective",
        "noun"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "terse",
      "definition": [
        "very short and to the point"
      ],
      "synonyms": [
        "concise",
        "brief",
        "condensed",
        "laconic",
        "short",
        "succinct",
        "abrupt"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65fb52ecd58ee572fc829871/665a05ece7d77a68b53b11fe.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b1839b88079eb4cd4a57b07/62a6c6f2d12c8718fb1f802d.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65fb5e26d58ee572fc829adb/6659f3f5e7d77a68b53aa5a6.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "",
        "",
        ""
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "tumescent",
      "definition": [
        "enlarged beyond normal from internal pressure"
      ],
      "synonyms": [
        "swollen",
        "turgid",
        "bulging"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183c078079eb4cd4a5c11d/6297ca6e6b16e8bc49cc34d4.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183bd18079eb4cd4a5baf2/627d89208d33c5b0c15cbc3e.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "",
        "",
        ""
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "universal",
      "definition": [
        "belonging or relating to the whole, covering everything"
      ],
      "synonyms": [
        "widespread",
        "general",
        "common",
        "whole",
        "entire",
        "total",
        "omnipresent",
        "overarching",
        "general",
        "comprehensive",
        "ubiquitous"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b18348a8079eb4cd4a4dbb8/62a226dc60e405740cf4d942.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183b068079eb4cd4a5a323/6278308bb05db515a22de355.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65f8a330b267755af6cf7178/665a7120e7d77a68b53dad5d.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/660b1d67ed50ea5a54311127/6660f9d3877ad250fbbd79c4.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "",
        "",
        ""
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "unpretentious",
      "definition": [
        "not excessively showy"
      ],
      "synonyms": [
        "understated",
        "simple",
        "genuine"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65fb55afd58ee572fc829935/666e78e8ab721a61ab9efdbd.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65f897d4b267755af6cf6d3d/666d38c6ab721a61ab9c40fd.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65fc5b724ba55c70ba23fc59/66716bfeab721a61aba0f350.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "",
        "",
        ""
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "unrelenting",
      "definition": [
        "sticking to an opinion in spite of reason, showing no signs of yielding"
      ],
      "synonyms": [
        "persistent",
        "determined",
        "adamant",
        "stubborn"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183b358079eb4cd4a5a8d1/6286a3dbc3731da7ee3d68e5.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b96ac97cc77853d885614ae/6287fe5dc3731da7ee429439.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "",
        "",
        ""
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "vacuous",
      "definition": [
        "lacking content that should be present, not having an ability to grasp ideas"
      ],
      "synonyms": [
        "empty",
        "blank",
        "stupid",
        "dense"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183d388079eb4cd4a5e4e8/62a3d19760e405fcc7d76b70.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b18348a8079eb4cd4a4dbb8/62a226dc60e405740cf4d4d1.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b1836518079eb4cd4a512c0/627ed0de8d33c5b0c163c20b.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "",
        "",
        ""
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "valorous",
      "definition": [
        "feeling or displaying no fear"
      ],
      "synonyms": [
        "Brave",
        "Courageous",
        "Heroic",
        "Gallant",
        "Fearless",
        "Daring",
        "Intrepid",
        "Dauntless",
        "Valiant"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183e718079eb4cd4a60a90/62884e33c3731da7ee43c04e.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183e658079eb4cd4a60914/627e3a8f8d33c5b0c1608d83.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183bb18079eb4cd4a5b740/628478e5c3731da7ee3210ef.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183eb88079eb4cd4a6131d/629c90c914bb6d77fa750ab4.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65f89a48b267755af6cf6e3e/665bf0e4e7d77a68b5466e93.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "",
        "",
        ""
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "verdant",
      "definition": [
        "covered with a thick, healthy, natural growth"
      ],
      "synonyms": [
        "lush",
        "green",
        "fertile"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65fb4f22d58ee572fc82975d/666ed833ab721a61ab9f6ba1.mp4",
        "https://y.yarn.co/bdc62eaf-7b6f-4626-a0f3-2637b869e0d3.mp4",
        "https://y.yarn.co/f1c33338-f335-4e71-bc2e-a28acd7a86ef.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "",
        "",
        ""
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "vex",
      "definition": [
        "to disturb someone's peace of mind"
      ],
      "synonyms": [
        "annoy",
        "irritate",
        "exasperate",
        "bother",
        "worry"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183c5e8079eb4cd4a5cb75/629fdfc214bb6d739ebfcda2.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "",
        "",
        ""
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "virulent",
      "definition": [
        "highly dangerous and deadly"
      ],
      "synonyms": [
        "poisonous",
        "toxic",
        "venomous",
        "lethal",
        "fatal",
        "terminal"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183ec78079eb4cd4a614cc/6284918ec3731da7ee32a0f2.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "",
        "",
        ""
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "vitalize",
      "definition": [
        "to give life, vigor, or spirit to"
      ],
      "synonyms": [
        "enliven",
        "animate",
        "inspire"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65f8a3e1b267755af6cf71b2/666dafcdab721a61ab9d3723.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b1835538079eb4cd4a4f3f4/6273190549bbae46093b85e6.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65f8a441b267755af6cf71df/666de0a3ab721a61ab9da013.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/62b1d38e160f097e01a3a5c4/62b429e0fe38d5702d5818d9.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "",
        "",
        ""
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "weep",
      "definition": [
        "to shed tears"
      ],
      "synonyms": [
        "cry",
        "sob",
        "grieve"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183d348079eb4cd4a5e466/627e52938d33c5b0c1611967.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183ed08079eb4cd4a615f8/627240b449bbaed9256491a8.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183d348079eb4cd4a5e466/627e52938d33c5b0c1611967.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "",
        "",
        ""
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "willowy",
      "definition": [
        "able to bend easily without breaking"
      ],
      "synonyms": [
        "pliable",
        "elastic",
        "malleable"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b1838538079eb4cd4a54ffc/62b5110553ebb5953ea6625b.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b96acdecc77853d8856154d/628c94dfb071e7bee0024969.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "",
        "",
        ""
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "winsome",
      "definition": [
        "having a good mood or disposition, likely to win one's affection"
      ],
      "synonyms": [
        "cheerful",
        "bright",
        "charming",
        "adorable"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183e078079eb4cd4a5fde9/6277eb91b05db515a22c615c.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "",
        "",
        ""
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "writhe",
      "definition": [
        "to make continual twisting, squirming movements"
      ],
      "synonyms": [
        "squirm",
        "struggle",
        "toss",
        "twist",
        "wriggle",
        "jerk",
        "wiggle",
        "twitch"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65f8a41cb267755af6cf71cd/666d8d62ab721a61ab9cf01a.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183d198079eb4cd4a5e15e/62863a7dc3731da7ee3b435d.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183d7a8079eb4cd4a5ecd5/62a85677d12c875e2fc77aa8.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "",
        "",
        ""
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "bandied",
      "definition": [
        "'Bandied' means to casually pass around or exchange ideas, words, or rumors, often in a light or informal way."
      ],
      "synonyms": [
        "bantered",
        "exchanged",
        "played with",
        "answered",
        "retorted",
        "tossed around",
        "discussed",
        "debated"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183ca08079eb4cd4a5d327/62831ceac3731d8d8b1c1faf.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "",
        "",
        ""
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        "pass on or discuss (an idea or rumor) in a casual or uninformed way.",
        "Band-died - Band playing back and forth"
      ]
    },
    {
      "word": "divisive",
      "definition": [
        "creating disunity or dissension, causing unfriendliness"
      ],
      "synonyms": [
        "Disruptive",
        "unsettling",
        "alienating",
        "controversial",
        "polarizing",
        "contentious"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "",
        "",
        ""
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "facetious",
      "definition": [
        "joking or jesting, mean to be humorous; not serious"
      ],
      "synonyms": [
        "humorous",
        "funny",
        "amusing",
        "witty",
        "comical",
        "quipping"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b96ac1fcc77853d885613ad/627d867c8d33c5b0c15caa34.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b1839798079eb4cd4a57380/628bd058b071e717a660710a.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183c7d8079eb4cd4a5cf21/62a7ceced12c875e2fc5957e.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b18369f8079eb4cd4a51b5a/62a8d3a0d12c875e2fc79601.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/628cdaa9e1c3e7c82c20a99f/6295337db071e724e9e5ac14.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "",
        "",
        ""
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "paltry",
      "definition": [
        "small or meager; of little value"
      ],
      "synonyms": [
        "petty",
        "trival",
        "unimportant",
        "piddling"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183b028079eb4cd4a5a2af/6273ae0749bbae46093eb36e.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183d198079eb4cd4a5e15e/62863a7dc3731da7ee3b46cb.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183d198079eb4cd4a5e15e/62863a7dc3731da7ee3b46cb.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "",
        "",
        ""
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        "PALtry = A PAL would give more than a try: Imagine a friend (PAL) giving only a small effort or something of little worth.",
        "PIDdling = PITifully Dinky: Think of something so tiny it’s almost pitiful.",
        "TRIvial = 'Only worth TRYing once': Imagine something so minor that it’s hardly worth trying more than once."
      ]
    },
    {
      "word": "pulsate",
      "definition": [
        "to throb or move rhythmically; expand and contract"
      ],
      "synonyms": [
        "vibrate",
        "beat",
        "pound",
        "reverberate"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "",
        "",
        ""
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "rash",
      "definition": [
        "marked by undue haste or lack of caution; or, an itchy eruptiion on the body; or, a large number of instances in a short period"
      ],
      "synonyms": [
        "abrupt",
        "hasty",
        "brash",
        "quick",
        "reckless"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "",
        "",
        ""
      ],
      "part-of-speech": [
        "adjective",
        "noun"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "ritualistic",
      "definition": [
        "invariably perfomed in the same way, characteristic of rituals"
      ],
      "synonyms": [
        "ceremonial",
        "reverent",
        "conventional",
        "formalistic",
        "customary",
        "traditional",
        "prescribed",
        "systematic"
      ],
      "media": [
        ""
      ],
      "image": [
        ""
      ],
      "sentences": [
        "",
        "",
        ""
      ],
      "part-of-speech": [
        "adjective"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "stress",
      "definition": [
        "constraining force or influence; or, emphasis; or, to subject to stress or strail."
      ],
      "synonyms": [
        "pressure",
        "anxiety",
        "tension",
        "emphasis",
        "importance"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/65fb5346d58ee572fc829897/665a16c2e7d77a68b53b9217.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/667548ae171fe56cd439950b/669cfdc3c3b09f59ba6bdff2.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b183d1b8079eb4cd4a5e18b/62867569c3731da7ee3c6b27.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b1838138079eb4cd4a54837/629d2a3d14bb6d88ae1a7ef9.mp4",
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/5b18343a8079eb4cd4a4d1ef/6282d8f2c3731d0a8ff3a660.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "",
        "",
        ""
      ],
      "part-of-speech": [
        "noun",
        "verb"
      ],
      "mnemonics": [
        ""
      ]
    },
    {
      "word": "vacillate",
      "definition": [
        "to waver in mind, will, or feeling, to hesitate in making a decision"
      ],
      "synonyms": [
        "waver",
        "fluctuate",
        "hesitate",
        "be undecided",
        "oscillate"
      ],
      "media": [
        "https://s3.us-west-1.wasabisys.com/video-us.playphrase.me/english-storage/669d1ac128eb8749ac180e44/669d42dfc3b09f59ba6c2586.mp4"
      ],
      "image": [
        ""
      ],
      "sentences": [
        "",
        "",
        ""
      ],
      "part-of-speech": [
        "verb"
      ],
      "mnemonics": [
        ""
      ]
    }
  ]