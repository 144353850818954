import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Data } from '../data';
import '../styles/VocabularyPage.css';
import WordDefinitionPopup from './WordDefinitionPopup';
import { FaFlag } from 'react-icons/fa';

function VocabularyPage({ userStats, setUserStats, customWords }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredWords, setFilteredWords] = useState([]);
  const [totalWords, setTotalWords] = useState(0);
  const [selectedLetters, setSelectedLetters] = useState(['all']);
  const [filterOptions, setFilterOptions] = useState({
    mastery: 'all', // all, mastered, in-progress, not-started
    sortBy: 'mastery', // mastery, alphabetical, flagged
    difficulty: 'all', // all, easy, medium, hard
  });
  const [showLetterFilter, setShowLetterFilter] = useState(false);

  // Add this constant
  const alphabetCategories = ['all', ...Array.from('abcdefghijklmnopqrstuvwxyz')];

  // Add new state for media popup
  const [mediaPopup, setMediaPopup] = useState({
    isOpen: false,
    type: null, // 'image' or 'video'
    content: [],
    currentIndex: 0
  });

  const [expandedCards, setExpandedCards] = useState({});

  // Add this near your other state declarations
  const [playingAudio, setPlayingAudio] = useState(null);

  // Add this near your other state declarations
  const [showMasteryControls, setShowMasteryControls] = useState(false);

  // Get mastered synonyms from quiz history
  const getMasteredSynonyms = (word) => {
    const masteredSynonyms = new Set();
    
    // Add manually mastered synonyms
    if (userStats.masteredSynonyms?.[word]) {
      userStats.masteredSynonyms[word].forEach(synonym => 
        masteredSynonyms.add(synonym)
      );
    }

    // Add synonyms mastered through quizzes
    userStats.quizHistory?.forEach(quiz => {
      const wordResult = quiz.wordResults?.[word];
      if (wordResult?.correct) {
        wordResult.correct.forEach(synonym => masteredSynonyms.add(synonym));
      }
    });

    return masteredSynonyms;
  };

  const getWordMasteryPercentage = (word, masteredSynonyms) => {
    const wordData = Data.find(item => item.word === word) || 
                    customWords.find(item => item.word === word);
    if (!wordData) return 0;
    
    return Math.round((masteredSynonyms.size / wordData.synonyms.length) * 100);
  };

  const getWordDifficulty = (word) => {
    const synonymCount = word.synonyms.length;
    if (synonymCount <= 3) return 'easy';
    if (synonymCount <= 5) return 'medium';
    return 'hard';
  };

  // Add letter selection handler
  const handleLetterSelect = (letter) => {
    let newSelection;
    if (letter === 'all') {
      newSelection = ['all'];
    } else {
      newSelection = selectedLetters.includes('all') 
        ? [letter] 
        : selectedLetters.includes(letter)
          ? selectedLetters.filter(l => l !== letter)
          : [...selectedLetters, letter];
      
      if (newSelection.length === 0) {
        newSelection = ['all'];
      }
    }
    setSelectedLetters(newSelection);
  };

  // Helper function to get word mastery level
  const getWordMasteryLevel = (word, masteredSynonyms) => {
    const masteryPercentage = getWordMasteryPercentage(word, masteredSynonyms);
    if (masteryPercentage === 100) return 'mastered';
    if (masteryPercentage > 0) return 'in-progress';
    return 'not-started';
  };

  // Filter and sort words
  useEffect(() => {
    let filtered = [...Data, ...customWords].filter(wordData => {
      // Search filter
      const searchLower = searchTerm.toLowerCase();
      const matchesSearch = searchTerm === '' || 
        wordData.word.toLowerCase().includes(searchLower) ||
        (Array.isArray(wordData.definition) 
          ? wordData.definition.some(def => typeof def === 'string' && def.toLowerCase().includes(searchLower))
          : typeof wordData.definition === 'string' && wordData.definition.toLowerCase().includes(searchLower)) ||
        wordData.synonyms.some(syn => syn.toLowerCase().includes(searchLower));

      // Letter filter
      const matchesLetter = selectedLetters.includes('all') || 
        selectedLetters.some(letter => 
          wordData.word.toLowerCase().startsWith(letter.toLowerCase())
        );

      // Get mastery info
      const masteredSynonyms = getMasteredSynonyms(wordData.word);
      const masteryLevel = getWordMasteryLevel(wordData.word, masteredSynonyms);
      const difficulty = getWordDifficulty(wordData);

      // Mastery filter
      const matchesMastery = filterOptions.mastery === 'all' ||
        filterOptions.mastery === masteryLevel;

      // Difficulty filter
      const matchesDifficulty = filterOptions.difficulty === 'all' ||
        filterOptions.difficulty === difficulty;

      return matchesSearch && matchesLetter && matchesMastery && matchesDifficulty;
    });

    // Apply sorting
    filtered = filtered.sort((a, b) => {
      switch (filterOptions.sortBy) {
        case 'alphabetical':
          return a.word.localeCompare(b.word);
        
        case 'flagged':
          const aFlagged = (userStats.flaggedSynonyms?.[a.word] || []).length;
          const bFlagged = (userStats.flaggedSynonyms?.[b.word] || []).length;
          // Sort by flagged count (descending) then alphabetically
          return bFlagged - aFlagged || a.word.localeCompare(b.word);
        
        case 'mastery':
          const aMastered = getMasteredSynonyms(a.word);
          const bMastered = getMasteredSynonyms(b.word);
          const aMasteryPercentage = getWordMasteryPercentage(a.word, aMastered);
          const bMasteryPercentage = getWordMasteryPercentage(b.word, bMastered);
          // Sort by mastery percentage (descending) then alphabetically
          return bMasteryPercentage - aMasteryPercentage || a.word.localeCompare(b.word);
        
        default:
          return 0;
      }
    });

    setFilteredWords(filtered);
    setTotalWords(filtered.length);
  }, [searchTerm, filterOptions, userStats, selectedLetters, customWords]);

  // Update filter options handler
  const handleFilterChange = (filterType, value) => {
    setFilterOptions(prev => ({
      ...prev,
      [filterType]: value
    }));
  };

  // Handle media icon clicks
  const handleMediaClick = (type, content, e) => {
    e.stopPropagation();
    if (content && content[0] !== "") {
      setMediaPopup({
        isOpen: true,
        type,
        content,
        currentIndex: 0
      });
    }
  };

  // Handle carousel navigation
  const handleCarouselNav = (direction) => {
    setMediaPopup(prev => ({
      ...prev,
      currentIndex: direction === 'next'
        ? (prev.currentIndex + 1) % prev.content.length
        : (prev.currentIndex - 1 + prev.content.length) % prev.content.length
    }));
  };

  // Add this function before the return statement
  const handlePronunciation = async (word, barElement) => {
    try {
      // If already playing this word, stop it
      if (playingAudio === word) {
        window.speechSynthesis.cancel();
        setPlayingAudio(null);
        barElement.classList.remove('playing');
        return;
      }

      // Cancel any ongoing speech
      window.speechSynthesis.cancel();

      // Add playing class to show animation
      barElement.classList.add('playing');
      setPlayingAudio(word);

      if ('speechSynthesis' in window) {
        const utterance = new SpeechSynthesisUtterance(word);
        utterance.lang = 'en-US';
        utterance.rate = 0.9;

        // Handle completion
        utterance.onend = () => {
          setPlayingAudio(null);
          barElement.classList.remove('playing');
        };

        // Handle errors
        utterance.onerror = () => {
          console.error('Error playing pronunciation for:', word);
          setPlayingAudio(null);
          barElement.classList.remove('playing');
        };

        window.speechSynthesis.speak(utterance);
      } else {
        console.error('Text-to-speech not supported in this browser');
        setPlayingAudio(null);
        barElement.classList.remove('playing');
      }

    } catch (error) {
      console.error('Error with pronunciation:', error);
      setPlayingAudio(null);
      barElement.classList.remove('playing');
    }
  };

  const [selectedText, setSelectedText] = useState('');
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [definitionData, setDefinitionData] = useState(null);
  const [showDefinitionPopup, setShowDefinitionPopup] = useState(false);

  useEffect(() => {
    const handleSelection = async () => {
      const selection = window.getSelection();
      const selectedText = selection.toString().trim();

      // Only proceed if there's actually selected text
      if (selectedText.length > 0) {
        // Check if the selected text is a word or synonym in Data.js
        const isInData = Data.some(word => 
          word.word.toLowerCase() === selectedText.toLowerCase() || 
          word.synonyms.some(syn => syn.toLowerCase() === selectedText.toLowerCase())
        );

        if (!isInData) {
          setShowDefinitionPopup(false);
          return;
        }

        // Get the selection range and its bounding rectangle
        const range = selection.getRangeAt(0);
        const rect = range.getBoundingClientRect();

        // Calculate position (not needed anymore since popup is fixed)
        setSelectedText(selectedText);

        try {
          const response = await fetch(`https://api.dictionaryapi.dev/api/v2/entries/en/${selectedText}`);
          if (response.ok) {
            const data = await response.json();
            setDefinitionData(data[0]);
            setShowDefinitionPopup(true);
          } else {
            console.error('Word not found or API error');
            setDefinitionData(null);
            setShowDefinitionPopup(false);
          }
        } catch (error) {
          console.error('Failed to fetch word data:', error);
          setDefinitionData(null);
          setShowDefinitionPopup(false);
        }
      }
    };

    // Add mouseup event listener to handle text selection
    document.addEventListener('mouseup', handleSelection);

    // Add click event listener to close popup when clicking outside
    const handleClickOutside = (event) => {
      if (!event.target.closest('.word-definition-popup')) {
        setShowDefinitionPopup(false);
        setDefinitionData(null);
        setSelectedText('');
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup
    return () => {
      document.removeEventListener('mouseup', handleSelection);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const isSynonymFlagged = (word, synonym) => {
    return userStats.flaggedSynonyms?.[word]?.includes(synonym);
  };

  const handleFlagSynonym = (word, synonym, e) => {
    e.stopPropagation(); // Prevent card expansion when clicking flag
    
    setUserStats(prev => {
      const currentFlagged = prev.flaggedSynonyms?.[word] || [];
      const newFlagged = currentFlagged.includes(synonym)
        ? currentFlagged.filter(s => s !== synonym)
        : [...currentFlagged, synonym];

      return {
        ...prev,
        flaggedSynonyms: {
          ...prev.flaggedSynonyms,
          [word]: newFlagged
        }
      };
    });
  };

  // Add this function to handle mastering a word
  const handleMasterWord = (word) => {
    setUserStats(prev => {
      const newStats = { ...prev };
      
      // Get all synonyms for the word
      const wordData = Data.find(item => item.word === word) || 
                      customWords.find(item => item.word === word);
      
      if (!wordData) return prev;

      // Initialize masteredSynonyms if it doesn't exist
      if (!newStats.masteredSynonyms) {
        newStats.masteredSynonyms = {};
      }
      
      // Add all synonyms to mastered list
      newStats.masteredSynonyms[word] = [...wordData.synonyms];
      
      return newStats;
    });
  };

  // Add this function to handle mastering individual synonyms
  const handleMasterSynonym = (word, synonym) => {
    setUserStats(prev => {
      const newStats = { ...prev };
      
      // Initialize masteredSynonyms if it doesn't exist
      if (!newStats.masteredSynonyms) {
        newStats.masteredSynonyms = {};
      }
      
      // Initialize array for this word if it doesn't exist
      if (!newStats.masteredSynonyms[word]) {
        newStats.masteredSynonyms[word] = [];
      }
      
      // Add synonym to mastered list if not already there
      if (!newStats.masteredSynonyms[word].includes(synonym)) {
        newStats.masteredSynonyms[word] = [
          ...newStats.masteredSynonyms[word],
          synonym
        ];
      }
      
      return newStats;
    });
  };

  // Add this function to handle unmastering a word
  const handleUnmasterWord = (word) => {
    setUserStats(prev => {
      const newStats = { ...prev };
      
      if (newStats.masteredSynonyms && newStats.masteredSynonyms[word]) {
        delete newStats.masteredSynonyms[word];
      }
      
      return newStats;
    });
  };

  // Add this function to handle unmastering individual synonyms
  const handleUnmasterSynonym = (word, synonym) => {
    setUserStats(prev => {
      const newStats = { ...prev };
      
      if (newStats.masteredSynonyms && newStats.masteredSynonyms[word]) {
        newStats.masteredSynonyms[word] = newStats.masteredSynonyms[word]
          .filter(s => s !== synonym);
        
        // Remove word entry if no synonyms are mastered
        if (newStats.masteredSynonyms[word].length === 0) {
          delete newStats.masteredSynonyms[word];
        }
      }
      
      return newStats;
    });
  };

  return (
    <div className="vocabulary-page">
      <div className="header-left">
        <Link to="/" className="back-button">
          <span>←</span> Back
        </Link>
        <h1>Vocabulary Progress</h1>
      </div>

      <div className="edit-controls">
        <button 
          className={`mastery-mode-button ${showMasteryControls ? 'active' : ''}`}
          onClick={() => setShowMasteryControls(!showMasteryControls)}
        >
          <span className="mastery-icon">★</span>
          {showMasteryControls ? 'Exit Mastery Mode' : 'Mastery Controls'}
        </button>
      </div>

      <div className="search-and-filter-container">
        <div className="search-container">
          <div className="search-input-wrapper">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search words, definitions, or synonyms..."
              className="search-input"
            />
            {searchTerm && (
              <button 
                className="clear-search"
                onClick={() => setSearchTerm('')}
                aria-label="Clear search"
              >
                ×
              </button>
            )}
          </div>
        </div>

        <div className="filter-container">
          <div className="filter-group">
            <label>Letter Filter:</label>
            <div className="dropdown-filter">
              <button 
                className="dropdown-trigger"
                onClick={() => setShowLetterFilter(!showLetterFilter)}
              >
                {selectedLetters.includes('all') 
                  ? 'All Letters' 
                  : selectedLetters.map(l => l.toUpperCase()).join(', ')}
                <span className={`arrow ${showLetterFilter ? 'up' : 'down'}`}>▼</span>
              </button>
              
              {showLetterFilter && (
                <div className="letter-filter-dropdown">
                  <div className="letter-buttons">
                    {alphabetCategories.map(letter => (
                      <button
                        key={letter}
                        onClick={() => handleLetterSelect(letter)}
                        className={`letter-button ${
                          selectedLetters.includes(letter) ? 'selected' : ''
                        }`}
                      >
                        {letter.toUpperCase()}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="filter-group">
            <label>Mastery:</label>
            <select 
              value={filterOptions.mastery}
              onChange={(e) => handleFilterChange('mastery', e.target.value)}
            >
              <option value="all">All</option>
              <option value="mastered">Mastered</option>
              <option value="in-progress">In Progress</option>
              <option value="not-started">Not Started</option>
            </select>
          </div>

          <div className="filter-group">
            <label>Sort by:</label>
            <select 
              value={filterOptions.sortBy}
              onChange={(e) => handleFilterChange('sortBy', e.target.value)}
            >
              <option value="mastery">Mastery</option>
              <option value="alphabetical">Alphabetical</option>
              <option value="flagged">Flagged Synonyms</option>
            </select>
          </div>

          <div className="filter-group">
            <label>Difficulty:</label>
            <select 
              value={filterOptions.difficulty}
              onChange={(e) => handleFilterChange('difficulty', e.target.value)}
            >
              <option value="all">All</option>
              <option value="easy">Easy (1-3 synonyms)</option>
              <option value="medium">Medium (4-5 synonyms)</option>
              <option value="hard">Hard (6+ synonyms)</option>
            </select>
          </div>
        </div>

        <div className="search-results-count">
          {searchTerm ? (
            `Found ${totalWords} ${totalWords === 1 ? 'word' : 'words'}`
          ) : (
            `${totalWords} total words`
          )}
        </div>
      </div>

      <div className="vocabulary-list">
        {filteredWords.length > 0 ? (
          filteredWords.map((wordData) => {
            const masteredSynonyms = getMasteredSynonyms(wordData.word);
            const flaggedSynonyms = userStats.flaggedSynonyms?.[wordData.word] || [];
            
            // Add a visual indicator for words with flagged synonyms
            const hasFlaggedSynonyms = flaggedSynonyms.length > 0;
            
            return (
              <div 
                key={wordData.word} 
                className={`vocabulary-card ${hasFlaggedSynonyms ? 'has-flagged' : ''}`}
              >
                <div className="word-header" onClick={() => {
                  // Add state for expanded cards
                  const newExpandedCards = {...expandedCards};
                  newExpandedCards[wordData.word] = !expandedCards[wordData.word];
                  setExpandedCards(newExpandedCards);
                }}>
                  <div className="word-title-container">
                    <div className="word-info">
                      <h2>{wordData.word}</h2>
                      {wordData['part-of-speech'] && wordData['part-of-speech'].length > 0 && (
                        <span className="part-of-speech">
                          {wordData['part-of-speech'].join(', ')}
                        </span>
                      )}
                    </div>
                    <p className="primary-definition">
                      {Array.isArray(wordData.definition) 
                        ? wordData.definition[0] 
                        : wordData.definition}
                    </p>
                  </div>
                  <div className="card-actions">
                    {showMasteryControls && (
                      <button
                        className={`master-word-button ${
                          wordData.synonyms.every(syn => 
                            userStats.masteredSynonyms?.[wordData.word]?.includes(syn)
                          ) ? 'mastered' : ''
                        }`}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent card expansion
                          const isFullyMastered = wordData.synonyms.every(syn => 
                            userStats.masteredSynonyms?.[wordData.word]?.includes(syn)
                          );
                          if (isFullyMastered) {
                            handleUnmasterWord(wordData.word);
                          } else {
                            handleMasterWord(wordData.word);
                          }
                        }}
                        title={`${
                          wordData.synonyms.every(syn => 
                            userStats.masteredSynonyms?.[wordData.word]?.includes(syn)
                          ) ? 'Remove mastery' : 'Master all synonyms'
                        }`}
                      >
                        <span className="master-icon">★</span>
                        <span className="master-text">
                          {wordData.synonyms.every(syn => 
                            userStats.masteredSynonyms?.[wordData.word]?.includes(syn)
                          ) ? 'Mastered' : 'Master Word'}
                        </span>
                      </button>
                    )}
                    <div className="mastery-badge" 
                      style={{
                        backgroundColor: `rgba(${
                          masteredSynonyms.size / wordData.synonyms.length * 100 >= 100 ? '74, 222, 128' : 
                          masteredSynonyms.size / wordData.synonyms.length * 100 >= 50 ? '0, 210, 255' : 
                          '255, 68, 68'
                        }, 0.1)`,
                        color: `${
                          masteredSynonyms.size / wordData.synonyms.length * 100 >= 100 ? 'var(--secondary-color)' : 
                          masteredSynonyms.size / wordData.synonyms.length * 100 >= 50 ? 'var(--primary-color)' : 
                          'var(--error-color)'
                        }`
                      }}
                    >
                      {Math.round(masteredSynonyms.size / wordData.synonyms.length * 100)}% Mastered
                    </div>
                    <button 
                      className="expand-button"
                      title={expandedCards[wordData.word] ? "Show less" : "Show more"}
                    >
                      <svg 
                        viewBox="0 0 24 24" 
                        width="24" 
                        height="24" 
                        fill="none" 
                        stroke="currentColor" 
                        strokeWidth="2" 
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                        style={{
                          transform: expandedCards[wordData.word] ? 'rotate(-180deg)' : 'rotate(0)',
                          transition: 'transform 0.3s ease'
                        }}
                      >
                        <path d="M6 9l6 6 6-6"/>
                      </svg>
                    </button>
                  </div>
                </div>

                {/* Always visible synonyms section */}
                <div className="synonyms-container compact">
                  <div className="synonyms-grid">
                    {wordData.synonyms.map((synonym) => (
                      <div key={`${wordData.word}-${synonym}`} className="synonym-container">
                        <span 
                          className={`synonym-tag ${
                            masteredSynonyms.has(synonym) ? 'mastered' : ''
                          } ${
                            userStats.flaggedSynonyms?.[wordData.word]?.includes(synonym) ? 'flagged' : ''
                          }`}
                        >
                          {synonym}
                          {showMasteryControls && (
                            <button
                              className={`master-synonym-button ${
                                masteredSynonyms.has(synonym) ? 'mastered' : ''
                              }`}
                              onClick={(e) => {
                                e.stopPropagation();
                                if (masteredSynonyms.has(synonym)) {
                                  handleUnmasterSynonym(wordData.word, synonym);
                                } else {
                                  handleMasterSynonym(wordData.word, synonym);
                                }
                              }}
                              title={`${
                                masteredSynonyms.has(synonym) ? 'Remove mastery' : 'Mark as mastered'
                              }`}
                            >
                              ★
                            </button>
                          )}
                          <button
                            className="flag-synonym-button"
                            onClick={(e) => handleFlagSynonym(wordData.word, synonym, e)}
                            title={userStats.flaggedSynonyms?.[wordData.word]?.includes(synonym) 
                              ? "Remove flag from this synonym"
                              : "Flag this synonym for review"}
                          >
                            <FaFlag className={userStats.flaggedSynonyms?.[wordData.word]?.includes(synonym) ? 'active' : ''} />
                          </button>
                        </span>
                        <button
                          className={`pronunciation-bar ${playingAudio === synonym ? 'playing' : ''}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            handlePronunciation(synonym, e.target);
                          }}
                          title={`Click to hear pronunciation of "${synonym}"`}
                          aria-label={`Pronounce ${synonym}`}
                        />
                      </div>
                    ))}
                  </div>
                </div>

                {/* Expandable content */}
                {expandedCards[wordData.word] && (
                  <div className="expanded-content">
                    {/* Additional definitions */}
                    {Array.isArray(wordData.definition) && wordData.definition.length > 1 && (
                      <div className="definitions-section">
                        <h3>Additional Definitions:</h3>
                        <div className="definitions-list">
                          {wordData.definition.slice(1).map((def, index) => (
                            <div key={index} className="definition-item">
                              <span className="definition-number">{index + 2}.</span>
                              <p>{def}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {/* Example Sentences */}
                    {wordData.sentences && wordData.sentences.length > 0 && wordData.sentences[0] !== "" && (
                      <div className="sentences-section">
                        <h3>Example Sentences:</h3>
                        <div className="sentences-list">
                          {wordData.sentences.map((sentence, index) => (
                            <div key={index} className="sentence-item">
                              <span className="sentence-number">{index + 1}.</span>
                              <p>{sentence}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {/* Media Section */}
                    {((wordData.media && wordData.media.length > 0 && wordData.media[0] !== "") || 
                      (wordData.image && wordData.image.length > 0 && wordData.image[0] !== "")) && (
                      <div className="media-section">
                        <h3>Visual Examples:</h3>
                        <div className="media-grid">
                          {wordData.media.map((url, index) => (
                            url && url !== "" && (
                              <div key={`video-${index}`} className="media-item">
                                <video controls>
                                  <source src={url} type="video/mp4" />
                                  Your browser does not support the video tag.
                                </video>
                              </div>
                            )
                          ))}
                          {wordData.image.map((url, index) => (
                            url && url !== "" && (
                              <div key={`image-${index}`} className="media-item">
                                <img src={url} alt={`Example of ${wordData.word}`} />
                              </div>
                            )
                          ))}
                        </div>
                      </div>
                    )}

                    {/* Memory Tips */}
                    {wordData.mnemonics && wordData.mnemonics.length > 0 && wordData.mnemonics[0] !== "" && (
                      <div className="mnemonics-section">
                        <h3>Memory Tips:</h3>
                        <div className="mnemonics-list">
                          {wordData.mnemonics.map((mnemonic, index) => (
                            mnemonic && (
                              <div key={index} className="mnemonic-item">
                                <span className="mnemonic-icon">💡</span>
                                <p>{mnemonic}</p>
                              </div>
                            )
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <div className="no-results">
            <p>No words found matching "{searchTerm}"</p>
            <button onClick={() => setSearchTerm('')} className="clear-search-button">
              Clear Search
            </button>
          </div>
        )}
      </div>

      {/* Add Media Popup */}
      {mediaPopup.isOpen && (
        <div className="media-popup-overlay" onClick={() => setMediaPopup({ isOpen: false })}>
          <div className="media-popup-content" onClick={e => e.stopPropagation()}>
            <button className="close-popup" onClick={() => setMediaPopup({ isOpen: false })}>
              ✖
            </button>
            
            {mediaPopup.content.length > 1 && (
              <div className="carousel-controls">
                <button 
                  className="carousel-button"
                  onClick={() => handleCarouselNav('prev')}
                >
                  ◀
                </button>
                <span className="carousel-counter">
                  {mediaPopup.currentIndex + 1} / {mediaPopup.content.length}
                </span>
                <button 
                  className="carousel-button"
                  onClick={() => handleCarouselNav('next')}
                >
                  ▶
                </button>
              </div>
            )}

            <div className="media-container">
              {mediaPopup.type === 'image' ? (
                <img 
                  src={mediaPopup.content[mediaPopup.currentIndex]} 
                  alt="Word visualization" 
                />
              ) : (
                <video 
                  controls 
                  autoPlay
                  src={mediaPopup.content[mediaPopup.currentIndex]}
                >
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          </div>
        </div>
      )}

      {showDefinitionPopup && definitionData && (
        <div className="popup-container">
          <WordDefinitionPopup
            word={selectedText}
            data={definitionData}
            onClose={() => {
              setShowDefinitionPopup(false);
              setDefinitionData(null);
              setSelectedText('');
            }}
          />
        </div>
      )}
    </div>
  );
}

export default VocabularyPage;