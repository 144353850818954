import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaStop, FaTimes, FaClock, FaCheck } from 'react-icons/fa';
import { Data } from '../data';
import '../styles/FlaggedQuizPage.css';

const CONFIDENCE_LEVELS = {
  AGAIN: {
    label: 'Again',
    color: 'var(--error-color)',
    icon: '↻'
  },
  GOOD: {
    label: 'Good',
    color: 'var(--primary-color)',
    icon: '✓'
  },
  EASY: {
    label: 'Easy',
    color: 'var(--secondary-color)',
    icon: '★'
  }
};

const FlaggedQuizPage = ({ userStats, setUserStats }) => {
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [repeatQueue, setRepeatQueue] = useState([]);
  const [easyStreak, setEasyStreak] = useState({});
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (!userStats?.flaggedSynonyms || Object.keys(userStats.flaggedSynonyms).length === 0) {
      navigate('/flagged-words');
      return;
    }

    if (!isInitialized) {
      const createQuestions = () => {
        const flaggedQuestions = Object.entries(userStats.flaggedSynonyms)
          .flatMap(([word, flaggedSyns]) => {
            const wordData = Data.find(w => w.word === word);
            if (!wordData) return [];

            return flaggedSyns.map(synonym => {
              const allPossibleSynonyms = Data
                .flatMap(w => w.synonyms)
                .filter(syn => 
                  !wordData.synonyms.includes(syn) &&
                  !Data.some(w => w.word === syn)
                );

              const wrongAnswers = [...new Set(allPossibleSynonyms)]
                .sort(() => 0.5 - Math.random())
                .slice(0, 5);

              return {
                word: wordData.word,
                correctSynonym: synonym,
                definition: wordData.definition,
                partOfSpeech: wordData['part-of-speech'],
                sentences: wordData.sentences,
                mnemonics: wordData.mnemonics,
                choices: [...wrongAnswers, synonym].sort(() => 0.5 - Math.random()),
                easyCount: easyStreak[`${word}-${synonym}`] || 0
              };
            });
          });

        return flaggedQuestions.sort(() => 0.5 - Math.random());
      };

      const initialQuestions = createQuestions();
      setQuestions(initialQuestions);
      setIsInitialized(true);
    }
  }, [userStats, navigate, isInitialized]);

  useEffect(() => {
    if (repeatQueue.length > 0) {
      const newQuestions = [...repeatQueue].sort(() => 0.5 - Math.random());
      setQuestions(prev => [...prev, ...newQuestions]);
      setRepeatQueue([]);
    }
  }, [repeatQueue]);

  const handleAnswer = (choice) => {
    if (showAnswer) return;
    setSelectedAnswer(choice);
    setShowAnswer(true);
  };

  const handleConfidenceLevel = (level) => {
    const currentQuestion = questions[currentIndex];
    if (!currentQuestion) return;

    const isCorrect = selectedAnswer === currentQuestion.correctSynonym;
    const questionKey = `${currentQuestion.word}-${currentQuestion.correctSynonym}`;

    setUserStats(prev => {
      const newStats = { ...prev };

      switch (level) {
        case 'AGAIN':
          const questionToRepeat = {
            ...currentQuestion,
            choices: [...currentQuestion.choices].sort(() => 0.5 - Math.random())
          };
          setRepeatQueue(prev => [...prev, questionToRepeat]);
          setEasyStreak(prev => ({ ...prev, [questionKey]: 0 }));
          break;

        case 'EASY':
          const newEasyCount = (easyStreak[questionKey] || 0) + 1;
          setEasyStreak(prev => ({ ...prev, [questionKey]: newEasyCount }));

          if (newEasyCount >= 3) {
            const newFlagged = [...(newStats.flaggedSynonyms[currentQuestion.word] || [])]
              .filter(syn => syn !== currentQuestion.correctSynonym);

            if (newFlagged.length === 0) {
              delete newStats.flaggedSynonyms[currentQuestion.word];
            } else {
              newStats.flaggedSynonyms[currentQuestion.word] = newFlagged;
            }

            if (!newStats.masteredSynonyms) {
              newStats.masteredSynonyms = {};
            }
            if (!newStats.masteredSynonyms[currentQuestion.word]) {
              newStats.masteredSynonyms[currentQuestion.word] = [];
            }
            newStats.masteredSynonyms[currentQuestion.word].push(currentQuestion.correctSynonym);
          }
          break;
      }

      return newStats;
    });

    if (currentIndex < questions.length - 1) {
      setCurrentIndex(prev => prev + 1);
      setShowAnswer(false);
      setSelectedAnswer(null);
    } else {
      navigate('/flagged-words');
    }
  };

  if (questions.length === 0) {
    return (
      <div className="flagged-quiz-page">
        <div className="no-questions">
          <h2>No Words to Review</h2>
          <p>You have no flagged words to review at the moment.</p>
          <button onClick={() => navigate('/flagged-words')} className="back-button">
            Return to Flagged Words
          </button>
        </div>
      </div>
    );
  }

  const currentQuestion = questions[currentIndex];

  return (
    <div className="flagged-quiz-page">
      <div className="quiz-header">
        <div className="quiz-progress">
          Question {currentIndex + 1} of {questions.length}
        </div>
        <button 
          className="end-quiz-button"
          onClick={() => navigate('/flagged-words')}
        >
          <FaStop /> End Review
        </button>
      </div>

      <div className="question-container">
        <h2 className="word">{currentQuestion.word}</h2>
        
        {!showAnswer && (
          <div className="choices">
            {currentQuestion.choices.map((choice, index) => (
              <button
                key={`${currentQuestion.word}-${choice}-${index}`}
                className={`choice-button ${
                  selectedAnswer === choice ? 'selected' : ''
                }`}
                onClick={() => handleAnswer(choice)}
              >
                {choice}
              </button>
            ))}
          </div>
        )}

        {showAnswer && (
          <div className="answer-section">
            <div className="answer-content">
              <div className="definition">
                {Array.isArray(currentQuestion.definition) 
                  ? currentQuestion.definition[0]
                  : currentQuestion.definition}
              </div>

              <div className="correct-answer">
                Correct answer: <span>{currentQuestion.correctSynonym}</span>
              </div>

              <div className="confidence-buttons">
                {Object.entries(CONFIDENCE_LEVELS).map(([key, level]) => (
                  <button
                    key={key}
                    className="confidence-button"
                    onClick={() => handleConfidenceLevel(key)}
                    style={{ 
                      '--button-color': level.color,
                      '--button-bg': `${level.color}15`
                    }}
                  >
                    <span className="confidence-icon">{level.icon}</span>
                    {level.label}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FlaggedQuizPage; 