import React, { useState, useEffect } from 'react';

import QuizContainer from './components/QuizContainer';

import ResultsContainer from './components/ResultsContainer';

import { Data } from './data';

import { 

  calculateNextReview, 

  checkDueForReview, 

  getPriorityScore 

} from './utils/spaceRepetition';

import Cookies from 'js-cookie';

import './App.css';

import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';

import ProgressPage from './components/ProgressPage';

import { ThemeProvider } from './context/ThemeContext';

import PracticePage from './components/PracticePage';

import QuizResultsPage from './components/QuizResultsPage';

import WordKnowledgePage from './components/WordKnowledgePage';

import VocabularyPage from './components/VocabularyPage';

import MathApp from './components/MathApp';

import MainPage from './components/MainPage';

import QuizPage from './components/QuizPage';

import SettingsPage from './components/SettingsPage';

import FlaggedWordsPage from './components/FlaggedWordsPage';

import FlaggedQuizPage from './components/FlaggedQuizPage';

import FlashcardsPage from './components/FlashcardsPage';



export function App() {

  const [sessionId] = useState(() => {

    const savedSession = Cookies.get('synonymQuizSession');

    return savedSession || Math.random().toString(36).substring(2) + Date.now().toString(36);

  });



  const [userStats, setUserStats] = useState(() => {

    const savedStats = Cookies.get(`stats_${sessionId}`);

    try {

      const parsedStats = savedStats ? JSON.parse(savedStats) : {

        totalQuizzes: 0,

        averageScore: 0,

        wordsLearned: [],

        weakWords: [],

        quizHistory: [],

        categoryPerformance: {},

        timeStats: { averageResponseTime: 0 },

        flaggedSynonyms: {}

      };

      if (!parsedStats.flaggedSynonyms) {

        parsedStats.flaggedSynonyms = {};

      }

      return parsedStats;

    } catch (error) {

      console.error('Error parsing saved stats:', error);

      return {

        totalQuizzes: 0,

        averageScore: 0,

        wordsLearned: [],

        weakWords: [],

        quizHistory: [],

        categoryPerformance: {},

        timeStats: { averageResponseTime: 0 },

        flaggedSynonyms: {}

      };

    }

  });



  // Initialize quizSetup with empty filteredWords array

  const [quizSetup, setQuizSetup] = useState({

    selectedAlphabets: ['all'],

    selectedWordCount: 5,

    filteredWords: []

  });



  const [quizResults, setQuizResults] = useState(null);



  const [customWords, setCustomWords] = useState(() => {

    try {

      const saved = localStorage.getItem('customWords');

      return saved ? JSON.parse(saved) : [];

    } catch (error) {

      console.error('Error parsing custom words:', error);

      return [];

    }

  });



  const [excludedWords, setExcludedWords] = useState(() => {

    try {

      const saved = localStorage.getItem('excludedWords');

      return saved ? JSON.parse(saved) : {};

    } catch (error) {

      console.error('Error parsing excluded words:', error);

      return {};

    }

  });



  // Save stats to cookie whenever they change

  useEffect(() => {

    try {

      Cookies.set(`stats_${sessionId}`, JSON.stringify(userStats), { expires: 30 });

    } catch (error) {

      console.error('Error saving stats:', error);

    }

  }, [userStats, sessionId]);



  // Add error boundary for routes

  const renderRoute = (Component, props) => {

    try {

      return <Component {...props} />;

    } catch (error) {

      console.error('Error rendering component:', error);

      return <div>Something went wrong. Please try refreshing the page.</div>;

    }

  };



  return (

    <ThemeProvider>

      <Router>

        <Routes>

          <Route path="/" element={<MainPage />} />

          <Route 

            path="/word-knowledge" 

            element={renderRoute(WordKnowledgePage, {

              quizSetup,

              setQuizSetup,

              setQuizResults,

              setUserStats,

              userStats,

              customWords,

              excludedWords

            })}

          />

          <Route 

            path="/progress" 

            element={renderRoute(ProgressPage, { userStats })}

          />

          <Route 

            path="/practice" 

            element={renderRoute(PracticePage)}

          />

          <Route 

            path="/results" 

            element={renderRoute(QuizResultsPage, { results: quizResults })}

          />

          <Route 

            path="/vocabulary" 

            element={renderRoute(VocabularyPage, {

              userStats,

              setUserStats,

              customWords,

              excludedWords

            })}

          />

          <Route 

            path="/math" 

            element={renderRoute(MathApp)}

          />

          <Route 

            path="/quiz" 

            element={renderRoute(QuizPage, {

              quizSetup,

              setQuizResults,

              setUserStats,

              userStats

            })}

          />

          <Route 

            path="/settings" 

            element={renderRoute(SettingsPage, {

              setUserStats

            })}

          />

          <Route 

            path="/flagged-words" 

            element={renderRoute(FlaggedWordsPage, {

              userStats,

              setUserStats

            })}

          />

          <Route 

            path="/flagged-quiz" 

            element={renderRoute(FlaggedQuizPage, {

              userStats,

              setUserStats

            })}

          />

          <Route 

            path="/flashcards" 

            element={renderRoute(FlashcardsPage, {

              userStats,

              setUserStats

            })}

          />

        </Routes>

      </Router>

    </ThemeProvider>

  );

}


