import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaArrowRotateRight, FaShuffle } from 'react-icons/fa6';
import { FaVolumeUp } from 'react-icons/fa';
import { Data } from '../data';
import '../styles/FlashcardsPage.css';

const FlashcardsPage = ({ userStats, setUserStats }) => {
  const navigate = useNavigate();
  const [cards, setCards] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);
  const [showDefinition, setShowDefinition] = useState(true);
  const [playingAudio, setPlayingAudio] = useState(null);

  useEffect(() => {
    const flaggedWords = Data.filter(word => 
      userStats.flaggedSynonyms?.[word.word]?.length > 0
    );

    const regularWords = Data.filter(word => 
      !userStats.flaggedSynonyms?.[word.word]?.length
    );

    const allCards = [
      ...flaggedWords.map(word => ({
        ...word,
        isFlagged: true
      })),
      ...regularWords.map(word => ({
        ...word,
        isFlagged: false
      }))
    ].sort(() => 0.5 - Math.random());

    setCards(allCards);
  }, [userStats]);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  const handleNext = () => {
    if (currentIndex < cards.length - 1) {
      setIsFlipped(false); // Reset flip state
      setTimeout(() => {
        setCurrentIndex(currentIndex + 1);
      }, 300); // Wait for flip animation to complete
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setIsFlipped(false); // Reset flip state
      setTimeout(() => {
        setCurrentIndex(currentIndex - 1);
      }, 300); // Wait for flip animation to complete
    }
  };

  const handleShuffle = () => {
    setIsFlipped(false); // Reset flip state
    setTimeout(() => {
      const shuffled = [...cards].sort(() => 0.5 - Math.random());
      setCards(shuffled);
      setCurrentIndex(0);
    }, 300); // Wait for flip animation to complete
  };

  const handleKeyPress = (e) => {
    switch (e.key) {
      case ' ':
        e.preventDefault();
        handleFlip();
        break;
      case 'ArrowRight':
        handleNext();
        break;
      case 'ArrowLeft':
        handlePrevious();
        break;
      default:
        break;
    }
  };

  const handlePronunciation = async (word, e) => {
    e.stopPropagation(); // Prevent card flip
    try {
      if (playingAudio === word) {
        window.speechSynthesis.cancel();
        setPlayingAudio(null);
        return;
      }

      window.speechSynthesis.cancel();
      setPlayingAudio(word);

      if ('speechSynthesis' in window) {
        const utterance = new SpeechSynthesisUtterance(word);
        utterance.lang = 'en-US';
        utterance.rate = 0.9;

        utterance.onend = () => {
          setPlayingAudio(null);
        };

        utterance.onerror = () => {
          console.error('Error playing pronunciation for:', word);
          setPlayingAudio(null);
        };

        window.speechSynthesis.speak(utterance);
      }
    } catch (error) {
      console.error('Error with pronunciation:', error);
      setPlayingAudio(null);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [currentIndex, isFlipped]);

  if (cards.length === 0) {
    return (
      <div className="flashcards-page">
        <div className="no-cards">
          <h2>No Cards Available</h2>
          <p>Start by adding words to your vocabulary.</p>
          <button onClick={() => navigate('/vocabulary')} className="back-button">
            Go to Vocabulary
          </button>
        </div>
      </div>
    );
  }

  const currentCard = cards[currentIndex];

  return (
    <div className="flashcards-page">
      <div className="flashcards-header">
        <button 
          className="back-button"
          onClick={() => navigate('/')}
        >
          <FaArrowLeft /> Back to Home
        </button>
        <h1>Flashcards</h1>
        <div className="view-toggle">
          <button 
            className={showDefinition ? 'active' : ''}
            onClick={() => setShowDefinition(true)}
          >
            Definition
          </button>
          <button 
            className={!showDefinition ? 'active' : ''}
            onClick={() => setShowDefinition(false)}
          >
            Synonyms
          </button>
        </div>
      </div>

      <div className="flashcard-container">
        <div 
          className={`flashcard ${isFlipped ? 'flipped' : ''}`} 
          onClick={handleFlip}
        >
          <div className="flashcard-inner">
            <div className="flashcard-front">
              <div className="word-section">
                <h2>{currentCard.word}</h2>
                <button
                  className="pronunciation-button"
                  onClick={(e) => handlePronunciation(currentCard.word, e)}
                >
                  <FaVolumeUp className={playingAudio === currentCard.word ? 'playing' : ''} />
                </button>
              </div>
              {currentCard.partOfSpeech && (
                <div className="part-of-speech">
                  {currentCard.partOfSpeech.join(', ')}
                </div>
              )}
            </div>
            <div className="flashcard-back">
              {showDefinition ? (
                <div className="definition">
                  {Array.isArray(currentCard.definition) 
                    ? currentCard.definition[0]
                    : currentCard.definition}
                </div>
              ) : (
                <div className="synonyms">
                  {currentCard.synonyms.map((synonym, index) => (
                    <span 
                      key={`${currentCard.word}-${synonym}-${index}`}
                      className={`synonym-tag ${
                        userStats.flaggedSynonyms?.[currentCard.word]?.includes(synonym) 
                          ? 'flagged' 
                          : ''
                      }`}
                    >
                      {synonym}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flashcard-controls">
          <button 
            className="control-button"
            onClick={handlePrevious}
            disabled={currentIndex === 0}
          >
            Previous
          </button>
          <div className="center-controls">
            <button 
              className="control-button shuffle"
              onClick={handleShuffle}
              title="Shuffle cards"
            >
              <FaShuffle />
            </button>
            <button 
              className="control-button flip"
              onClick={handleFlip}
              title="Flip card (Space)"
            >
              <FaArrowRotateRight />
            </button>
          </div>
          <button 
            className="control-button"
            onClick={handleNext}
            disabled={currentIndex === cards.length - 1}
          >
            Next
          </button>
        </div>

        <div className="progress-bar">
          <div 
            className="progress"
            style={{ width: `${((currentIndex + 1) / cards.length) * 100}%` }}
          />
        </div>
        <div className="card-count">
          {currentIndex + 1} / {cards.length}
        </div>
      </div>

      <div className="keyboard-shortcuts">
        <p>Keyboard shortcuts: Space (flip), ← (previous), → (next)</p>
      </div>
    </div>
  );
};

export default FlashcardsPage; 