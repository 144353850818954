import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FaArrowLeft, FaCheck, FaTimes, FaClock, FaFlag, FaStar } from 'react-icons/fa';
import '../styles/QuizResults.css';

function QuizResultsPage({ results }) {
  const navigate = useNavigate();

  // Calculate overall stats
  const getOverallStats = () => {
    let totalCorrect = 0;
    let totalAttempts = 0;
    let totalTime = 0;
    let totalFlagged = 0;

    Object.values(results || {}).forEach(resultData => {
      const { correct = [], incorrect = [], times = [], flaggedSynonyms = [] } = resultData;
      totalCorrect += correct.length;
      totalAttempts += correct.length + incorrect.length;
      totalTime += times.reduce((sum, time) => sum + time, 0);
      totalFlagged += flaggedSynonyms.length;
    });

    return {
      totalCorrect,
      totalAttempts,
      averageScore: totalAttempts ? (totalCorrect / totalAttempts) * 100 : 0,
      averageTime: totalAttempts ? totalTime / totalAttempts : 0,
      totalFlagged
    };
  };

  const stats = getOverallStats();

  return (
    <div className="quiz-results-page">
      <div className="header-left">
        <Link to="/" className="back-button">
          <FaArrowLeft /> Main Menu
        </Link>
      </div>
      
      <div className="results-container">
        <h2 className="results-title">Quiz Results</h2>

        {/* Overall Stats Section */}
        <div className="overall-stats">
          <div className="stat-card">
            <div className="stat-icon score">
              <FaStar />
            </div>
            <div className="stat-value">{Math.round(stats.averageScore)}%</div>
            <div className="stat-label">Average Score</div>
          </div>
          <div className="stat-card">
            <div className="stat-icon correct">
              <FaCheck />
            </div>
            <div className="stat-value">{stats.totalCorrect}/{stats.totalAttempts}</div>
            <div className="stat-label">Correct Answers</div>
          </div>
          <div className="stat-card">
            <div className="stat-icon time">
              <FaClock />
            </div>
            <div className="stat-value">{stats.averageTime.toFixed(1)}s</div>
            <div className="stat-label">Average Time</div>
          </div>
          <div className="stat-card">
            <div className="stat-icon flagged">
              <FaFlag />
            </div>
            <div className="stat-value">{stats.totalFlagged}</div>
            <div className="stat-label">Flagged for Review</div>
          </div>
        </div>

        <div className="results-list">
          {Object.entries(results || {}).map(([word, resultData]) => {
            const { correct = [], incorrect = [], times = [], flaggedSynonyms = [] } = resultData;
            const totalAttempts = correct.length + incorrect.length;
            const score = totalAttempts > 0 ? (correct.length / totalAttempts) * 100 : 0;
            const averageTime = times.length > 0 
              ? times.reduce((a, b) => a + b, 0) / times.length 
              : 0;
            
            return (
              <div key={word} className="result-card">
                <div className="result-header">
                  <div className="word-info">
                    <h3 className="word-title">{resultData.word || word}</h3>
                    {resultData['part-of-speech'] && (
                      <span className="part-of-speech">
                        {resultData['part-of-speech'].join(', ')}
                      </span>
                    )}
                  </div>
                  <div className="score-badges">
                    <div className={`score-badge ${score >= 70 ? 'good' : 'poor'}`}>
                      {Math.round(score)}% correct
                    </div>
                    <div className="time-badge">
                      <FaClock /> {averageTime.toFixed(1)}s
                    </div>
                  </div>
                </div>
                
                <div className="word-definition">
                  {resultData.definition}
                </div>

                <div className="synonyms-section">
                  <h4>Synonyms Performance</h4>
                  <div className="synonyms-grid">
                    {resultData.allSynonyms.map((synonym) => (
                      <div key={synonym} className="synonym-wrapper">
                        <span className={`synonym-tag ${
                          correct.includes(synonym) ? 'correct' : 
                          incorrect.includes(synonym) ? 'incorrect' : 
                          flaggedSynonyms.includes(synonym) ? 'flagged' : ''
                        }`}>
                          {synonym}
                          {correct.includes(synonym) && <FaCheck className="status-icon" />}
                          {incorrect.includes(synonym) && <FaTimes className="status-icon" />}
                          {flaggedSynonyms.includes(synonym) && <FaFlag className="status-icon" />}
                        </span>
                      </div>
                    ))}
                  </div>
                  {incorrect.length > 0 && (
                    <div className="incorrect-answers">
                      <h5>Incorrect Answers:</h5>
                      {incorrect.map((synonym, index) => (
                        <div key={index} className="incorrect-answer-item">
                          <FaTimes className="incorrect-icon" />
                          <span className="incorrect-synonym">{synonym}</span>
                          <span className="arrow">→</span>
                          <span className="user-answer">
                            {results[word].userAnswers?.[synonym] || 'No answer'}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default QuizResultsPage; 
