import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaStop, FaTimes, FaFlag, FaClock } from 'react-icons/fa';
import { Data } from '../data';
import '../styles/QuizPage.css';

const QuizPage = ({ quizSetup, setQuizResults, setUserStats, userStats }) => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [startTime] = useState(Date.now());
  const [questions, setQuestions] = useState([]);
  const [showEndConfirm, setShowEndConfirm] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [flaggedQuestions, setFlaggedQuestions] = useState(new Set());
  const [timeLeft, setTimeLeft] = useState(36);
  const [timerActive, setTimerActive] = useState(true);

  useEffect(() => {
    if (!quizSetup.filteredWords || quizSetup.filteredWords.length === 0) {
      navigate('/word-knowledge');
      return;
    }

    // Create questions only for unmastered synonyms
    const createQuestions = () => {
      return quizSetup.filteredWords.flatMap(word => {
        // Get previously mastered synonyms for this word
        const masteredSynonyms = new Set(userStats.masteredSynonyms?.[word.word] || []);
        
        // Filter out mastered synonyms
        const remainingSynonyms = word.synonyms.filter(synonym => !masteredSynonyms.has(synonym));

        return remainingSynonyms.map(synonym => {
          const allPossibleSynonyms = Data
            .flatMap(w => w.synonyms)
            .filter(syn => 
              !word.synonyms.includes(syn) &&
              !Data.some(w => w.word === syn)
            );

          const wrongAnswers = [...new Set(allPossibleSynonyms)]
            .sort(() => 0.5 - Math.random())
            .slice(0, 5);

          return {
            word: word.word,
            correctSynonym: synonym,
            definition: word.definition,
            partOfSpeech: word['part-of-speech'],
            sentences: word.sentences,
            mnemonics: word.mnemonics,
            choices: [...wrongAnswers, synonym].sort(() => 0.5 - Math.random()),
            allSynonyms: word.synonyms
          };
        });
      }).sort(() => 0.5 - Math.random());
    };

    setQuestions(createQuestions());
  }, [quizSetup, navigate, userStats.masteredSynonyms]);

  useEffect(() => {
    let timer;
    if (timerActive && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft(prev => prev - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      // Time's up - mark question as incorrect
      const currentQuestion = questions[currentQuestionIndex];
      setUserStats(prev => ({
        ...prev,
        flaggedSynonyms: {
          ...prev.flaggedSynonyms,
          [currentQuestion.word]: [
            ...(prev.flaggedSynonyms?.[currentQuestion.word] || []),
            currentQuestion.correctSynonym
          ]
        }
      }));
      setShowAnswer(true);
    }

    return () => clearInterval(timer);
  }, [timeLeft, timerActive]);

  const handleAnswer = (choice) => {
    setTimerActive(false);
    const currentQuestion = questions[currentQuestionIndex];
    const timeTaken = 36 - timeLeft;
    const isCorrect = choice === currentQuestion.correctSynonym;

    if (isCorrect) {
      // Update mastered synonyms when answer is correct
      setUserStats(prev => {
        const newStats = { ...prev };
        if (!newStats.masteredSynonyms) {
          newStats.masteredSynonyms = {};
        }
        if (!newStats.masteredSynonyms[currentQuestion.word]) {
          newStats.masteredSynonyms[currentQuestion.word] = [];
        }
        newStats.masteredSynonyms[currentQuestion.word].push(currentQuestion.correctSynonym);
        return newStats;
      });

      setAnswers(prev => ({
        ...prev,
        [currentQuestionIndex]: {
          word: currentQuestion.word,
          userAnswer: choice,
          correctSynonym: currentQuestion.correctSynonym,
          timeTaken,
          definition: currentQuestion.definition,
          allSynonyms: currentQuestion.allSynonyms,
          flagged: flaggedQuestions.has(currentQuestionIndex)
        }
      }));

      moveToNextQuestion();
    } else {
      setUserStats(prev => ({
        ...prev,
        flaggedSynonyms: {
          ...prev.flaggedSynonyms,
          [currentQuestion.word]: [
            ...(prev.flaggedSynonyms?.[currentQuestion.word] || []),
            currentQuestion.correctSynonym
          ]
        }
      }));

      setAnswers(prev => ({
        ...prev,
        [currentQuestionIndex]: {
          word: currentQuestion.word,
          userAnswer: choice,
          correctSynonym: currentQuestion.correctSynonym,
          timeTaken,
          definition: currentQuestion.definition,
          allSynonyms: currentQuestion.allSynonyms,
          flagged: flaggedQuestions.has(currentQuestionIndex)
        }
      }));

      setShowAnswer(true);
      setSelectedAnswer(choice);
    }
  };

  const moveToNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setShowAnswer(false);
      setSelectedAnswer(null);
      setTimeLeft(36); // Reset timer
      setTimerActive(true); // Restart timer
    } else {
      saveQuizResults();
    }
  };

  const saveQuizResults = () => {
    const results = {};
    Object.entries(answers).forEach(([index, answerData]) => {
      const { word, userAnswer, correctSynonym, timeTaken, definition, allSynonyms, flagged } = answerData;
      
      if (!results[word]) {
        results[word] = {
          word,
          definition,
          allSynonyms,
          correct: [],
          incorrect: [],
          times: [],
          flaggedSynonyms: [],
          userAnswers: {}
        };
      }

      if (userAnswer === correctSynonym) {
        results[word].correct.push(correctSynonym);
      } else {
        results[word].incorrect.push(correctSynonym);
        results[word].userAnswers[correctSynonym] = userAnswer;
        if (flagged) {
          results[word].flaggedSynonyms.push(correctSynonym);
        }
      }
      results[word].times.push(timeTaken);
    });

    // Update user stats
    setUserStats(prev => {
      const newStats = { ...prev };
      
      // Update quiz history
      newStats.quizHistory = [
        ...(prev.quizHistory || []),
        {
          date: new Date().toISOString(),
          wordResults: results,
          totalQuestions: questions.length,
          correctAnswers: Object.values(results).reduce((sum, word) => sum + word.correct.length, 0)
        }
      ];

      // Update total quizzes
      newStats.totalQuizzes = (prev.totalQuizzes || 0) + 1;

      // Calculate and update average score
      const totalCorrect = Object.values(results).reduce((sum, word) => sum + word.correct.length, 0);
      const totalQuestions = questions.length;
      const quizScore = (totalCorrect / totalQuestions) * 100;
      newStats.averageScore = prev.averageScore 
        ? ((prev.averageScore * (prev.totalQuizzes - 1)) + quizScore) / prev.totalQuizzes
        : quizScore;

      // Update flagged synonyms
      Object.values(results).forEach(wordResult => {
        if (wordResult.flaggedSynonyms.length > 0) {
          const currentFlagged = newStats.flaggedSynonyms[wordResult.word] || [];
          newStats.flaggedSynonyms[wordResult.word] = [
            ...new Set([...currentFlagged, ...wordResult.flaggedSynonyms])
          ];
        }
      });

      return newStats;
    });

    // Save results for results page
    setQuizResults(results);

    // Navigate to results page
    navigate('/results');
  };

  const handleEndQuiz = () => {
    setShowEndConfirm(true);
  };

  const confirmEndQuiz = () => {
    // Save results before navigating
    const results = {};
    Object.entries(answers).forEach(([index, answerData]) => {
      const { word, userAnswer, correctSynonym, timeTaken, definition, allSynonyms, flagged } = answerData;
      
      if (!results[word]) {
        results[word] = {
          word,
          definition,
          allSynonyms,
          correct: [],
          incorrect: [],
          times: [],
          flaggedSynonyms: [],
          userAnswers: {}
        };
      }

      if (userAnswer === correctSynonym) {
        results[word].correct.push(correctSynonym);
      } else {
        results[word].incorrect.push(correctSynonym);
        results[word].userAnswers[correctSynonym] = userAnswer;
        if (flagged) {
          results[word].flaggedSynonyms.push(correctSynonym);
        }
      }
      results[word].times.push(timeTaken);
    });

    // Update user stats
    setUserStats(prev => {
      const newStats = { ...prev };
      
      // Update quiz history
      newStats.quizHistory = [
        ...(prev.quizHistory || []),
        {
          date: new Date().toISOString(),
          wordResults: results,
          totalQuestions: questions.length,
          correctAnswers: Object.values(results).reduce((sum, word) => sum + word.correct.length, 0)
        }
      ];

      // Update total quizzes
      newStats.totalQuizzes = (prev.totalQuizzes || 0) + 1;

      // Calculate and update average score
      const totalCorrect = Object.values(results).reduce((sum, word) => sum + word.correct.length, 0);
      const totalQuestions = questions.length;
      const quizScore = (totalCorrect / totalQuestions) * 100;
      newStats.averageScore = prev.averageScore 
        ? ((prev.averageScore * (prev.totalQuizzes - 1)) + quizScore) / prev.totalQuizzes
        : quizScore;

      // Update flagged synonyms
      Object.values(results).forEach(wordResult => {
        if (wordResult.flaggedSynonyms.length > 0) {
          const currentFlagged = newStats.flaggedSynonyms[wordResult.word] || [];
          newStats.flaggedSynonyms[wordResult.word] = [
            ...new Set([...currentFlagged, ...wordResult.flaggedSynonyms])
          ];
        }
      });

      return newStats;
    });

    // Save results for results page
    setQuizResults(results);

    // Navigate to results page
    navigate('/results');
  };

  const handleFlagQuestion = () => {
    const currentQuestion = questions[currentQuestionIndex];
    const newFlagged = new Set(flaggedQuestions);
    
    if (newFlagged.has(currentQuestionIndex)) {
      newFlagged.delete(currentQuestionIndex);
      setUserStats(prev => {
        const currentFlagged = prev.flaggedSynonyms?.[currentQuestion.word] || [];
        const updatedFlagged = currentFlagged.filter(syn => syn !== currentQuestion.correctSynonym);
        
        return {
          ...prev,
          flaggedSynonyms: {
            ...prev.flaggedSynonyms,
            [currentQuestion.word]: updatedFlagged
          }
        };
      });
    } else {
      newFlagged.add(currentQuestionIndex);
      setUserStats(prev => {
        const currentFlagged = prev.flaggedSynonyms?.[currentQuestion.word] || [];
        
        return {
          ...prev,
          flaggedSynonyms: {
            ...prev.flaggedSynonyms,
            [currentQuestion.word]: [
              ...currentFlagged,
              currentQuestion.correctSynonym
            ]
          }
        };
      });
    }
    setFlaggedQuestions(newFlagged);
  };

  const getFlagExplanation = () => {
    if (flaggedQuestions.has(currentQuestionIndex)) {
      return "This synonym has been flagged for review. You'll see it highlighted in your vocabulary list.";
    }
    return "Flag this synonym to mark it for review. It will be highlighted in your vocabulary list.";
  };

  if (questions.length === 0) {
    return <div>Loading...</div>;
  }

  const currentQuestion = questions[currentQuestionIndex];
  const isAnswerIncorrect = selectedAnswer && selectedAnswer !== currentQuestion.correctSynonym;

  return (
    <div className="quiz-page">
      <div className="quiz-header">
        <div className="quiz-progress">
          Question {currentQuestionIndex + 1} of {questions.length}
        </div>
        <div className="quiz-actions">
          <button 
            className={`flag-button ${flaggedQuestions.has(currentQuestionIndex) ? 'flagged' : ''}`}
            onClick={handleFlagQuestion}
            title={getFlagExplanation()}
          >
            <FaFlag />
            <span className="flag-tooltip">{getFlagExplanation()}</span>
          </button>
          <button 
            className="end-quiz-button"
            onClick={() => setShowEndConfirm(true)}
          >
            <FaStop /> End Quiz
          </button>
        </div>
      </div>

      <div className="question-container">
        <div className="timer-bar-container">
          <div 
            className={`timer-bar ${timeLeft <= 10 ? 'warning' : ''}`}
            style={{ 
              width: `${(timeLeft/36) * 100}%`,
              backgroundColor: timeLeft <= 10 ? 'var(--error-color)' : 'var(--secondary-color)'
            }}
          />
          <div className="timer-text">
            <FaClock /> {timeLeft}s
          </div>
        </div>
        <h2 className="word">{currentQuestion.word}</h2>
        
        <div className="choices">
          {currentQuestion.choices.map((choice, index) => (
            <button
              key={index}
              className={`choice-button ${
                selectedAnswer === choice ? 
                  choice === currentQuestion.correctSynonym ? 'correct' : 'incorrect'
                : ''
              }`}
              onClick={() => handleAnswer(choice)}
              disabled={showAnswer}
            >
              {choice}
            </button>
          ))}
        </div>

        {isAnswerIncorrect && !showAnswer && (
          <button 
            className="show-answer-button"
            onClick={() => setShowAnswer(true)}
          >
            Show Answer
          </button>
        )}

        {showAnswer && (
          <div className="answer-section">
            <div className="answer-content">
              {currentQuestion.partOfSpeech && (
                <div className="part-of-speech">
                  {currentQuestion.partOfSpeech.join(', ')}
                </div>
              )}
              
              <div className="definition">
                {Array.isArray(currentQuestion.definition) 
                  ? currentQuestion.definition[0]
                  : currentQuestion.definition
                }
              </div>

              <div className="synonyms">
                <h4>Synonyms:</h4>
                <div className="synonym-list">
                  {currentQuestion.allSynonyms.map((syn, index) => (
                    <span 
                      key={index}
                      className={`answer-synonym ${syn === currentQuestion.correctSynonym ? 'correct' : ''}`}
                    >
                      {syn}
                    </span>
                  ))}
                </div>
              </div>

              {currentQuestion.sentences && currentQuestion.sentences[0] && (
                <div className="example-sentence">
                  <h4>Example:</h4>
                  <p>{currentQuestion.sentences[0]}</p>
                </div>
              )}

              {currentQuestion.mnemonics && currentQuestion.mnemonics[0] && (
                <div className="mnemonic">
                  <h4>Memory Aid:</h4>
                  <p>{currentQuestion.mnemonics[0]}</p>
                </div>
              )}

              {(currentQuestion.media?.[0] || currentQuestion.image?.[0]) && (
                <div className="media-section">
                  {currentQuestion.image?.[0] && (
                    <img src={currentQuestion.image[0]} alt={currentQuestion.word} />
                  )}
                  {currentQuestion.media?.[0] && (
                    <video controls>
                      <source src={currentQuestion.media[0]} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </div>
              )}

              <button 
                className="continue-button"
                onClick={moveToNextQuestion}
              >
                Continue
              </button>
            </div>
          </div>
        )}
      </div>

      {showEndConfirm && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button 
              className="close-modal"
              onClick={() => setShowEndConfirm(false)}
            >
              <FaTimes />
            </button>
            <h3>End Quiz?</h3>
            <p>Are you sure you want to end the quiz? Your progress will be saved.</p>
            <div className="modal-buttons">
              <button 
                className="cancel-button"
                onClick={() => setShowEndConfirm(false)}
              >
                Continue Quiz
              </button>
              <button 
                className="confirm-button"
                onClick={confirmEndQuiz}
              >
                End Quiz
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuizPage; 