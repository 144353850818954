import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaTrash, FaExclamationTriangle } from 'react-icons/fa';
import Cookies from 'js-cookie';
import '../styles/SettingsPage.css';

const SettingsPage = ({ setUserStats }) => {
  const navigate = useNavigate();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const clearUserData = () => {
    // Clear all cookies
    Cookies.remove('synonymQuizSession');
    Object.keys(Cookies.get()).forEach(cookie => {
      Cookies.remove(cookie);
    });

    // Clear all localStorage
    localStorage.clear();

    // Clear browser cache for the domain
    if ('caches' in window) {
      caches.keys().then(names => {
        names.forEach(name => {
          caches.delete(name);
        });
      });
    }

    // Reset user stats completely
    setUserStats({
      totalQuizzes: 0,
      averageScore: 0,
      wordsLearned: [],
      weakWords: [],
      quizHistory: [],
      categoryPerformance: {},
      timeStats: { averageResponseTime: 0 },
      flaggedSynonyms: {},
      masteredWords: {},
      masteredSynonyms: new Set(),
      excludedWords: {},
      customWords: [],
      settings: {
        theme: 'light',
        fontSize: 'medium',
        notifications: true
      }
    });

    // Clear session storage
    sessionStorage.clear();

    // Show success message
    alert('All data has been cleared successfully!');

    setShowConfirmation(false);
    // Force reload the page to ensure clean state
    window.location.href = '/';
  };

  return (
    <div className="settings-page">
      <div className="settings-header">
        <button 
          className="back-button"
          onClick={() => navigate('/')}
        >
          <FaArrowLeft /> Back to Home
        </button>
        <h1>Settings</h1>
      </div>

      <div className="settings-container">
        <section className="settings-section">
          <h2>Data Management</h2>
          <div className="setting-item">
            <div className="setting-info">
              <h3>Clear User Data</h3>
              <p>Delete all your progress, statistics, preferences, and mastered words. This action cannot be undone.</p>
              <ul className="data-list">
                <li>Quiz history and scores</li>
                <li>Mastered words and synonyms</li>
                <li>Learning progress</li>
                <li>Flagged items</li>
                <li>Custom settings</li>
                <li>Cached data</li>
              </ul>
            </div>
            <button 
              className="clear-data-button"
              onClick={() => setShowConfirmation(true)}
            >
              <FaTrash /> Clear Data
            </button>
          </div>
        </section>
      </div>

      {showConfirmation && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <FaExclamationTriangle className="warning-icon" />
              <h3>Clear All Data?</h3>
            </div>
            <p>This will permanently delete all your:</p>
            <ul>
              <li>Quiz progress and history</li>
              <li>Learning statistics</li>
              <li>Mastered words and synonyms</li>
              <li>Flagged words</li>
              <li>Custom settings</li>
              <li>Browser cache for this app</li>
            </ul>
            <p className="warning-text">This action cannot be undone!</p>
            <div className="modal-buttons">
              <button 
                className="cancel-button"
                onClick={() => setShowConfirmation(false)}
              >
                Cancel
              </button>
              <button 
                className="confirm-button"
                onClick={clearUserData}
              >
                Yes, Clear Everything
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SettingsPage; 